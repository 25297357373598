import axios from 'axios'
import {pushDataLayerEventHandler} from '@/utils/utils'
import router from 'next/router'
import { getParamsFromQuery } from 'services/queryString';

/**
 * get List of Properties | Search API
 * @param {Object} query
 * @returns
 */
export const getSearhData = async (reqHeaders, reqBody) => {
  const newReqBody = {...reqBody}
  if(newReqBody['project']=='true'){
    newReqBody['project'] = true
  }
  try {
    const response = await fetch(`${newReqBody['project'] ? process.env.NEXT_PUBLIC_API_URL_V3:process.env.NEXT_PUBLIC_API_URL_V6}/properties/filter`,{
      method: "POST",
      headers: reqHeaders,
      body: JSON.stringify(newReqBody)
    })
    const res = await response.json()
    const data = res
    if (res.status === true) {
      return data
    } 
    throw res.status
  } catch (error) {
    return error?.response
  }
}

export const getUserRecentSearchByUserId = async (reqHeaders) => {
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL_V3}/user-search`, {
      method: 'GET',
      headers: reqHeaders
    })
    const res = await response.json()
    const data = res.data
    if (res.status === true) {
      return data
    } 
    throw res.status
  } catch (error) {
    return error?.response
  }
}
export const getUserRecentSearchForLocal = async (reqHeaders,reqBody) => {
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL_V3}/user-search`, {
      method: 'POST',
      headers: reqHeaders,
      body: JSON.stringify(reqBody)
    })
    const res = await response.json()
    const data = res.data
    if (res.status === true) {
      return data
    } 
    throw res.status
  } catch (error) {
    return error?.response
  }
}
export const getFeatureAgentData = async (reqHeaders, reqBody) => {
  try {
    if (reqBody?.zoneId || reqBody?.districtId) {
      const response = await axios({
        method: 'post',
        url: `${process.env.NEXT_PUBLIC_API_URL_V1}/agent/feature`,
        headers: reqHeaders,
        data: reqBody,
      })
      const data = response.data.data
      if (response.status) {
        return data
      } 
      throw response.status
    }
    else { 
      return [] 
    }
  } catch (error) {
    return error?.response
  }
}
export const getClientSideFeatureAgent = async (headers, body) => {
  try {
    if(body.districtId && typeof body.districtId === 'string'){
      body.districtId=body.districtId?.split(',')
    }
    const resp = await fetch(`/api/properties/featureAgent`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ ...body }),
    })
    const response_json = await resp.json()
    const responseContent = await response_json.data
    const featureData = await responseContent?.data
    return featureData || [];
  } catch (error) {
    return error?.response
  }
}

export const updateUserRecentSearchByUserId = async (reqHeaders, reqBody) => {
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL_V3}/user-search/create`, {
      method: 'POST',
      headers: reqHeaders,
      body: JSON.stringify(reqBody)
    })
    const res = await response.json()
    const data = res.data
    if (res.status === true) {
      return data
    } 
    throw res.status
  } catch (error) {
    return error?.response
  }
}
export const checkForCurrentLocation = async (reqHeaders, reqBody) => {
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL_V3}/location/district-by-current-location`, {
      method: 'POST',
      headers: reqHeaders,
      body: JSON.stringify(reqBody)
    })
    const res = await response.json()
    if (res.status === true) {
      return res
    } 
    return res.status
  } catch (error) {
    return error?.response
  }
}
export const getPageType = ()=>{
  const {route} = router.router;

  if(JSON.parse(localStorage.getItem('expandMap'))) return 'SRP_MAP' // if map has been expanded to full page view

  switch(route){
    case '/[slug]/search' : 
      return 'SRP'
    case '/':
      return 'Home'
    default : return 'Not Found'
  }
}

const pageCheckFunc = () => {
  let pageType = getPageType()
  if (pageType === 'SRP' && localStorage?.getItem('zonefilterClick')) {
    const mapcheck = JSON.parse(localStorage?.getItem('zonefilterClick'))
    if (mapcheck.isMapView) {
      return 'SRP-Map'
    } else {
      return 'SRP-List'
    }
  }
}

const detailsIDCheck = (details) => {
  if (details?.id) {
    return 'by_Property_id'
  }
  return ''
}

const detailsCityIDCheck = (details) => {
  if (details?.cityId) {
    return details?.cityId
  }
  return details?.city_id || router?.router?.query?.cityId
}

const detailsCityCheck = (details) => {
  if (details?.city) {
    return details?.city
  }
  return ''
}

const district_list_check = (details) => {
  if (details?.district?.join('') || details?.locationType === 'district') {
    return details?.srpTitle
  }
  return ''
}

const inputValueCheck = (inputValue) => !!inputValue

const checkProperty = (details, property) => !!details?.[property]

const serviceCheck = (details) => {
  return details?.propertyFor || details?.service || ''
}

const zoneIdCheck = (details) => {
  return details?.zones?.map(zone=>zone?.id).join(',') || ""
}

const zoneCheck = (details) => {
  return details?.zones?.map(zone=>zone?.name).join(',') || ""
}

const district_count_check = (details) => (details?.districtId && !Array.isArray(details?.districtId) ? 1 : '')
const district_id_check = (details) => (Array.isArray(details?.districtId) ? details?.districtId?.join('') : details?.districtId || '')

const statusCheck = (details, property) => {
  return details?.[property] || ""
}

export const searchAnalyticEvent=(details,searchType,inputValue,window,lang,eventName)=>{
  const pageType = pageCheckFunc()
  const eventObj = {
    search_keyword: inputValue || "",
    search_result_clicked: Array.isArray(details?.title) ? details?.title.join(',') : details?.title || "",
    search_result_displayed : '',
    top_location_clicked: checkProperty(details, 'position'),
    recent_search_clicked : searchType === 'recent search',
    use_my_current_loc : searchType === 'location',
    search_type : details?.locationType ? details?.locationType : detailsIDCheck(details),
    service : serviceCheck(details),
    popular_district: checkProperty(details, 'popularDis'),
    nearby_district:  checkProperty(details, 'nearBy'),
    start_new_search: inputValueCheck(inputValue),
    city_id: Array.isArray(details?.cityId) ? details?.cityId?.[0] : detailsCityIDCheck(details),
    city: Array.isArray(details?.city) ? details?.city?.[0] : detailsCityCheck(details),
    zone: zoneCheck(details),
    zone_id: zoneIdCheck(details),
    district_list: Array.isArray(details?.district) && details?.district.length > 0 ? details?.district.join(',') : district_list_check(details), 
    district_count: (details?.district?.length && details?.district?.length > 0) ? details?.district?.length : district_count_check(details),
    district_id: (details?.districtId?.length && details?.district?.length > 0) ?  details?.districtId?.join(',') : district_id_check(details),
    permission_status : statusCheck(details, 'permission_status'),
    pill_name : details?.pill_name,
    scrolled_depth: details?.scrolled_depth,
    source: statusCheck(details, 'source'),
    popular_city: statusCheck(details, 'popular')
  }
  if(details?.districtId?.length){
    delete eventObj.popular_city
  }
  pushDataLayerEventHandler(window,lang,eventName,pageType,eventObj)
}


export const fetchNearByProperties = async (cities, slugQueries, query, locale, baseUrl) => {
  try {
    const params = getParamsFromQuery({ ...query, ...slugQueries });

    const ciytLocation = cities?.find((city) => city?.cityId == params?.cityId);
    delete params.slug;
  
    const reqBody = {
      ...params,
      page: 0,
      nearbySearch: {
        distance: 5,   //replace 40 with 5
        location: ciytLocation?.center || null,
      },
    };
   
    if(reqBody.sort) { delete reqBody['sort'] }
    const response = await axios({
      method: 'post',
      url: `${baseUrl || process.env.NEXT_PUBLIC_API_URL_V3}/properties/filter/nearby`,
      headers:  {
        "Content-Type": "application/json",
        locale,
        module: "post-property",
      },
      data: JSON.stringify(reqBody),
    })
    if (response?.data?.status) {
      return {
        nearbyLocation : reqBody,
        nearByProperties : response?.data?.data
      }
    } 
  } catch (error) {
    console.log(error)
    return {...error.response}
  }
}

export const getNearbyPropertiesByFilterQuries = async (headers, reqBody) => {
  try{
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL_V3 || process.env.NEXT_PUBLIC_API_INTERNAL_URL_V3}/properties/filter/nearby`,
    {
      method: "POST",
      headers,
      body: reqBody
    }
  )
  return await response.json()
  } catch (error) {
    console.error("Enable To Fetch Nearby Filter Data", error)
  }
}

export const NextPrevAPI = async (body, lang) => {
  try {
      const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL_V5}/properties/filter`,{
          method: 'Post',
          headers: {
              'Content-Type': 'application/json',
              locale: lang,
          },
          body : JSON.stringify({...body})
      })
      const response = await res.json()
      return response?.data
  } catch (error) {
    console.error("Enable To Fetch Data", error)
  }
}

export const strapiFlagPool = async (flag='') => {
  const apiURL = `${process.env.NEXT_PUBLIC_CMS_STRAPI_BASE_URL}/flag-pool/${flag}`
  try {
    const response = await fetch(apiURL)
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`)
    }
    const data = await response.json()
    return data
  } catch (error) {
    return null
  }
}
