import { useAppContext } from 'context/AppContext'
import useTranslation from 'next-translate/useTranslation'
import { useEffect, useRef, useState } from 'react'
import { searchAnalyticEvent } from 'services/searchService'
import TopLocations from '../TopLocations/TopLocations'
import css from './citySearchDesktop.module.scss'
import { useMediaQuery } from 'react-responsive'
import AllCities from '../AllCities'

export default function HomeSearchCity({
  dropDownData,
  setDropDownData,
  dropRef,
  inputValue,
  showSuggestion,
  setInputValue,
  setWrapperOpen,
  setInputError,
  isMobileOnly,
  setShowSuggestion,
  inputError,
  selectedCity,
  popularCities,
  selectedProperty,
  getHighlightedText,
  searchcityRef,
  onChnageForDistrict,
  toggleNRF,
  setToggleNRF,
  pillsArray,
  toggleSearchableModal,
  errorPage,
  pageName,
  onSearch,
  allCities=[]
}) {
  const { t, lang } = useTranslation('translations')
  const { state, dispatch } = useAppContext()
  const {
    commonReducer: { isDropDownDataEmpty, isProject },
    searchReducer,
  } = state
  const buttonRef = useRef()
  const isDesktop = useMediaQuery({ minWidth: 767 })
  const [shouldShowTopCities,setshouldShowTopCities] = useState(false)

  useEffect(() => {
    dispatch({ type: 'UPDATE_PROPERTYFOR', payload: 'sale' })
    dispatch({ type: 'UPDATE_PROPERTYTYPE', payload: 'residential' })
  }, [])

  useEffect(() => {
    if (isDesktop) {
      const checkIfClickedOutside = (e) => {
        if (inputError && buttonRef.current && !buttonRef.current.contains(e.target)) {
          setInputError(false)
          dispatch({ type: 'SET_IS_OUTSIDE_CLICK', payload: false })
        }
      }
      document.addEventListener('click', checkIfClickedOutside)
    }
  }, [inputError])
  useEffect(()=>{
    const defaultCity = {
      "propertyFor": "sale",
      "title": t('Home.RIYADH'),
      "countryId": 1,
      "regionId": 56,
      "cityId": 273,
      "districtId": null,
      "city": t('Home.RIYADH'),
      "srpTitle": t('Home.RIYADH'),
      "category": "residential",
      "propertyCount": 212,
      "muhakatatCount": 0,
      "compoundCount": 61,
      "locationType": "city",
      "popular":true
  }
    if( !searchReducer?.selectedItem || !searchReducer?.selectedItem?.countryName[0]){
      selectedCity(defaultCity)
      setTimeout(() => {
        setInputValue(defaultCity?.title)
      });
    }
    else if(searchReducer?.selectedItem && searchReducer?.selectedItem?.countryName[0]){
      setTimeout(() => {
        setInputValue(searchReducer?.selectedItem?.countryName[0])
      });
    }
  },[])

  const onFocusInput = () => {
    if (errorPage || pageName == 'ErrorPage') {
      window.scrollTo({
        top: 500,
        left: 0,
        behavior: 'smooth',
      })
      setTimeout(() => {
        setWrapperOpen(true)
        setInputError(false)
        isMobileOnly && toggleSearchableModal()
        if (!isMobileOnly) {
          setShowSuggestion(true)
        }
      }, 150)
    } else {
      window.scrollTo({
        top: 167,
        behavior: 'smooth',
      })
    }
    setWrapperOpen(true)
    setInputError(false)
    isMobileOnly && toggleSearchableModal()
    if (!isMobileOnly) {
      setshouldShowTopCities(true)
      setInputValue('')
      setShowSuggestion(true)
    }
    searchAnalyticEvent({ scrolled_depth: searchReducer?.scrollDepth }, '', '', window, lang, 'search_box_tap')
  }

  const handleCityLabelClick = () =>{
    document && document.getElementById("citySearch") && document.getElementById("citySearch").focus();
  }
  const handlePopularCitiesShow = (e) =>{
    if(e.target.value.trimStart()!='' && shouldShowTopCities){
      setshouldShowTopCities(false)
    }
    else if((!e?.target?.value || e?.target?.value.length === 0) && !shouldShowTopCities){
      setshouldShowTopCities(true)
    }
  }
  const checkDropDown = (dropDownArray) => {
    if(!isDropDownDataEmpty && dropDownArray.length){
      return dropDownArray?.slice(0, 10)?.filter((f) => f?.searchType === 'property')?.map((property) => (
          <li
            className={css.idNumber}
            key={property.id}
            onClick={() => selectedProperty(property)}
          >
            <span className={css.idLabel}> {t('Home.ID')}: </span>
            <span className={css.idText}>
              {getHighlightedText(property.darReference, inputValue)}
            </span>{' '}
            <p className={css.idTitle}>{property.title}</p>
          </li>
        ))
    }
  }

  const checkSelectedCity = (inputText,dropDownArray) => {
    if(inputText?.length >= 2 && !isDropDownDataEmpty && dropDownArray.length){
       return dropDownArray.slice(0, 10)?.filter((f) => f?.searchType !== 'property' && !f?.districtId)?.map((val) => (
          <div
            className={`${css.suggestion_list} ${val?.title===searchReducer?.selectedItem?.countryName[0] ? css?.active:''}`}
            onClick={() => {
              selectedCity(val)
              dispatch({type:"REMOVE_ALL_SELECTED_DISTRICT",payload: []});
              dispatch({ type: "SET_ALL_DISTRICTS", payload: [] })
            }}
          >
            <div className={css.cityWrap}>
              <span className={css.cityName}>{val?.title}</span>
            </div>
          </div>
        ))}
  }

  let content = (
    <>
      {checkSelectedCity(inputValue, dropDownData)}
    </>
  );

  if (!isNaN(inputValue) && inputValue?.length >= 2 && dropDownData.length) {
    content = (
      <>
        {checkDropDown(dropDownData)}
      </>
    );
  }
  const handleTopAndAllCities = () => (
    <div className={css.scrollbar}>
      {popularCities?.length ? <TopLocations
        title={t('common.POPULAR_CITIES')}
        top_locations={popularCities}
        selectedCity={selectedCity}
        pillsArray={pillsArray}
        onSearch={(cityDetail) => onSearch(cityDetail)}
        isFromHomeDesk={true}
      /> : ''}
      {allCities?.length ? <AllCities
        allCities={allCities}
        pillsArray={pillsArray}
        title={t('common.ALL_CITIES')}
        selectedCity={selectedCity}
        onSearch={(cityDetail) => onSearch(cityDetail)}
        isFromHomeDesk={true}
      /> : ''}
    </div>
  )

  const suggestionContent = () => {
    if (showSuggestion && inputValue && inputValue?.length >= 2 && !shouldShowTopCities && !isDropDownDataEmpty) {
      return (
        <div className={css.searchedCitiesHome}>
          <div className={css.scrollbar}>{content}</div>
        </div>
      )
    } else {
      return ''
    }
  }
  
  return (
    <>
      <div ref={dropRef} className={`${css.citySearchWrap} ${isProject ? css.projectSearchWrap : ''}`}>
      <div className={css.innerWrap} onClick={() => handleCityLabelClick()}>
        <label>{t("Search.CITY")}</label>
         <div className={css.inputWrap}>
           <input
            type='text'
            name='searchBox'
            id='citySearch'
            placeholder={t('Home.SEARCH')}
            value={inputValue}
            className={css.searchcity}
            onChange={(e) => {
                setToggleNRF(true)
                setInputValue(e.target.value.trimStart())
                handlePopularCitiesShow(e)
                setWrapperOpen(true)
                setInputError(false)
                setDropDownData([])
                onChnageForDistrict(e)
                if (inputValue?.length < 2 && isDropDownDataEmpty) {
                  dispatch({ type: 'SET_IS_DROP_DOWN_DATA_EMPTY', payload: false })
                }
              }
            }
            ref={searchcityRef}
            onFocus={() => onFocusInput()}
            onBlur={()=> setInputValue(searchReducer?.selectedItem?.countryName[0] || '')}
            autoComplete='off'
          />
        </div>
      </div>
      <div className={`${errorPage ? css.autoSuggest_404 : ''} ${css.suggestionSection} ${css.suggestionSectionHome
          } ${css.suggestionSection_Hs}`}
      >
        
        {/* for web view */}
        {showSuggestion && (
          <>
              {(!inputValue || shouldShowTopCities) && (popularCities?.length || allCities?.length) ?
                <div className={css.homeDesktopWrap}>
                 { handleTopAndAllCities() }
                </div>
                : ''
              }
            {suggestionContent()}

            {isDropDownDataEmpty && toggleNRF && inputValue && inputValue.length >= 2 ? (
              <div className={css.notFound}>
                <p>{t('Search.NO_RESULT_FOUND')}</p>
              </div>
            ) : (
              ''
            )}
          </>
        )}
      </div>
    </div>
    </>
  )
}
