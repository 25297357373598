import { setCookieOnLogin } from "../MatchMakerUtils"
import UCookies from 'universal-cookie'
import uniqueIdGenerator from '@/utils/generateUniqueId'
import dayjs from "dayjs"

export const commonApi = async (url, method, headers, errorLabel, callback, body=null) =>{
    try {
        const response = await fetch(url,{
            method,
            headers,
            body   
        })
        if(!response.ok){
            throw new Error(`Failed to fetch`)
        }
        const res = await response.json()
        callback(res)
    } catch (error) {
        console.error(errorLabel, error)
    }
}

export const checkUserEligiblityApi = async (req_id, dispatch, lang) => {
    const url = `${process.env.NEXT_PUBLIC_GATEWAY_URL}requirement-manager/package-eligibility-check?requirementId=${req_id}`
    const method =  'GET'
    const headers = {
          locale: lang,
          Cookie: `i18next=${lang}`,
          Authorization: window.localStorage.getItem('authToken') ?? '',
          'x-request-id': uniqueIdGenerator.getId()
    }
    const errorLabel = 'Error occurred in package-eligibility-check :'
    commonApi(url,method,headers,errorLabel,(res)=>{
        const {status, data} = res
        if (status) {
            dispatch({ type: 'SET_USER_ELIGIBLE', payload: data?.eligibleToUpgrade })
        }
    })
}

function localStorageUpdateFunc(loginStatus, token, data, cookie) {
    if (loginStatus && !token && data?.auto_login) {
      window.localStorage.setItem('authToken', data?.auto_login?.token)
      window.localStorage.setItem('refreshToken', data?.auto_login?.refreshToken)
      window.localStorage.setItem('userId', data?.auto_login?.id)
      window.localStorage.setItem('role', JSON.stringify(data?.auto_login?.role))
      window.localStorage.setItem('firstName', data?.auto_login?.fullName || '')
      window.localStorage.setItem('phoneNumber', data?.auto_login?.phoneNumber || '')
      window.localStorage.setItem('countryCode', data?.auto_login?.phoneNumberCountryCode || '')
      const authStoredDate = dayjs().format()
      window.localStorage.setItem('authStoredDate', authStoredDate)
      setCookieOnLogin(
        data?.auto_login?.token,
        data?.auto_login?.refreshToken,
        authStoredDate,
        data?.auto_login?.id,
        cookie,
      )
    }
  }

export const getRequirementsApi = async ( req_id, dispatch, router, lang, onPaymentPage, source = null, loginStatus = null) =>{
    const token = window.localStorage.getItem('authToken') || ''
    const cookie = new UCookies() 
    let tokenUrl = ''  
    if(!token) tokenUrl = `&source=${source}&onPaymentPage=${onPaymentPage}`
    const url = `${process.env.NEXT_PUBLIC_GATEWAY_URL}requirement-manager/get-requirements?requirementId=${req_id}${tokenUrl}`
    const method =  'GET';
    const headers = {
        'Content-Type': 'application/json',
        locale: lang,
        Authorization: token || null,
        'x-request-id': uniqueIdGenerator.getId()
      }
      const errorLabel = 'Error occurred in getRequirements :'
    commonApi(url, method, headers, errorLabel, (res) => {
      const { status, data } = res
      dispatch({ type: 'SET_IS_LOADING', payload: false })
      if (status) {
        localStorageUpdateFunc(loginStatus, token, data, cookie)
        if (data?.count === 0) {
          router.push('/user/myrequirements?source=mmlandingpage')
        } else {
          dispatch({ type: 'SET_LOGGEDIN_STATUS', payload: true })
          dispatch({ type: 'SET_GET_REQUIREMENTS_DATA', payload: data })
        }
      }
    })
}