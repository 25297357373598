import React, { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import Styles from './Styles.module.scss'
import useTranslation from 'next-translate/useTranslation'
import { isMobileOnly } from 'react-device-detect'
import { useAppContext } from 'context/AppContext'
import { searchAnalyticEvent } from 'services/searchService'
import { getParamsFromQuery } from 'services/queryString'
import { getAutoSuggestData } from 'services/cityOptionsService'

const MobileSrpSearch = dynamic(()=>import('../mobileSrpSearch/mobileSrpSearch'))
const AutoSearchSrpDesktop = dynamic(()=>import('../autoSearchSrpDesktop/autoSearchSrpDesktop'))
const HomeSearchBox = dynamic(()=>import('../HomeSearchBox/HomeSearchBox')) 
const EnableLocationDesktop = dynamic(()=>import('../EnableLocationDesktop/EnableLocationDesktop')) 
const EnableLocationMobile = dynamic(()=>import('../EnableLocationMobile/EnableLocationMobile')) 
const MobileHomeSearchPanel = dynamic(()=>import('../MobileHomeSearchPanel'))

export default function AutoSearch({ cities, inputValue, wrapperOpen, setInputValue, handleSetFilter = null, setWrapperOpen, searchCities, setSearchCities, searchProperties, handlePropertyIdChange, cityProp, setCitySelected, zonePayload, zoneOpen, setZoneOpen, countryOpen, setCountryOpen, propertySearchResult, interLinkOpen, setInterLinkOpen, handleCityClick, handlePropertyRedirection, pageName, toggleSearchableModal, changeType, transactionType, userSelectedCity, searchableModalVisible = false, setBackFade, backFade, slugQueries = null, errorPage=false, onSearch = null, searchLoader, setSearchLoader = null, searchSRP = false, setSearchSRP = null,isMobileSSR=false, handleSearchCrossClickMsite = null }) {
  
  const router = useRouter()
  const { state, dispatch } = useAppContext() 
  const { searchReducer, commonReducer } = state
  const { query } = router
  const routerParams = getParamsFromQuery(query)
  const { t, lang } = useTranslation('translations')
  const [propertyType, setPropertyType] = useState( routerParams?.propertyFor || slugQueries?.propertyFor || query?.slug || searchReducer?.propertyFor || 'sale' )
  const searchcityRef = useRef()
  const dropRef = useRef()
  const dropRefCity = useRef()
  const popularCities = cities?.length ? cities?.filter((item) => item?.popular === true) : []
  const allCities = cities?.length ? cities?.filter((item) => !item?.popular) : []
  const [showSuggestion, setShowSuggestion] = useState(false)
  const [showCitySuggetion,setShowCitySuggetion] = useState(false)
  const [toggleSearchableModalForSRP, setToggleSearchableModalForSRP] = useState(searchSRP || false)
  const [showHomeCitySearchLoaderMsite,setShowHomeCitySearchLoaderMsite] = useState(false)
  const tabs = [
    { translation: t('Home.BUY'), key: 'sale' },
    { translation: t('Home.RENT'), key: 'rent' },
    {translation: t('ProjectCarousel.Projects'), key:'project'}
  ]
  const nearByLocation = []
  const toggleLoader = false
  const viewPropertiesBtn = true
  const { whichSelected } = searchReducer.selectedItem
  const [citypropertity, setCitypropertity] = useState(false)
  const [menuopen, setMenuopen] = useState(false)
  const [locationDesktopPopUp, setLocationDesktopPopUp] = useState(false)
  const [outSidesaudi, setOutSidesaudi] = useState(false)
  const [inputError, setInputError] = useState(false)
  const [pillsArray, setPillsArray] = useState([])
  const [pillsSrpArray, setPillsSrpArray] = useState([])
  const [exceedDistrict, setExceedDistrict] = useState(false)
  const [popularDistrict, setPopularDistrict] = useState([])
  const [dropDownData, setDropDownData] = useState([])
  const [toggleNRF, setToggleNRF] = useState(false) // NRF = no result found
  const [analyticsKeyword, setAnalyticsKeyword] = useState('')
  const [viewBtnTrigger,setViewBtnTrigger] = useState(false);
  const [preSelectedPills,setPreSelectedPills] = useState(null)
  const [cityChanged, setCityChanged] = useState(false)
  const [noPopularDistrict, setNoPopularDistrict] = useState(false)
  const { isProject } = commonReducer
  const reqHeaders = {
    'Content-Type': 'application/json',
    cookie: `i18next=${lang}`,
    locale: lang,
  }

  const [onLoadItems, setOnLoadItems] = useState({
    isMobileOnly:false
  })

  useEffect(() => {
    setToggleSearchableModalForSRP(searchSRP)
  }, [searchSRP])

  useEffect(() => {
    setOnLoadItems({isMobileOnly})

  }, [isMobileOnly])
  const getCurrentSelectedData = (updatedPills = []) => {
    const uPill = updatedPills?.filter((f) => f.propertyFor === searchReducer.propertyFor)
    return uPill[uPill?.length - 1]?.locationType || null
  }
  const getDistrictTitle = () => getCurrentSelectedData(pillsArray) === 'city' ? `${t("NewPropertySearch.POPULAR_DISTRICTS_IN")} ${searchReducer?.selectedItem?.cityName[0] || ''}` : t("NewPropertySearch.NEARBY_DISTRICT")
  
  useEffect(()=>{
    const up = routerParams?.propertyFor || slugQueries?.propertyFor || query?.slug || 'sale';
      dispatch({ type: 'UPDATE_PROPERTYFOR', payload: up })
      if (pageName === 'SRPpage' && !slugQueries) {
        getSelectedVal()
      }
  },[routerParams?.propertyFor,slugQueries?.propertyFor]);

  useEffect(() => {
    if (pageName === 'SRPpage') {
      getSelectedVal()
    }
    return () => {
      if (pageName === 'SRPpage') {
        setPillsArray([])
      }
   }
  }, [])

  useEffect(()=>{
    if (pageName === 'SRPpage' && !isMobileOnly) {
      getSelectedVal()
      setCityChanged(true)
    }
  },[searchReducer?.selectedItem?.cityName[0]])

  useEffect(() => {
    const selectedDistricts = searchReducer?.selectedItem?.districtName;
    if (pageName === 'SRPpage' && selectedDistricts?.length) {
      getSelectedVal()
    }
  }, [JSON.stringify(searchReducer?.selectedItem?.districtName)])

  useEffect(() => {  // for page refersh
    const isSelectedDataEmpty = searchReducer?.selectedItem?.whichSelected;
    if (pageName === 'SRPpage' && isSelectedDataEmpty != null) {
      getSelectedVal()
    }
  },[searchReducer?.selectedItem?.whichSelected])

  
  useEffect(() => {
    changeType(searchReducer?.propertyFor);
    setPropertyType(searchReducer?.propertyFor)
    if (pageName === 'SRPpage' && !isMobileOnly) {
      getSelectedVal()
    }
  }, [searchReducer?.propertyFor])

  useEffect(() => {  //for recent search
    if(searchReducer?.recentSearchTrigger){
      dispatch({ type: 'RECENT_SEARCH_TRIGGER', payload: false });
      if (pageName === 'SRPpage') {
        getSelectedVal()
      }
    }
  }, [searchReducer?.recentSearchTrigger]);

  useEffect(() => {  //for filterapply
    if(searchReducer?.filterTrigger){
      dispatch({ type: 'FILTER_TRIGGER', payload: false });
      if (pageName === 'SRPpage') {
        getSelectedVal()
      }
    }
  }, [searchReducer?.filterTrigger]);

  useEffect(() => {  //for recent search
    if(searchReducer?.currentLocationTrigger){
      dispatch({ type: 'CURRENT_LOCATION_TRIGGER', payload: false });
      if (pageName === 'SRPpage') {
        getSelectedVal()
      }
    }
  }, [searchReducer?.currentLocationTrigger]);

  useEffect(() => {
    dispatch({ type: 'IS_SUGGESTION_OPEN', payload: showSuggestion })
    dispatch({type:'IS_CITY_SUGGETION_OPEN',payload: showCitySuggetion})
  }, [showSuggestion,showCitySuggetion])

  const [oldVal,setOldVal]=useState([]);

  useEffect(()=>{
    if(oldVal?.length>0){
      oldVal?.forEach((item) => {
          item.propertyFor = searchReducer?.propertyFor;
      });    
    }
  },[searchReducer?.propertyFor])
  const setCityIfNoDistrict = (sData) => {
    if (!sData?.districtId || (sData?.districtId && sData?.districtId?.length === 0)) {
      sData = { ...sData, whichSelected: 'city' };
    }
    return sData;
  };

  const getSelectedVal = () => {
    
    let sData = searchReducer?.selectedItem;
    const selectVal =  (searchReducer.propertyFor || routerParams?.propertyFor || slugQueries?.propertyFor|| query?.slug);
    sData = setCityIfNoDistrict(sData);
    if (sData.whichSelected === 'country') {
      const valArr = {
        countryId: sData?.countryId?.[0],
        title: sData?.countryName?.[0],
        locationType: 'country',
        propertyFor:  selectVal,
        autoId: parseInt(Date.now()),
      };
      setPillsArray([valArr])
      setOldVal([valArr])
    }
    if (sData.whichSelected === 'city') {
      
      const cityObj = {
        cityId: sData?.cityId?.[0],
        title: sData?.cityName?.[0],
        locationType: 'city',
        propertyFor: selectVal,
        autoId: parseInt(Date.now()),
      }
      if (sData.zoneName?.length > 0 && sData.zoneId?.length > 0 &&( !routerParams.cityId || routerParams.zoneId)) {
        cityObj.title = sData.zoneName?.[0]
      }
      setPillsArray([cityObj])
      setOldVal([cityObj])
    }
    if (sData.whichSelected === 'district') {
      
      setPillsArray(
        sData?.districtId?.length &&
        sData?.districtId?.map((dist, i) => {
          return {
            districtId: dist,
            title: sData.districtName[i],
            srpTitle: sData?.districtNameSRP && sData?.districtNameSRP[i] ? sData?.districtNameSRP[i] : sData.districtName[i],
            locationType: 'district',
            propertyFor: selectVal,
            autoId: parseInt(Date.now()),
            cityData: {
              cityId: '',
              cityName: '',
              countryId: '',
              countryName: ''
            }
          }
        })
      )
    }
  }

  useEffect(() => {
    if (zonePayload && zonePayload?.length && zonePayload[0].label === t('Home.ALL_ZONES')) {
      let zoneTotal = 0
      for (let i = 0; i < zonePayload?.length; i++) {
        if (i !== 0) {
          zoneTotal += zonePayload[i].count
        }
      }
      zonePayload[0].count = zoneTotal
    }
  }, [zonePayload])
 useEffect(() => {
   if(!searchReducer.enableLocation && setBackFade && pageName !== 'SRPpage'){
    setBackFade(false)
   }
   else if(searchReducer.enableLocation && pageName === 'SRPpage'){
     setBackFade(false)
     document.body.style.overflow = 'hidden'
   }
 }, [searchReducer.enableLocation])
 
  useEffect( () => {
    if (searchReducer.IsSrpMsiteOpen) {
      const updateVal = pillsArray?.map((p) => {
        p.propertyFor = searchReducer.propertyFor;
        return p;
      })
      setExceedDistrict(false)
      getAutoSuggestDataByInput(updateVal);
    }
  }, [searchReducer.propertyFor])

  // close wrapper dropdown on outside or escape click
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        dropRef.current &&
        !dropRef.current.contains(e.target) &&
        typeof e?.target?.className === 'string' &&
        !e?.target?.className?.includes("cityName")
        && !e?.target?.className?.includes("icon-close")
        && !e?.target?.className?.includes("chip")
        && !e?.target?.className?.includes("suggestion_wrap")
        && !e?.target?.className?.includes("propertyType")
        && !e?.target?.className?.includes("suggestion_list")
        && !e?.target?.className?.includes("textSpan")
        && !e?.target?.className?.includes("navDrawerContainer")
        && !e?.target?.className?.includes("navMenuDrawer")
        && e?.target?.id !== 'cityName' &&
        e?.target?.name !== 'searchBox'
      ) {
        if (pageName === 'SRPpage') {
          setCitySelected(true)
          setShowSuggestion(false)
          // !isMobileOnly && setInputValue(cityProp)
        }else{
          
          setShowSuggestion(false)
          setWrapperOpen(false)
        }
      }
    }
    const closeDropdown = (e) => {
      if (e.keyCode == 27) {
        setWrapperOpen(false)
        setShowSuggestion(false)
        if (pageName === 'SRPpage') {
          setCitySelected(true)
          setInputValue(cityProp)
        }
      }
    }
    window.addEventListener('keydown', closeDropdown)
    document.addEventListener('click', checkIfClickedOutside)
    return () => {
      window.removeEventListener('keydown', closeDropdown)
      document.removeEventListener('click', checkIfClickedOutside)
    }
  }, [wrapperOpen])

  // close zone dropdown on outside or escape click
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (zoneOpen && dropRef.current && !dropRef.current.contains(e.target) && e.target.id !== 'selectZone') {
        setZoneOpen && setZoneOpen(false)
      }
    }
    const closeDropdown = (e) => {
      if (e.keyCode == 27) {
        setZoneOpen && setZoneOpen(false)
      }
    }
    window.addEventListener('keydown', closeDropdown)
    document.addEventListener('click', checkIfClickedOutside)
    return () => {
      window.removeEventListener('keydown', closeDropdown)
      document.removeEventListener('click', checkIfClickedOutside)
    }
  }, [zoneOpen])
  useEffect(()=>{
    const checkIfClickedOutsideOfCity = (e) => {
      if (showCitySuggetion && dropRefCity.current && !dropRefCity.current.contains(e.target)) {
        setShowCitySuggetion(false)
        setCitySelected(true)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutsideOfCity)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutsideOfCity)
    }
  },[showCitySuggetion])
  // close country dropdown on outside or escape click
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (countryOpen && dropRef.current && !dropRef.current.contains(e.target)) {
        setCountryOpen && setCountryOpen(false)
      }
    }
    const closeDropdown = (e) => {
      if (e.keyCode == 27) {
        setCountryOpen && setCountryOpen(false)
      }
    }
    window.addEventListener('keydown', closeDropdown)
    document.addEventListener('click', checkIfClickedOutside)
    return () => {
      window.removeEventListener('keydown', closeDropdown)
      document.removeEventListener('click', checkIfClickedOutside)
    }
  }, [countryOpen])

  //filter data from the list which label value match with inputvalue
  const filterCity = (data, searchCity) => {
    const l1 = data
      .filter((item) => item?.title?.toLowerCase().startsWith(searchCity?.toLowerCase()))
      .sort((a, b) => (b.title > a.title ? -1 : 1))
    const l2 = data
      .filter((item) => !item?.title?.toLowerCase().startsWith(searchCity?.toLowerCase()))
      .filter((item) => item?.title?.toLowerCase().includes(searchCity?.toLowerCase()))
      .sort((a, b) => (b?.title > a?.title ? -1 : 1))

    return [...l1, ...l2]
  }
  useEffect(() => {
        if (!isNaN(inputValue) && inputValue?.length >= 1 && inputValue?.length <= 6) {
            handlePropertyIdChange(inputValue)
          } else {
      if (inputValue?.length === '' || inputValue?.length < 2) {
        setSearchCities({ popularCities, allCities })
      } else {
        const filterData = filterCity(cities, inputValue?.trim())
        setSearchCities({ allCities: filterData })
      }
    }
      }, [inputValue,cities])   
  const selectedCity = (cityDetails,isBack = false) => {
    const data = {...cityDetails,
                ...nearByLocation,
                scrolled_depth:searchReducer?.scrollDepth,
                popularDistrict}
    const keyword = inputValue || analyticsKeyword;
    if(pageName==='SRPpage' && !isBack){
      setPopularDistrict([])
      searchAnalyticEvent(data, 'top locations', keyword, window, lang, 'search')
    }
    if(pageName !== 'SRPpage'){
      setShowSuggestion(false) 
      dispatch({
        type: 'SELECTED_ITEM',
        payload: {
          cityId: [cityDetails?.cityId],
          cityName: [cityDetails?.city],
          locationType: cityDetails?.locationType,
          whichSelected: cityDetails?.locationType,
          districtId:[cityDetails?.districtId],
          districtName:[cityDetails?.title],
          countryId:[cityDetails?.countryId],
          countryName:[cityDetails?.title],
          popularCity:cityDetails?.popular
        },
      })
    }
    if (!isMobileOnly || pageName !== 'SRPpage') {
      setCitypropertity(true)
    }
    handleCityClick(cityDetails,isBack)
    setInputValue(cityDetails?.title)
    setWrapperOpen(false)
    if (pageName === 'SRPpage') {
      setZoneOpen && setZoneOpen(false)
      setCitySelected(true)
    }
  }

  useEffect(()=>{

    if(pageName === 'SRPpage' && !searchReducer?.isSuggestionOpen && pillsArray?.length == 0 && oldVal?.length > 0 && !isMobileOnly){
      setPillsArray(oldVal);
    }
  },[searchReducer?.isSuggestionOpen])

  const displayPills = () =>{
    if(pillsArray.length == 0 && !showSuggestion){
      return oldVal
    }
    return pillsArray
  }

  const displayPlaceHolder = () =>{
    if(searchReducer?.selectedItem?.cityName[0]){
      return `${t("NewPropertySearch.SEARCH_DISTRICTS_IN")} ${searchReducer?.selectedItem?.cityName[0]}`
    }
    return t('Home.ADD_DISTRICT')
  }

  const getPlaceholderText = (isMobileModalSearchScreen=false,defaultSearchPlaceholder=false) => {
    const oldestPillArray = displayPills()  
    if(defaultSearchPlaceholder){
        return displayPlaceHolder()
    } 
    if (oldestPillArray && oldestPillArray.length >= 5) {
      return ''
    }
    if (!oldestPillArray || oldestPillArray === null || isMobileModalSearchScreen && pillsArray.length === 0) {
      return t('Home.SEARCH_BY_DISTRICT_CITY_OR_PROPERTY_ID')
    }
    if ((pageName !== 'SRPpage') || (oldestPillArray && oldestPillArray?.filter((f) => f.propertyFor === searchReducer.propertyFor)?.length == 0)) {
      return t('Home.SEARCH_BY_DISTRICT_CITY_OR_PROPERTY_ID')
    }
    if (oldestPillArray && getCurrentSelectedData(oldestPillArray) === 'country') {
      return t('Home.ADD_CITY_DISTRICT')
    }
    if(getCurrentSelectedData(oldestPillArray) === 'city'){
      return `${t("NewPropertySearch.SEARCH_DISTRICTS_IN")} ${searchReducer?.selectedItem?.cityName[0]}`
    }
    if ( getCurrentSelectedData(oldestPillArray) === 'district') {
      return t('Home.ADD_DISTRICT')
    }
  }

  const selectedProperty = (propertyData) => {
    const data = {
        ...propertyData,
        ...nearByLocation,
        scrolled_depth:searchReducer?.scrollDepth,
        popularDistrict}
    const keyword = inputValue || analyticsKeyword;
    searchAnalyticEvent(data, 'top locations', keyword, window, lang, 'search')
    if (!isMobileOnly || pageName !== 'SRPpage') {
      setCitypropertity(true)
    }
    setWrapperOpen(false)
    handlePropertyRedirection(propertyData?.slug, propertyData.propertyFor, inputValue, propertyData?.title)
  }
  const linkOpen = () => {
    setInterLinkOpen(!interLinkOpen)
    setWrapperOpen(false)
    setZoneOpen && setZoneOpen(false)
  }
  const changePropertyType = (type) => {
    setPropertyType(type)
    setShowSuggestion(false)
    setInputError(false)
  }

  const getHighlightedText = (text, highlight) => {
    highlight = highlight.trim();
    const highlighted = text?.replace(
      new RegExp(highlight, 'gi'),
      (highlightedVal) => `<strong class=${Styles.textSpan} style="font-weight:500">${highlightedVal}</strong>`,
    );
    return <span className={Styles.textSpan} dangerouslySetInnerHTML={{ __html: highlighted }} />
  }

  const checkEmptyInput = (input) => {
    if (input === '' || input===undefined) {
      setInputError(true)
      setShowSuggestion(false)
      return false
    }
    return true
  }

  useEffect(() => {
    dispatch({ type: 'IS_SRP_MSITE_OPEN', payload: toggleSearchableModalForSRP })

    if (toggleSearchableModalForSRP) {
      document.body.style.overflow = 'hidden'
    } else if(!sessionStorage.getItem("isTopMenuBtnClicked")) {
      //Added check so that it can not scroll when right profile menu will be open
      document.body.style.overflow = 'initial';
    }
  }, [toggleSearchableModalForSRP])

  useEffect(() => {
    if(pillsArray?.length>0){
      pillsArray?.forEach((item) => {
        item.propertyFor = searchReducer?.propertyFor;
    });
    }
    getPlaceholderText();
    if(!viewBtnTrigger){
      dispatch({
        type: 'SRP_PILL_DATA',
        payload: pillsArray,
      });
    }else{
      setViewBtnTrigger(false)
    }
    if(pillsArray?.length == 1 && pillsArray[0]?.locationType=='city' && noPopularDistrict){
      setDropDownData([])
      setPopularDistrict([])
    }
    if (pillsArray?.length >= 5) {
      setDropDownData([])
      setPopularDistrict([])
      setExceedDistrict(true);
      return;
    }
    setExceedDistrict(false);
  }, [pillsArray])

  const districtExceedCheck = () =>{
    if(exceedDistrict){
      setExceedDistrict(false);
    }
  }

  const setLocationType = (dataObj) => {
    let firstLocationType = pillsArray[0].locationType
    // if pill array already contains some exsisting value for either of city / district / country;
    if (firstLocationType !== 'city' && !isMobileOnly) {
      if (dataObj?.locationType == 'city') {
        firstLocationType = dataObj?.locationType
        setPillsArray([dataObj])
      }
    }
    return firstLocationType
  }

  const checkDuplicateDistricts = (item, dataObj) =>{
    if (item?.districtId === dataObj?.districtId && dataObj?.districtId!=null){
      return null
    }
  }

  const addToPillsArray = async (dataObj) => {
    setAnalyticsKeyword(inputValue)
    dispatch({type:"SET_SHOW_ALL_CLICK", payload: false})
    if(pillsArray?.length >= 5){
      setDropDownData([])
      setPopularDistrict([])
      setExceedDistrict(true);
      return;
    }

    districtExceedCheck()
    if(!isProject){
      searchcityRef.current.value = '' // remove input text on addition of pill
    }

    // dataObj can be a city / distrtict or a country 
    const { locationType } = dataObj;

    // pill array can be empty 
    if (pillsArray?.length === 0) {
      setPillsArray([dataObj])
      getAutoSuggestDataByInput([dataObj])
      return;
    }

    // validation for duplicate entries when adding
    const tempPillArray = pillsArray.filter((item) => item.propertyFor === searchReducer?.propertyFor)
    for (const item of tempPillArray) {
      checkDuplicateDistricts(item, dataObj)
    }
    const firstLocationType = setLocationType(dataObj)

    let tempPills
    switch (firstLocationType) {
      case 'country':
        {
          if (locationType === 'district' || locationType === 'city') setPillsArray([dataObj])
          tempPills = [dataObj];
          break;
        }
      case 'city':
        {
          if( locationType === 'city') setPreSelectedPills([dataObj])
          if (locationType === 'district') setPillsArray([dataObj])
          tempPills = [dataObj]
          break;
        }
      case 'district':
        {
          if (locationType === 'district') setPillsArray([...pillsArray, dataObj])
          tempPills = [...pillsArray, dataObj]
          break;
        }

    }
    setDropDownData([])
    // need to get popular district or nearby district based on current pills
    getAutoSuggestDataByInput(tempPills)

  }
  
  const handleRemovePill = (objToRemove) =>{
    if(objToRemove?.locationType=='country') {
      // setRemovedPillLocationTypeIsCountry(true)
      dispatch({ type: 'REMOVED_PILL_COUNTRY', payload: true })
      dispatch({ type: 'REMOVED_PILL_CITY', payload: false })
      dispatch({ type: 'REMOVED_PILL_DISTRICT', payload: false })
    } else if(objToRemove?.locationType=='city') {
      dispatch({ type: 'REMOVED_PILL_COUNTRY', payload: false })
      dispatch({ type: 'REMOVED_PILL_CITY', payload: true })
      dispatch({ type: 'REMOVED_PILL_DISTRICT', payload: false })
    } else if(objToRemove?.locationType=='district') {
      dispatch({ type: 'REMOVED_PILL_COUNTRY', payload: false })
      dispatch({ type: 'REMOVED_PILL_CITY', payload: false })
      dispatch({ type: 'REMOVED_PILL_DISTRICT', payload: true })
    }
  }

  const filterPills = (isClearAll, isCrossClick, index) =>{
    if(!isClearAll && !isCrossClick){
      return pillsArray.filter((_items, itemIndex) => index !== itemIndex)
    }
    return []
  }

  const removePill = async (_value, objToRemove,isClearAll=false,isCrossClick=false) => { 
      const { index } = objToRemove;
      !isClearAll && !isCrossClick && searchAnalyticEvent({...objToRemove,pill_name : objToRemove?.title},'','',window,lang,'pill_removed')
    dispatch({ type: 'RECENT_SEARCH_VALUE', payload: null })
    dispatch({type:"SET_SHOW_ALL_CLICK", payload: false})
    handleRemovePill(objToRemove)
    const filteredPill = filterPills(isClearAll, isCrossClick, index)
    if(!isMobileOnly){
      if ((oldVal?.length == 0 && filteredPill?.length == 0) || isClearAll) {
        const cityDetails = searchReducer?.selectedItem;
        const selectedCites = [{ cityId: cityDetails?.cityId[0], title: cityDetails?.cityName[0], locationType: 'city',propertyFor:searchReducer?.propertyFor}]
        setPillsArray(selectedCites || []);
        setDropDownData([])
        getAutoSuggestDataByInput((selectedCites || []),true)
        return;
      }
      setPillsArray(filteredPill?.length!==0 ? filteredPill : oldVal);
      setDropDownData([])
      getAutoSuggestDataByInput(filteredPill?.length!==0 ? filteredPill : oldVal,true)
      return;
    }
    if(isCrossClick){
      setPillsArray([]);
      setOldVal([])
      setDropDownData([])
    return;
    }
    setPillsArray(filteredPill);
    setDropDownData([])
    getAutoSuggestDataByInput(filteredPill) // need to get popular district or nearby district based on current pill
  }
const handleClearDropDown = (outSideClick) =>{
  if (outSideClick) {
    setTimeout(() => {
      setDropDownData([]); // should not flicker on outside click
    }, 80);
  }
  else {
    setDropDownData([]);
  }
}
const handleUpdatedPills = (uVal) =>{
  return uVal === null
  ? Array.isArray(pillsArray) && pillsArray?.filter((f) => f.propertyFor === searchReducer.propertyFor)
  : Array.isArray(uVal) && uVal?.filter((f) => f.propertyFor === searchReducer.propertyFor);
}

  const isCountryFlag = (updatedPills) => updatedPills?.[0]?.locationType !== 'country';

  const getCountryId = (updatedPills) =>{
    if(updatedPills?.[0]?.locationType === 'country'){
      return updatedPills?.[0]?.countryId
    }
    return null 
  }

  const handleFetchAutoSuggestData = async (updatedPills = [], bodyData={}) => {
    if (updatedPills?.length == 0 || updatedPills?.[0]?.locationType === 'country') {
      const topCityData = await getAutoSuggestData(reqHeaders, {
        searchText: '',
        propertyFor: searchReducer.propertyFor || routerParams?.propertyFor || slugQueries?.propertyFor || query.slug,
        countryFlag: isCountryFlag(updatedPills),
        newAutoSuggest: true,
        countryId: getCountryId(updatedPills)
      });
      setDropDownData(topCityData?.allCities)
      setPopularDistrict([])
    } else {
      const autoSuggest = await getAutoSuggestData(reqHeaders, bodyData);
      dispatch({ type: "SET_SHOW_ALL_DISTRICTS_LIST", payload: autoSuggest?.allDistricts })
      setDropDownData([]);
      setPopularDistrict(autoSuggest?.allCities)
      if (updatedPills?.[0]?.locationType === 'city') {
        setNoPopularDistrict(false)
      }
      // toggle NRF based on length
      if (autoSuggest?.allCities?.length === 0) {
        setToggleNRF(true)
        if (updatedPills?.[0]?.locationType === 'city') {
          setNoPopularDistrict(true)
        }
      }
      else setToggleNRF(false)
    }
  }
  // this function will fetch  list of districts related to a city  || district
  // only city will get popular districts
  // with only districts will nearby districts
  const getAutoSuggestDataByInput = async (uVal = null, isRemove = false, outSideClick = false) => {
   handleClearDropDown(outSideClick)
    const bodyData = {
      propertyFor: searchReducer.propertyFor || routerParams?.propertyFor || slugQueries?.propertyFor,
      newAutoSuggest: true
    }
    const updatedPills = handleUpdatedPills(uVal)
      
    switch (updatedPills?.[0]?.locationType) {
      case 'city':
        {
          if (updatedPills.length > 1) {
            bodyData.districtId = updatedPills.map((f) => f?.districtId).filter((f) => f)
          }
          bodyData.cityId = updatedPills?.[0].cityId
          break;
        }

      case 'district':
        {
          bodyData.districtId = updatedPills.map((f) => f?.districtId)
          break;
        }

      case 'country':
        {
          bodyData.countryId = updatedPills.map((f) => f?.countryId)
          break;
        }
    }
    (!cityChanged && uVal && uVal[0]?.locationType!=='city' || isRemove) && dispatch({type: "SET_SHOW_DISTRICT_LOADER", payload : true})
    await handleFetchAutoSuggestData(updatedPills,bodyData)
    setCityChanged(false)
    dispatch({ type: 'SET_IS_DROP_DOWN_DATA_EMPTY', payload: false })
    dispatch({type: "SET_SHOW_DISTRICT_LOADER", payload : false})
  }

  const onChnageForDistrict = async (e,fromSRPCity=false,fromSRPDistrict=false) => {
    const inptval =  e?.target?.value
        if (inptval?.length >= 2) {
      const regex = /\d\s+[a-zA-Z]/
      if(regex.test(inptval?.toLowerCase())){
        dispatch({ type: 'SET_IS_DROP_DOWN_DATA_EMPTY', payload: true })
        setDropDownData([]);
        return;
      }
      // It should not call api for numeric search values
      if((pageName !== 'SRPpage' || (pageName === 'SRPpage' && fromSRPCity)) && /^\d*$/.test(inptval?.replace(/\s/g, '')?.trim())){   
        dispatch({ type: 'SET_IS_DROP_DOWN_DATA_EMPTY', payload: true })
        return;
      }
      setDropDownData([]);
            const pillId = pillsArray?.filter((f) => f?.locationType === 'district')?.map((pill) => pill?.districtId || pill?.id);
      
      // here 2 cases exist first pill can be city or district
      let bodyData = {
        propertyFor: searchReducer?.propertyFor || routerParams?.propertyFor || slugQueries?.propertyFor || query.slug || 'sale',
        newAutoSuggest: true,
        districtId: pillId || [],
        cityId:
          pillsArray?.[0]?.locationType === 'city'
            ? pillsArray?.[0]?.cityId
            : routerParams?.cityId,
        searchText: inptval?.trim(),
        
      }
      if(!fromSRPDistrict && (!pillId || pillId?.length==0)){ //If it's city
        bodyData={
          ...bodyData,
          cityOnly:true
        }
      }
      if (pillsArray?.length == 0 || fromSRPCity) {
        delete bodyData.cityId
        if(bodyData?.districtId && fromSRPCity){
          delete bodyData.districtId
          bodyData = {...bodyData,cityOnly:true}
        }
      }
      if(fromSRPDistrict && pageName === 'SRPpage'){
        bodyData = {...bodyData,districtOnly:true} 
      }
      setShowHomeCitySearchLoaderMsite(true)
      const autoSuggest = await getAutoSuggestData(reqHeaders, bodyData)
      if(!autoSuggest || autoSuggest.length === 0){
        dispatch({ type: 'SET_IS_DROP_DOWN_DATA_EMPTY', payload: true })
        setDropDownData([]);
      }
      else{
        if (!isNaN(inptval)) {
          setDropDownData(autoSuggest || []);
          dispatch({ type: 'SET_IS_DROP_DOWN_DATA_EMPTY', payload: autoSuggest.filter((f) => f?.searchType === 'property')?.length>0 ? false : true })
        } else {
          setDropDownData(autoSuggest?.filter((f) => {
            if (f.title?.split(',')?.[0]?.toLowerCase().match(new RegExp(inptval?.toLowerCase())) || f.locationType !== 'district') {
              return f;
            }
          }) || []);
          if(pageName !== 'SRPpage'){
          dispatch({ type: 'SET_IS_DROP_DOWN_DATA_EMPTY', payload: autoSuggest.filter((f) => f?.searchType !== 'property' && !f?.districtId)?.length>0 ? false : true })
          }
          else if(pageName === 'SRPpage'){
            dispatch({ type: 'SET_IS_DROP_DOWN_DATA_EMPTY', payload: autoSuggest.filter((f) => f?.searchType !== 'property')?.length>0 ? false : true })
          }
        }
      }
      
      setShowHomeCitySearchLoaderMsite(false)
    } 
  }
  
  const viewProperties = async (isMobile = false) => {
    let analyticsPill = pillsArray[0]
    if(pillsArray[0]?.locationType === 'district'){
      const districtPill = {
        locationType : pillsArray[0]?.locationType,
        propertyFor : pillsArray[0]?.propertyFor,
        title : pillsArray?.map((d)=>d?.title?.split(',')?.[0]),
        districtId : pillsArray?.map((d)=>d?.districtId),
        district : pillsArray?.map((d)=>d?.title?.split(',')?.[0]),
        city : pillsArray?.[0]?.title?.split(',')[1]?.trim(),
        popularDistrict,
        nearBy : (pillsArray?.find((district)=>district?.regionId && district?.locationType && district?.distance )) ? true : false , // distance present in nearby
        popularDis : (pillsArray?.find((district)=>!district?.distance ) && pillsArray[0]?.locationType !== 'city') ? true : false // distance not presnt in pop
      }
      analyticsPill = districtPill;
    }
    searchAnalyticEvent({...analyticsPill,scrolled_depth:searchReducer?.scrollDepth}, '', analyticsKeyword, window, lang, 'search')
    if (getCurrentSelectedData(pillsArray) !== 'district') {
      const cityData = pillsArray?.filter((f) => f.propertyFor === searchReducer.propertyFor)?.[0]
      selectedCity(cityData);
    } else {
      dispatch({
        type: 'UPDATE_DISTRICT_SRP',
        payload: pillsArray?.filter((f) => f.propertyFor === searchReducer.propertyFor),
      });
    }
    if (isMobile) {
        setToggleSearchableModalForSRP(false)
        setSearchSRP && setSearchSRP(false)
    }
  }


  const getLocationTypeString = (locationType) => {
    switch (locationType) {
      case 'city': return t('Search.CITY')
      case 'country': return ''
      case 'district': return t('Search.DISTRICT')
    }
  }

const handleSearchMsiteSRP = (cityInfo,districtAndPropertyTypeObj) =>{
  getSelectedVal();
  onSearch(cityInfo,districtAndPropertyTypeObj);
  handleSetFilter('search')
}
  return (
    <>
      {/* add input for srp
      onfousu show  srpPOP up */}
      
        {locationDesktopPopUp && (
          <div className={Styles.isNotMobile}>
          <EnableLocationDesktop
          setLocationDesktopPopUp={setLocationDesktopPopUp}
          />
          </div>
        )}
      

      <div className={Styles.isNotDesktop}>

        {locationDesktopPopUp && (
          <EnableLocationMobile 
          setLocationDesktopPopUp={setLocationDesktopPopUp} />
        )}
      </div>

      {/* SRP Msite */}
      <div className={`${Styles.isNotDesktop} ${pageName === 'SRPpage' ? Styles.areaHeight : ''}`}>
        {pageName === 'SRPpage' && (
          <MobileSrpSearch
          setDropDownData={setDropDownData}
          handleSetFilter={handleSetFilter}
          oldVal={oldVal}
          Styles={Styles}
          setShowSuggestion={setShowSuggestion}
          searchcityRef={searchcityRef}
          dropRef={dropRef}
          showSuggestion={showSuggestion}
          getPlaceholderText={getPlaceholderText}
          pillsArray={pillsArray}
          onChnageForDistrict={onChnageForDistrict}
          setToggleNRF={setToggleNRF}
          setInputValue={setInputValue}
          getAutoSuggestDataByInput={getAutoSuggestDataByInput}
          isMobileOnly={onLoadItems.isMobileOnly}
          linkOpen={linkOpen}
          setMenuopen={setMenuopen}
          setWrapperOpen={setWrapperOpen}
          toggleSearchableModal={toggleSearchableModal}
          toggleLoader={toggleLoader}
          pageName={pageName}
          selectedCity={selectedCity}
          propertyType={propertyType}
          dropDownData={dropDownData}
          addToPillsArray={addToPillsArray}
          getHighlightedText={getHighlightedText}
          viewPropertiesBtn={viewPropertiesBtn}
          exceedDistrict={exceedDistrict}
          menuopen={menuopen}
          removePill={removePill}
          inputValue={inputValue}
          popularDistrict={popularDistrict}
          t={t}
          lang={lang}
          getDistrictTitle={getDistrictTitle}
          toggleNRF={toggleNRF}
          whichSelected={whichSelected}
          popularCities={popularCities}
          viewProperties={viewProperties}
          setOutSidesaudi={setOutSidesaudi}
          setBackFade={setBackFade}
          backFade={backFade}
          tabs={tabs}
          handlePropertyIdChange={handlePropertyIdChange}
          changePropertyType={changePropertyType}
          setLocationDesktopPopUp={setLocationDesktopPopUp}
          toggleSearchableModalForSRP={toggleSearchableModalForSRP}
          setToggleSearchableModalForSRP={setToggleSearchableModalForSRP}
          propertySearchResult={propertySearchResult}
          searchProperties={searchProperties}
          selectedProperty={selectedProperty}
          transactionType={transactionType}
          userSelectedCity={userSelectedCity}
          nearByLocation={nearByLocation}
          locationDesktopPopUp={locationDesktopPopUp}
          outSidesaudi={outSidesaudi}
          setExceedDistrict={setExceedDistrict}
          pillsSrpArray={pillsSrpArray}
          setPillsSrpArray={setPillsSrpArray}
          setPillsArray={setPillsArray}
          wrapperOpen={wrapperOpen}
          setSearchSRP={setSearchSRP}
          searchableModalVisible={searchableModalVisible}
          setInputError={setInputError}
          inputError={inputError}
          checkEmptyInput={checkEmptyInput}
          getLocationTypeString={getLocationTypeString}
          errorPage={errorPage}
          onSearch={(cityInfo,districtAndPropertyTypeObj)=>handleSearchMsiteSRP(cityInfo,districtAndPropertyTypeObj)}
          searchLoader={searchLoader}
          setSearchLoader={setSearchLoader}
          isMobileSSR={isMobileSSR}
          showHomeCitySearchLoaderMsite={showHomeCitySearchLoaderMsite}
          handleSearchCrossClickMsite={()=>{
            handleSearchCrossClickMsite(); 
            removePill(null,{locationType:searchReducer?.selectedItem?.locationType},false,true); 
            selectedCity({})
            handleSetFilter('search')
          }}
          />
        )}
      </div>
      {/* desktop view SRP page* */}
      <>
        {pageName === 'SRPpage' && (
          <>
            <AutoSearchSrpDesktop
            setDropDownData={setDropDownData}
              handleSetFilter={handleSetFilter}
              oldVal={oldVal}
              Styles={Styles}
              setShowSuggestion={setShowSuggestion}
              searchcityRef={searchcityRef}
              dropRef={dropRef}
              dropRefCity={dropRefCity}
              showSuggestion={showSuggestion}
              getPlaceholderText={getPlaceholderText}
              pillsArray={pillsArray}
              setPillsArray={setPillsArray}
              onChnageForDistrict={onChnageForDistrict}
              setToggleNRF={setToggleNRF}
              setInputValue={setInputValue}
              getAutoSuggestDataByInput={getAutoSuggestDataByInput}
              isMobileOnly={onLoadItems.isMobileOnly}
              linkOpen={linkOpen}
              setMenuopen={setMenuopen}
              setWrapperOpen={setWrapperOpen}
              toggleSearchableModal={toggleSearchableModal}
              toggleLoader={toggleLoader}
              pageName={pageName}
              selectedCity={selectedCity}
              dropDownData={dropDownData}
              addToPillsArray={addToPillsArray}
              getHighlightedText={getHighlightedText}
              viewPropertiesBtn={viewPropertiesBtn}
              exceedDistrict={exceedDistrict}
              menuopen={menuopen}
              removePill={removePill}
              inputValue={inputValue}
              popularDistrict={popularDistrict}
              getDistrictTitle={getDistrictTitle}
              toggleNRF={toggleNRF}
              whichSelected={whichSelected}
              popularCities={popularCities}
              viewProperties={viewProperties}
              setOutSidesaudi={setOutSidesaudi}
              setBackFade={setBackFade}
              backFade={backFade}
              setLocationDesktopPopUp={setLocationDesktopPopUp}
              getLocationTypeString={getLocationTypeString}
              setExceedDistrict={setExceedDistrict}
              setToggleSearchableModalForSRP={setToggleSearchableModalForSRP}
              selectedProperty={selectedProperty}
              routerParams={routerParams}
              propertyType={propertyType}
              setShowCitySuggetion={setShowCitySuggetion}
              showCitySuggetion={showCitySuggetion}
              selectedCityItem={searchReducer?.selectedItem}
              router={router}
              preSelectedCityPills = {preSelectedPills}
              setPreSelectedCityPills = {setPreSelectedPills}
              allCities={allCities}
            />
          </>
        )}

      </>

      {/* mobile home page search input* */}
      <div className={Styles.isNotDesktop}>
        {pageName !== 'SRPpage' && (
          <MobileHomeSearchPanel
          wrapperOpen={wrapperOpen}
          handleSetFilter={handleSetFilter}
          Styles={Styles}
          searchableModalVisible={searchableModalVisible}
          setShowSuggestion={setShowSuggestion}
          searchcityRef={searchcityRef}
          dropRef={dropRef}
          showSuggestion={showSuggestion}
          pillsArray={pillsArray}
          onChnageForDistrict={onChnageForDistrict}
          setToggleNRF={setToggleNRF}
          setInputValue={setInputValue}
          getAutoSuggestDataByInput={getAutoSuggestDataByInput}
          isMobileOnly={onLoadItems.isMobileOnly}
          linkOpen={linkOpen}
          setMenuopen={setMenuopen}
          setWrapperOpen={setWrapperOpen}
          toggleSearchableModal={toggleSearchableModal}
          toggleLoader={toggleLoader}
          pageName={pageName}
          selectedCity={selectedCity}
          dropDownData={dropDownData}
          addToPillsArray={addToPillsArray}
          getHighlightedText={getHighlightedText}
          viewPropertiesBtn={viewPropertiesBtn}
          exceedDistrict={exceedDistrict}
          menuopen={menuopen}
          setInputError={setInputError}
          inputError={inputError}
          checkEmptyInput={checkEmptyInput}
          removePill={removePill}
          inputValue={inputValue}
          popularDistrict={popularDistrict}
          getDistrictTitle={getDistrictTitle}
          toggleNRF={toggleNRF}
          whichSelected={whichSelected}
          popularCities={popularCities}
          viewProperties={viewProperties}
          setOutSidesaudi={setOutSidesaudi}
          setBackFade={setBackFade}
          backFade={backFade}
          tabs={tabs}
          handlePropertyIdChange={handlePropertyIdChange}
          changePropertyType={changePropertyType}
          setLocationDesktopPopUp={setLocationDesktopPopUp}
          getLocationTypeString={getLocationTypeString}
          selectedProperty={selectedProperty}
          errorPage={errorPage}
          onSearch={onSearch}
          searchLoader={searchLoader}
          setSearchLoader={setSearchLoader}
          propertyType={propertyType}
          isMobileSSR={isMobileSSR}
          showHomeCitySearchLoaderMsite={showHomeCitySearchLoaderMsite}
          />
        )}
      </div>
      
  
      {/* desktop home page search input* */}
        {pageName !== 'SRPpage' && (
      <div className={Styles.isNotMobile}>
          <HomeSearchBox
            handleSetFilter={handleSetFilter}
            dropDownData={dropDownData}
            setDropDownData={setDropDownData}
            toggleSearchableModal={toggleSearchableModal}
            dropRef={dropRef}
            changePropertyType={changePropertyType}
            handlePropertyIdChange={handlePropertyIdChange}
            inputValue={inputValue}
            showSuggestion={showSuggestion}
            setInputValue={setInputValue}
            setWrapperOpen={setWrapperOpen}
            setInputError={setInputError}
            isMobileOnly={false}
            setShowSuggestion={setShowSuggestion}
            inputError={inputError}
            checkEmptyInput={checkEmptyInput}
            selectedCity={selectedCity}
            propertyType={propertyType}
            popularCities={popularCities}
            citypropertity={citypropertity}
            propertySearchResult={propertySearchResult}
            searchProperties={searchProperties}
            selectedProperty={selectedProperty}
            getHighlightedText={getHighlightedText}
            searchCities={searchCities}
            tabs={tabs}
            searchcityRef={searchcityRef}
            setLocationDesktopPopUp={setLocationDesktopPopUp}
            setOutSidesaudi={setOutSidesaudi}
            onChnageForDistrict={onChnageForDistrict}
            toggleNRF={toggleNRF}
            setToggleNRF={setToggleNRF}
            getLocationTypeString={getLocationTypeString}
            pillsArray={pillsArray}
            pageName={pageName}
            errorPage={errorPage}
            onSearch={onSearch}
            searchLoader={searchLoader}
            isMobileSSR={isMobileSSR}
            allCities={allCities}
          />
      </div>
        )}

    </>
  )
}
