import { listingsPerPageSize } from "@/utils/constant"

const removeUnwantedParams = (params, removeFlag, isPageChange) => {
  const filteredParams = { ...params }
  delete filteredParams.slug
  delete filteredParams.pageSize
  delete filteredParams.includeProperties

  if (removeFlag) {
    delete filteredParams.page
  }
  if (!isPageChange) {
    delete filteredParams.min_size
    delete filteredParams.max_size
    delete filteredParams.min_price
    delete filteredParams.max_price
  }

  return filteredParams
}

const generatePriceRangeQuery = (priceRange) => {
  if (!priceRange) return null
  return `min_price=${priceRange[0].min}&max_price=${priceRange[0].max}`
}

const generatePropertySizeRangeQuery = (propertySizeRange) => {
  if (!propertySizeRange) return null
  const minSize = propertySizeRange[0].min
  const maxSize = propertySizeRange[0].max
  return `min_size=${minSize}&max_size=${maxSize}`
}

const generateArrayQuery = (property, array) => {
  if (!Array.isArray(array)) return null
  return `${property}=${array.join(',')}`
}

const generateSimpleQuery = (property, value) => {
  if (value === undefined || value === null) return null
  return `${property}=${value}`
}

export const generateQueryStringFromParams = (params, removeFlag = true, isPageChange = false) => {
  const filteredParams = removeUnwantedParams(params, removeFlag, isPageChange)
  const queriesArray = []

  for (const property in filteredParams) {
    let queryStr = null
    switch (property) {
      case 'priceRange':
        queryStr = generatePriceRangeQuery(filteredParams[property])
        break
      case 'propertySizeRange':
        queryStr = generatePropertySizeRangeQuery(filteredParams[property])
        break
      case 'propertyTypeData':
      case 'neighborhoodsData':
        queryStr = generateArrayQuery(property, filteredParams[property])
        break
      default:
        queryStr = generateSimpleQuery(property, filteredParams[property])
    }

    if (queryStr) {
      queriesArray.push(queryStr)
    }
  }

  return `?${queriesArray.join('&')}`
}

const handleSlug = (query, filterQueries) => {
  if (query.slug && !query.propertyFor && (query.slug === 'sale' || query.slug === 'rent')) {
    filterQueries.propertyFor = query.slug
    delete filterQueries.slug
  }
}

const handlePriceRange = (query, filterQueries) => {
  if (query.min_price || query.max_price) {
    filterQueries.priceRange = [{ min: query.min_price || 0, max: query.max_price || 'Any' }]
    delete filterQueries.min_price
    delete filterQueries.max_price
  }
}

const handleSizeRange = (query, filterQueries) => {
  if (query.min_size || query.max_size) {
    filterQueries.propertySizeRange = [{ min: query.min_size || 0, max: query.max_size || 35000 }]
    delete filterQueries.min_size
    delete filterQueries.max_size
  }
}

const handleStringToArray = (query, filterQueries, field) => {
  if (query[field] && typeof query[field] === 'string') {
    filterQueries[field] = query[field].split(',')
  }
}

const handleDistrictId = (query, filterQueries) => {
  if (query.districtId && query.districtId === 'null') {
    filterQueries.districtId = null
  }
}

const handleNearbySearch = (query, filterQueries) => {
  if (query.nearbySearch) {
    filterQueries.nearbySearch = query.nearbySearch
  }
}

const setDefaultParams = (filterQueries) => {
  filterQueries.page = filterQueries.page || 0
  filterQueries.pageSize = filterQueries.pageSize || 32
  filterQueries.includeProperties = filterQueries.includeProperties || true
}

export const getParamsFromQuery = (query) => {
  const filterQueries = { ...query }

  handleSlug(query, filterQueries)
  handlePriceRange(query, filterQueries)
  handleSizeRange(query, filterQueries)
  handleStringToArray(query, filterQueries, 'propertyTypeData')
  handleStringToArray(query, filterQueries, 'neighborhoodsData')
  handleDistrictId(query, filterQueries)
  handleNearbySearch(query, filterQueries)
  setDefaultParams(filterQueries)

  return filterQueries
}

export const getParamsFromRefUrl = (refUrl) => {
  const referenceUrlParams = refUrl?.split('?')

  const propertyForSlug = referenceUrlParams[0].split('/')[0]

  const propertyFor = encodeURIComponent(propertyForSlug)

  const referenceQueryString = referenceUrlParams[1]

  const refUrlParams = JSON.parse(
    '{"' + decodeURI(referenceQueryString.replace(/&/g, '","').replace(/=/g, '":"')) + '"}',
  )

  return {
    ...refUrlParams,
    propertyFor,
    page: 0,
    pageSize: listingsPerPageSize || 32,
    includeProperties: true,
  }
}
