import dynamic from 'next/dynamic'
import { debounceFunction, pushDataLayerEventHandler } from '@/utils/utils'
import { useAppContext } from 'context/AppContext'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import { getAutoSuggestData } from 'services/cityOptionsService'
import { getParamsFromQuery } from 'services/queryString'
import style from './style.module.scss'
import AutoSearch from '../HomeSearchNew/AutoSearch'
import { listingsPerPageSize } from '@/utils/constant'

const HomeSearchNew = dynamic(()=>import('../HomeSearchNew'),{ssr:false}) 

const Error404 = ({ heading, image, alt, errorCode, filterProperties }) => {
  const { t, lang } = useTranslation('translations')
  const router = useRouter()
  const { query } = router
  const routerParams = getParamsFromQuery({ ...query })
  const [cityOptions, setCityOptions] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [searchProperties, setSearchProperties] = useState(null)
  const [propertySearchResult, setPropertySearchResult] = useState(true)
  const [wrapperOpen, setWrapperOpen] = useState(false)
  const [searchCities, setSearchCities] = useState({})
  const [searchableModalVisible, isSearchableModalVisible] = useState(false)
  const [searchLoader, setSearchLoader] = useState(false)
  const { state, dispatch } = useAppContext()
  const { searchReducer, commonReducer } = state
  const [transactionType, setTransactionType] = useState('sale')
  const isProject = commonReducer.isProject

  useEffect(() => {
    (async () => {
      const res = await getAutoSuggestData(
        {
          'Content-Type': 'application/json;charset=UTF-8',
          locale: lang,
        },
        { propertyFor: 'sale', 
          countryFlag: true,
          cityOnly:true,  //It should not show country
          allCities:true // should show all cities including popular cities
        },
      )
      setCityOptions(res?.allCities || [])
    })()
  }, [])

  const handleCityChange = (item) => {

    const data = {
      cityId: item.cityId,
      countryId: '1',
      propertyFor: transactionType,
      type: 'residential',
      page: 0,
      pageSize: listingsPerPageSize || 32,
      includeProperties: true,
    }

    if (filterProperties !== undefined) {
      filterProperties(data)
    }
  }

  const cityDistrictSearch = (cityInfo, districtAndPropertyTypeObject, transactionValue, projectQuery) =>{
    const cityVal = cityInfo.cityId || cityInfo.city_id ? `&cityId=${cityInfo.cityId || cityInfo.city_id}` : ''
      const districtVal = districtAndPropertyTypeObject?.districtValue?.length!==0 ? `&districtId=` + String(districtAndPropertyTypeObject?.districtValue?.map((val)=>val?.districtId)) : ''
      const propertyTypeFilter = districtAndPropertyTypeObject?.propertyType.find((val)=>val?.isSelected) ? districtAndPropertyTypeObject?.propertyType.filter((val)=>val?.isSelected) :'';
      const selectedPropertyType = propertyTypeFilter ? `&propertyTypeData=` + String(propertyTypeFilter?.map((val)=>val?.value)) :''
      router.push(
        `/${transactionValue}/search?propertyFor=${transactionValue}&countryId=1${cityVal}${isProject?'':districtVal}&type=${searchReducer?.propertyType}${selectedPropertyType}${projectQuery}`,
        undefined,
        undefined,
      )
  }

  const citySearch = (cityInfo, projectQuery) =>{
    const cityVal = cityInfo?.cityId || cityInfo?.city_id ? `&cityId=${cityInfo?.cityId || cityInfo?.city_id}` : ''
      const districtVal = cityInfo?.districtId ? `&districtId=` + cityInfo?.districtId : ''
      if (transactionType) {
        router.push(
          `/${transactionType}/search?propertyFor=${transactionType}&countryId=1${cityVal}${isProject?'':districtVal}${projectQuery}
          `,
          undefined,
          undefined,
        )
      } else {
        router.push(`/sale/search?propertyFor=sale&countryId=1${cityVal}${isProject?'':districtVal}${projectQuery}`, undefined, undefined)
      }
  }

  const handleSearch = (cityInfo, transactionValue, districtAndPropertyTypeObject=false) => { //need to change
    const projectQuery = isProject ? `&project=true`:''
    setSearchLoader && setSearchLoader(true)
    // to get transactionValue from home @sourabh_c
    if(cityInfo && districtAndPropertyTypeObject){
      cityDistrictSearch(cityInfo, districtAndPropertyTypeObject, transactionValue, projectQuery)
    }
    else if(cityInfo){
      citySearch(cityInfo, projectQuery)
    }
  }
  const toggleSearchableModal = () => {
    isSearchableModalVisible(!searchableModalVisible)
  }
  useEffect(() => {
    if (searchableModalVisible) {
      document.body.style.overflow = 'hidden'
      document.body.style.position = 'fixed'
    } else {
      document.body.style.overflow = 'initial'
      document.body.style.position = ''
    }
  }, [searchableModalVisible])
  const handlePropertyIdChange = useCallback(
    debounceFunction(async function (searchVal) {
      try{
      const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/search/auto-suggest`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          locale: lang,
          Cookie: `i18next=${lang}`,
          authorization: window.localStorage.getItem('authToken') ?? '',
        },
        body: JSON.stringify({
          searchText: searchVal,
          propertyFor: 'sale',
        }),
      })
      if(!res.ok) {
        throw new Error(`Request failed with status ${res.status}`)
      }
      const response = await res.json()

      // added indexOf to check whether number is negative
      if (response?.data?.length && searchVal?.indexOf('-') == -1) {
        setSearchProperties(response.data)
        setPropertySearchResult(true)
      } else {
        setPropertySearchResult(false)
        setSearchCities({ allCities: [] })
      }
    } catch(error){
      console.error("Error Occurred:", error)
    }
    }, 400),
    [],
  )

  const isDomestic = routerParams?.countryId == 1 ? 'domestic' : 'international'
  const handlePropertyRedirection = (slug, propertyFor, searchedVal, title) => {
    const details = {
      service: propertyFor,
      search_keyword: searchedVal,
      search_result_clicked: title,
      searchType: 'property',
      property_category: routerParams?.countryId ? isDomestic : '',
    }

    pushDataLayerEventHandler(window, lang, 'SearchProperty', 'Error Page', details)
    router.push(`/property/${propertyFor}/${slug}`)
  }

  const noDataHandleClick = (searchedVal, type) => {
    const details = {
      service: 'sale',
      search_keyword: searchedVal,
      search_result_clicked: 'No result found',
      searchType: type,
      property_category: routerParams?.countryId ? isDomestic : '',
    }

    pushDataLayerEventHandler(window, lang, 'SearchProperty', 'Error Page', details)
  }
  const changeType = (type) => {
    setTransactionType(type)
    dispatch({ type: 'UPDATE_PROPERTYFOR', payload: type })
    if (type == 'sale') {
      setTransactionType('sale')
    } else {
      setTransactionType('rent')
    }
  }
  return (
    <div className={style.wrapper}>
      <div className={style.errorImage}>
        <img src={`${image}`} alt={t(`${alt}`)} className={style.image} />
      </div>
      <div className={style.content}>
        <h1 className={style.heading}>{t(`${heading}`)}</h1>
        {errorCode && <span className={style.subTitle}>{t(`${errorCode}`)}</span>}
      </div>
      <div className={style.seperatorError}></div>
      <div className={style.dropDownWrapper}>
        <div className={`${style.searchDropdown}`}>
          <div className={`${style.searchWidget}`}>
            <div className={style.searchWidgetText}>{t("Home.START_YOUR_NEW_SEARCH")}</div>
            <div className={`${style.searchWidgetDropdown}`}>
              <AutoSearch
                cities={cityOptions || []}
                inputValue={inputValue}
                setInputValue={setInputValue}
                wrapperOpen={wrapperOpen}
                setWrapperOpen={setWrapperOpen}
                searchCities={searchCities}
                setSearchCities={setSearchCities}
                handleCityClick={handleCityChange}
                searchProperties={searchProperties}
                handlePropertyIdChange={handlePropertyIdChange}
                propertySearchResult={propertySearchResult}
                handlePropertyRedirection={handlePropertyRedirection}
                noDataHandleClick={noDataHandleClick}
                toggleSearchableModal={toggleSearchableModal}
                searchableModalVisible={searchableModalVisible}
                pageName='ErrorPage'
                changeType={changeType}
                searchLoader={searchLoader}
                setSearchLoader={setSearchLoader}
                onSearch={(citySelected,districtAndPropertyTypeObject) => handleSearch(citySelected, transactionType,districtAndPropertyTypeObject)}
              />
            </div>
          </div>
          {searchableModalVisible && (
            <HomeSearchNew
              cities={cityOptions || []}
              inputValue={inputValue}
              setInputValue={setInputValue}
              wrapperOpen={wrapperOpen}
              setWrapperOpen={setWrapperOpen}
              searchCities={searchCities}
              setSearchCities={setSearchCities}
              handleCityClick={handleCityChange}
              searchProperties={searchProperties}
              handlePropertyIdChange={handlePropertyIdChange}
              propertySearchResult={propertySearchResult}
              handlePropertyRedirection={handlePropertyRedirection}
              noDataHandleClick={noDataHandleClick}
              pageName='ErrorPage'
              toggleSearchableModal={toggleSearchableModal}
              changeType={changeType}
              onSearch={(citySelected) => handleSearch(citySelected, transactionType)}
              searchableModalVisible={searchableModalVisible}
              searchLoader={searchLoader}
              setSearchLoader={setSearchLoader}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Error404
