export const homeSearchReducer = (state, action) => {
    switch (action.type) {
        case 'SET_ALL_DISTRICTS':
            return {
                ...state,
                allDistricts: action.payload
            }
        case 'SELECTED_DISTRICTS':
            return{
                ...state,
                selectedDistrictsMsite:[...state.selectedDistrictsMsite, action.payload]
            }
        case 'REMOVED_DISTRICT':
                const districtToBeRemoved = action.payload
                const findIndex = state.selectedDistrictsMsite?.findIndex(obj => obj.districtId === districtToBeRemoved.districtId)
                findIndex > -1 && state.selectedDistrictsMsite.splice(findIndex, 1)
                return {
                    ...state,
                    selectedDistrictsMsite: [...state.selectedDistrictsMsite]
                }
        case 'REMOVE_ALL_SELECTED_DISTRICT':
            return {
                ...state,
                selectedDistrictsMsite: action.payload
            }
        case 'ALL_PROPERTY_TYPES' :
            return{
                ...state,
                allPropertyTypes:action.payload
            }
        case 'SET_SHOW_ALL_CLICKED':
            return{
                ...state,
                showAllClicked: action.payload
            }

       //Till here
            case 'RECENT_SEARCH_VALUE':
            return {
                ...state,
                recentSearchValue: action.payload
            }
        case 'SRP_PILL_DATA':
            return {
                ...state,
                srpPillData: action.payload
            }
        case 'UPDATE_PROPERTYFOR':
            
            return {
                ...state,
                propertyFor: action.payload
            }
        case 'UPDATE_PROPERTYTYPE':
            return {
                ...state,
                propertyType: action.payload
            }
        case 'IS_SUGGESTION_OPEN':
            return {
                ...state,
                isSuggestionOpen: action.payload
            }
        case 'UPDATE_PROPERTYFOR_MSITE':
            return {
                ...state,
                propertyForMsite: action.payload
            }
        case 'UPDATE_PROPERTYTYPE_MSITE':
            return {
                ...state,
                propertyTypeMsite: action.payload
            }
        case 'IS_SRP_MSITE_OPEN':
            return {
                ...state,
                IsSrpMsiteOpen: action.payload
            }
        case 'RECENT_SEARCH_TRIGGER':
            return {
                ...state,
                recentSearchTrigger: action.payload
            }
        case 'FILTER_TRIGGER':
            return {
                ...state,
                filterTrigger: action.payload
            }
        case 'CURRENT_LOCATION_TRIGGER':
            return {
                ...state,
                currentLocationTrigger: action.payload
            }
        case 'SRP_SET_SELECTED_CITY':
            return {
                ...state,
                srpSelectedCity: action.payload
            }
        case 'SELECTED_ITEM': // general update aciton for country / city / district [router params values] ;
            return {
                ...state,
                // selectedItem : action.payload
                selectedItem: { ...state.selectedItem, ...action.payload }
            }
        case 'UPDATE_DISTRICT_SRP':
            return {
                ...state,
                districtSrpData: action.payload
            }
        case 'UPDATE_ROUTER_PARAMS':
            return {
                ...state,
                routerParams : action.payload
            }
        case 'IS_SEARCH_INPUT_VISIBLE':
            return {
                ...state,
                isSearchInputVisible : action.payload
            }
        case 'IS_HOME_MSITE_OPEN':
            return {
                ...state,
                isHomeMsiteOpen : action.payload
            }
        case 'SET_SCROLL_DEPTH':
            return {
                ...state,
                scrollDepth : action.payload
            }
        case 'IS_HOME_STICKY_INPUT_CLICKED':
            return {
                ...state,
                isHomeStickyInputClicked : action.payload
            }
        case 'ENABLE_LOCATION':
            return {
                ...state,
                enableLocation: action.payload
            }
        case 'SET_SELECTED_PLACE_FOR_TITLE':
            return {
                ...state,
                selectedPlaceForTitle: action.payload
            }
        case 'MASTERPLAN_BANNER_LOADING':
            return {
                ...state,
                masterplanBannerLoading: action.payload
            }
        case 'IS_PIN_CLICKED':
            return {
                ...state,
                isPinClicked: action.payload
            }
      
        case "SET_AB_TESTING_SRP": {
            return {
              ...state,
              abTestVariant: action.payload
            }
          }
        default:
            return state;
    }
}