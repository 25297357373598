import dynamic from 'next/dynamic'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { pushDataLayerEventHandler, refPath } from '@/utils/utils'
import { getParamsFromQuery } from 'services/queryString'
import styles from './SubFooter.module.scss'
import { useEffect, useState } from 'react'
const IconoGraphy = dynamic(() => import('../../IconoGraphy'))
const SocialLinks = dynamic(() => import('../SocialLinks'))

let handleDataLayerFunction = null

const SubFooter = (props) => {
  const { metaData, propertyDetails, page, pageType, udpCompoundId } = props
  const router = useRouter()
  const { query, pathname } = router
  const routerParams = getParamsFromQuery({ ...query })
  const { t, lang } = useTranslation('translations')
  const [isFirstLoadDone, setIsFirstLoadDone] = useState(false)

  useEffect(() => {
    setIsFirstLoadDone(true)
  }, [])

  const privacyLinks = [
    { id: 1, link: t('common.BLOGS'), path: lang == 'ar' ? 'https://blog.wasalt.sa/' :'https://blog.wasalt.sa/en/', name: 'Blogs' },
    { id: 2, link: t('common.Corporate'), path: '/corporate', name: 'corporate_clicked' },
    { id: 3, link: t('common.PRIVACY_POLICY'), path: '/s/privacy-policy', name: 'Privacy policy' },
    { id: 4, link: t('common.TERMS_OF_USE'), path: '/s/terms-of-use', name: 'Terms of Use' },
    { id: 5, link: t('common.TERMS_OF_ADVERTISING'), path: '/s/terms-of-advertising', name: 'Terms of Advertising' },
  ]

  const privacyLinksToRender = privacyLinks.map((link) => (
    <Link key={link.id} href={link.path} prefetch={false}>
      <div id = {`privacyLink${link.id}`} className={styles.privacyLink} onClick={() => privacyLinksClickHandler(link.name)}>
        {link.link}
      </div>
    </Link>
  ))

  const privacyLinksClickHandler = (linkName) => {
      pushDataLayerEventHandler(window, lang, linkName, pageType)
  }
  const handleDataLayer = async () => {
    if (handleDataLayerFunction === null) {
      const { handleDataLayerMethodForSubFooter } = await import(
        /* webpackChunkName: "handleDataLayer" */ '../../SEOHeader/Methods/onClickMethods'
      )
      handleDataLayerFunction = handleDataLayerMethodForSubFooter
    }
    handleDataLayerFunction(routerParams, lang, pageType)
  }

  return (
    <div className={styles.lowerFooter}>
      <div className={styles.socialIcons}>
        <SocialLinks lang={lang} pageType={pageType}/>
      </div>
      <div className={styles.legal}>
        {privacyLinksToRender}
        <div className={styles.language}>
          <IconoGraphy iconClass={'icon-globe'} iconColor={'color_gray'} fontSize={'f_16'}></IconoGraphy>
          <a
            id = 'langChangeFooterBtn'
            href={refPath({
              page,
              pageType,
              propertyDetails,
              metaData,
              query,
              udpCompoundId,
              isFirstLoadDone,
              lang,
              router,
            })}
            onClick={handleDataLayer}
            className={styles.language_link}
            dir={lang === 'ar' ? 'ltr' : 'rtl'}
          >
            {t('common.LANGUAGE')}
          </a>
        </div>
      </div>
    </div>
  )
}

export default SubFooter
