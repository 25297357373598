/* eslint-disable max-lines */
import dayjs from 'dayjs';
import * as isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import mixpanel from 'mixpanel-browser';
import { isAndroid, isDesktop, isIOS, isMobileOnly } from 'react-device-detect';
import { toast } from 'react-toastify';
import { saveUnsaveAPI } from 'services/saveunsaveAPI';
import UCookies from 'universal-cookie';
import { getCompoundDetailsClint } from 'services/compoundDetailsService';
import { getGalleryDetailsClint, getGalleryKeyDataPDP, getPropertyDetailsClint } from 'services/propertyDetailsService';
import uniqueIdGenerator from '@/utils/generateUniqueId'
import { listingsPerPageSize } from './constant';

let globalLanguage = 'en'
export const ContactNumber = 8001233227
export const AuctionContactNumber = 8001233227
export const countryCode = +966
export const REGA_IMG_EN = `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/rega-property-default-img-en.png`
export const REGA_IMG_AR = `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/rega-property-default-img-ar.png`
export const REGA_IMG_MAP_EN = `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/rega-property-default-card-img-en.png`
export const REGA_IMG_MAP_AR = `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/rega-property-default-card-img-ar.png`
export const REGA_IMG_EN_NEW = `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/rega-property-default-no-img-en.png`
export const REGA_IMG_AR_NEW = `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/rega-property-default-no-img-ar.png`
export const REGA_IMG_SUCCESS_MSITE = `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/success_no_image_msite.png`
export const REGA_IMG_SUCCESS_DESKTOP = `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/success_no_image_desktop.png`

const attributeDataKeys = {
  noOfBedrooms: 'bedrooms',
  noOfBathrooms: 'bathrooms',
  builtUpArea: 'builtUpArea',
  carpetArea: 'carpetArea',
  noOfLivingrooms: 'livingrooms',
  noOfGuestrooms: 'guestrooms',
  furnishingType: 'furnishType',
  completionYear: 'completionYear',
  noofUnitsAvailable: 'noofUnitsAvailable',
}

// Rediredted from event frunction paramter

export const getRedirectedFromData = (id) => {
  const r_data = JSON.parse(localStorage.getItem('r_from')) || []
  const data = r_data.filter((f) => f.pId === id)
  return data?.length > 0 ? data[0]?.pType : null
}

export const updateRedirectedFrom = (pId, pType) => {
  const r_data = JSON.parse(localStorage.getItem('r_from')) || []
  const ind = r_data.findIndex((f) => f.pId === pId)
  if (ind > -1) {
    return false
  }
  r_data.push({ pId, pType })
  localStorage.setItem('r_from', JSON.stringify(r_data))
}

export const removeRedirectedFromData = (id) => {
  const r_data = JSON.parse(localStorage.getItem('r_from')) || []
  const data = r_data.filter((f) => f.pId !== id)
  localStorage.setItem('r_from', JSON.stringify(data))
}
export const setLanguage = (lang) => (globalLanguage = lang)

export const getLanguage = () => globalLanguage

export const getDaysAgo = (lastUpdatedTime) => {
  const currentDate = dayjs();
  const updatedDate = dayjs(lastUpdatedTime);

  return currentDate.diff(updatedDate, 'days')
}

export function validateEmail(email) {
  const re = /^[\w.-]+@\w+\.[a-zA-Z]{2,}$/
  return re.test(String(email).toLowerCase())
}

export function validatePhone(phone = '') {
  return phone.startsWith('05') && phone.length === 10
}

export const getMapBound = (boundryData) => {
  if (!window.google) return
  const bounds = new window.google.maps.LatLngBounds()
  for (const boundry of boundryData) {
    bounds.extend(new window.google.maps.LatLng(boundry.latitude, boundry.longitude))
  }
  return bounds
}

export const priceConverter = (n, lang) => {
  let symbol

  if (n > 999 && n < 1000000) {
    if (lang === 'ar') {
      symbol = ' ألف'
    } else {
      symbol = 'K'
    }
    const kValue = (n / 1000).toFixed(2) + symbol
    return kValue.replace('.00', '') // convert to K for number from > 1000 < 1 million
  } else if (n >= 1000000) {
    if (lang == 'ar') {
      symbol = ' مليون'
    } else {
      symbol = 'M'
    }
    const mValue = (n / 1000000).toFixed(2) + symbol
    return mValue.replace('.00', '') // convert to M for number from > 1 million
  } else if (n < 900) {
    return n // if value < 1000, nothing to do
  }
}

export const priceConverterFeaturePlus = (n) => {
  let symbol

  if (n > 999 && n < 1000000) {
    symbol = 'K'
    const kValue = (n / 1000).toFixed(1) + symbol
    return kValue.replace('.0', '') // convert to K for number from > 1000 < 1 million
  } else if (n >= 1000000) {
    symbol = 'M'
    const mValue = (n / 1000000).toFixed(1) + symbol
    return mValue.replace('.0', '') // convert to M for number from > 1 million
  } else if (n < 900) {
    return n // if value < 1000, nothing to do
  }
}

//Till billion convertor
export const priceValuationConverter = (n) => {
  let symbol
  if (n >= 1000 && n < 1000000) {
    symbol = 'K'
    const kValue = (n / 1000).toFixed(1) + symbol
    return kValue.replace('.0', '') // convert to K for number from > 1000 < 1 million
  } else if (n >= 1000000 && n <1000000000) {
    symbol = 'M'
    const mValue = (n / 1000000).toFixed(1) + symbol
    return mValue.replace('.0', '') // convert to M for number from > 1 million
  }
   else if (n >= 1000000000) {
      symbol = 'B'
      const mValue = (n / 1000000000).toFixed(1) + symbol
      return mValue.replace('.0', '') // convert to B for number from > 1 billion
  } else if (n < 1000) {
    return n // if value < 1000, nothing to do
  }
}
export const calculateTextWidth = (text, fontProp) => {
  const tag = document.createElement('div')
  tag.style.position = 'absolute'
  tag.style.left = '-999em'
  tag.style.whiteSpace = 'nowrap'
  tag.style.font = fontProp
  tag.innerHTML = text

  document.body.appendChild(tag)
  const result = tag.clientWidth
  document.body.removeChild(tag)
  return result
}

export const getUrlParams = (url) =>
  `${url}?`
    .split('?')[1]
    .split('&')
    .reduce(
      (params, pair) => {
        const [key, val] = `${pair}=`.split('=').map(decodeURIComponent);
        return (key ? { ...params, [key]: val } : params);
      },
      {},
    )
export const getUrlParamsObject = (url) => {
  const newObject = {}
  const a = `${url}?`
  a.split('?')[1]
    ?.split('&')
    ?.map((obj) => {
      if (obj) {
        const splittedarray = obj.split('=')
        newObject[`${splittedarray[0]}`] = splittedarray[1]
      }
      return true
    })
  return newObject
}

const toastPropertiesPathNameCheck = typeof window === 'object' && window.location.pathname.includes('/en') ? 'bottom-right' : 'bottom-left'

const toastProperties = {
  hideProgressBar: true,
  position: isMobileOnly
    ? 'bottom-left'
    : toastPropertiesPathNameCheck,
  autoClose: 3000,
  className: "toast-message",
}

/**
 ** Show toast message with red background
 * @param {*} message : string
 */
export const showErrorToast = (message) => {
  toast.error(message, {
    ...toastProperties,
    theme: 'colored',
  })
}

export const showSuccessToast = (message) => {
  toast.success(message, {
    ...toastProperties,
    theme: 'colored',
  })
}

export const showCustomisedSuccessToast = (message, className, bodyClassName) => {
  toast.success(message, {
    ...toastProperties,
    icon: false,
    position: 'top-right',
    theme: 'colored',
    className,
    bodyClassName,
  })
}

export const onShareClick = (t, text = '', source = '') => {
  const pageurl = decodeURI(window.location.href.split('?')[0])
  const fullText = `\u202A${text}\u202C\n${pageurl}`;
  let shareObject = ''
  if(source == 'matchmaker') {
    shareObject = {
      text : fullText
    }
  } else {
    shareObject = {
      url: pageurl,
      text
    }
  }
  if (navigator.share) {
    navigator.share(shareObject);
  } else {
    console.warn('Sorry! Your browser does not support Web Share API')
    if(navigator.clipboard){
        // state can be one of "granted", "prompted", "denied"
    try{
    navigator.clipboard.writeText(`${fullText}`)
    toast.success(t('common.COPY_SCUCCESS'), {
      ...toastProperties,
      theme: 'colored',
    })
  } catch (error) {
    console.warn("Sorry! Your browser does not support clipboard API.")
  }
  } else {
    console.warn("Sorry! Your browser does not support Sharing options")
  }
  }
}
/**
 * remove cookies
 */
export const removeCookies = () => {
  const domain = location.host
  const cookieOptions = {
    path: '/',
  }
  if (domain.split('.').length > 1) {
    const domainParts = domain.split('.')
    domainParts.shift()
    cookieOptions.domain = '.' + domainParts.join('.')
  }
  const cookie = new UCookies()
  cookie.remove('authToken', cookieOptions)
  cookie.remove('refreshToken', cookieOptions)
  cookie.remove('userId', cookieOptions)
  cookie.remove('authStoredDate')
  if (domain.match(/[a-z]/i) == null) {
    delete cookieOptions.domain
    cookie.remove('authToken', cookieOptions)
    cookie.remove('refreshToken', cookieOptions)
    cookie.remove('userId', cookieOptions)
    cookie.remove('authStoredDate')
  } else if (domain.split('.').length > 1) {
    //commented by @Fawwad on 27-01-2022 logout issue on live site
    //&& (cookie.get('authToken') == null || cookie.get('authToken') != token)

    cookieOptions.domain = '.' + location.host
    cookie.remove('authToken', cookieOptions)
    cookie.remove('refreshToken', cookieOptions)
    cookie.remove('userId', cookieOptions)
    cookie.remove('authStoredDate')
  }
}

export const numberWithCommas = (x) => x?.toLocaleString("en-US")

export const smoothScrollTo = (target) => {
  const isClosedHeader = window.sessionStorage.getItem('isOpenHeaderClosed')
  const { top } = target.current.getBoundingClientRect()
  if (target.current.className.includes('DraftCase') && isDesktop) {
    window.scrollTo({
      top: top + window.scrollY - (isClosedHeader || isClosedHeader == null ? 95 : 120),
      behavior: 'smooth',
    })
  } else if (target.current.className.includes('DraftCase') && isMobileOnly) {
    window.scrollTo({
      top: top + window.scrollY - (isClosedHeader ? 65 : 120),
      behavior: 'smooth',
    })
  } else if (target.current.className.includes('DraftCase')) {
    window.scrollTo({
      top: top + window.scrollY - (isClosedHeader ? 45 : 120),
      behavior: 'smooth',
    })
  } else
    window.scrollTo({
      top: top + window.scrollY - (isClosedHeader ? 140 : 120),
      behavior: 'smooth',
    })
}

export const numberWithoutCommas = (str) => {
  const val = str ? parseInt(str.replace(/\,/g, ''), 10) : str
  return isNaN(val) ? '' : val
}
const marketingChannelGrouping = () => {
  const url = window?.location?.href
  const searchParams = new URLSearchParams(new URL(url).search);
  const utmSource = searchParams.get('utm_source')? searchParams.get('utm_source').toLowerCase() : null;
  const utmMedium = searchParams.get('utm_medium')? searchParams.get('utm_medium').toLowerCase() : null;
  const sourcesToCheck = ['twitter', 'tiktok', 'snapchat', 'instagram', 'facebook', 'instagramstories', 'youtube', 'google-youtube', 'linkedin'];
  const gclid = searchParams.get('gclid') ? searchParams.get('gclid') : null;

  if (sourcesToCheck?.includes(utmSource)) {
    sessionStorage.setItem('marketingChnnlGrp','Paid Social')
    return 'Paid Social'
  } 
  else if (utmSource?.includes('google') || gclid){
    sessionStorage.setItem('marketingChnnlGrp','Paid Search')
    return 'Paid Search'
  } 
  else if (!sourcesToCheck?.includes(utmSource) && (document.referrer?.includes('twitter') || document.referrer?.includes('tiktok') || document.referrer?.includes('snapchat') || document.referrer?.includes('instagram') || document.referrer?.includes('facebook') || document.referrer?.includes('google-youtube') || document.referrer?.includes('linkedin') )) {
    sessionStorage.setItem('marketingChnnlGrp','Organic Social')
    return 'Organic Social'
  }
  else if(utmMedium?.includes('display') || utmMedium?.includes('performance')){
    sessionStorage.setItem('marketingChnnlGrp','display')
    return 'display'
  }
  else if(document.referrer.includes('google') || document.referrer.includes('bing') || document.referrer.includes('yahoo')){
    sessionStorage.setItem('marketingChnnlGrp','Organic Search')
    return 'Organic Search'
  }

if (utmSource?.includes('moengage')){
  sessionStorage.setItem('marketingChnnlGrp','Push Notification')
  return 'Push Notification'
} 
if(utmSource == null){
  sessionStorage.setItem('marketingChnnlGrp','Direct')
  return 'Direct'
}
  
    sessionStorage.setItem('marketingChnnlGrp','Referral')
    return 'Referral'
  
  
}

const getCustomSource = () => {
  const url = window?.location?.href
  const searchParams = new URLSearchParams(new URL(url).search)
  const utmSource = searchParams.get('utm_source') ? searchParams.get('utm_source').toLowerCase() : null
  const gclid = searchParams.get('gclid') ? searchParams.get('gclid') : null;

  if (utmSource && (document.referrer.includes('twitter') || document.referrer.includes('tiktok') || document.referrer.includes('snapchat') || document.referrer.includes('instagram')  || document.referrer.includes('facebook') || document.referrer.includes('instagramstories') || document.referrer.includes('youtube') || document.referrer.includes('linkedin'))) {
    sessionStorage.setItem('cstmSrc', utmSource)
    return utmSource //paid social
  }
  else if (!utmSource && (document.referrer.includes('twitter') || document.referrer.includes('tiktok') || document.referrer.includes('snapchat') || document.referrer.includes('instagram') || document.referrer.includes('facebook') || document.referrer.includes('youtube') || document.referrer.includes('linkedin') )) {
    sessionStorage.setItem('cstmSrc', document.referrer)
    return document.referrer //organic social
  }
  else if (document.referrer.includes('google') || gclid){
    sessionStorage.setItem('cstmSrc', 'google')
    return 'google'
  } else if (!document.referrer && !utmSource) {
    sessionStorage.setItem('cstmSrc', 'direct')
    return 'direct'
  }
  else if(document.referrer.includes('google') && utmSource.includes('performance')){
    sessionStorage.setItem('cstmSrc', 'google-performancemax')
    return 'google-performancemax'
  }
  else if(document.referrer.includes('google') && utmSource.includes('display')){
    sessionStorage.setItem('cstmSrc','google-display')
    return 'google-display'
  }
  else if( document.referrer && !( document.referrer.includes('twitter') || document.referrer.includes('tiktok') || document.referrer.includes('snapchat') || document.referrer.includes('instagram') || document.referrer.includes('youtube') || document.referrer.includes('facebook') || document.referrer.includes('linkedin') )){
    sessionStorage.setItem('cstmSrc',document.referrer)
    return document.referrer
  }
  sessionStorage.setItem('cstmSrc', searchParams.get('utm_source'))
  return searchParams.get('utm_source')
}

const handleSessionStorage = () => {
  sessionStorage.setItem('cstmMed','NA')
}

const returnSessionStorage = () => {
  handleSessionStorage()
  return 'NA' //direct
}

const customMedium = () =>{
  const url = window?.location?.href
  const searchParams = new URLSearchParams(new URL(url).search);
  const utmSource = searchParams.get('utm_source')? searchParams.get('utm_source').toLowerCase() : null;

  if (document.referrer.includes('google') && (utmSource == 'google-display' || utmSource == 'google-performancemax')){
    sessionStorage.setItem('cstmMed','display')
    return 'display'
  }
  else if (!utmSource && (document.referrer.includes('twitter') || document.referrer.includes('tiktok') || document.referrer.includes('snapchat') || document.referrer.includes('instagram') || document.referrer.includes('facebook') || document.referrer.includes('youtube') || document.referrer.includes('linkedin') )) {
    returnSessionStorage()
  } else if (!document.referrer && !utmSource) {
    returnSessionStorage()
  }
  else if( document.referrer && !( document.referrer.includes('twitter') || document.referrer.includes('tiktok') || document.referrer.includes('snapchat') || document.referrer.includes('instagram') || document.referrer.includes('facebook') || document.referrer.includes('youtube') || document.referrer.includes('linkedin') )){
    returnSessionStorage()
  }
    sessionStorage.setItem('cstmMed',searchParams.get('utm_medium'))
    return searchParams.get('utm_medium')
}

const sessionStorageFn = () => {
  sessionStorage.setItem('cstmCmpgn','NA')
  return 'NA'
}

const getCustomCampaign =() =>{
  const url = window?.location?.href
  const searchParams = new URLSearchParams(new URL(url).search)
  const utmSource = searchParams.get('utm_source') ? searchParams.get('utm_source').toLowerCase() : null

  if( document.referrer && !( document.referrer.includes('twitter') || document.referrer.includes('tiktok') || document.referrer.includes('snapchat') || document.referrer.includes('instagram') || document.referrer.includes('facebook') || document.referrer.includes('youtube') || document.referrer.includes('linkedin') )){
    sessionStorageFn()
  }
  else if (!utmSource && (document.referrer.includes('twitter') || document.referrer.includes('tiktok') || document.referrer.includes('snapchat') || document.referrer.includes('instagram') || document.referrer.includes('facebook') || document.referrer.includes('youtube') || document.referrer.includes('linkedin') )) {
    sessionStorageFn()
  }
  else if(!document.referrer && !utmSource) {
    sessionStorageFn()
  }
    sessionStorage.setItem('cstmCmpgn', searchParams.get('utm_campaign'))
    return searchParams.get('utm_campaign')
}

const cstmCntntStorage = () => {
    sessionStorage.setItem('cstmCntnt', 'NA')
    return 'NA'
}

const getCustomContent =() => {
  const url = window?.location?.href
  const searchParams = new URLSearchParams(new URL(url).search);
  const utmSource = searchParams.get('utm_source')? searchParams.get('utm_source').toLowerCase() : null;
  
  if( document.referrer && !( document.referrer.includes('twitter') || document.referrer.includes('tiktok') || document.referrer.includes('snapchat') || document.referrer.includes('instagram') || document.referrer.includes('facebook') || document.referrer.includes('youtube') || document.referrer.includes('linkedin') )){
    cstmCntntStorage()
  }
  else if (!utmSource && (document.referrer.includes('twitter') || document.referrer.includes('tiktok') || document.referrer.includes('snapchat') || document.referrer.includes('instagram') || document.referrer.includes('facebook') || document.referrer.includes('youtube') || document.referrer.includes('linkedin') )) {
    cstmCntntStorage()
  }

  if (
    document.referrer &&
    !(
      document.referrer.includes('twitter') ||
      document.referrer.includes('snapchat') ||
      document.referrer.includes('instagram') ||
      document.referrer.includes('facebook') ||
      document.referrer.includes('youtube') ||
      document.referrer.includes('linkedin')
    )
  ) {
    cstmCntntStorage()
  } else if (
    !utmSource &&
    (document.referrer.includes('twitter') ||
      document.referrer.includes('snapchat') ||
      document.referrer.includes('instagram') ||
      document.referrer.includes('facebook') ||
      document.referrer.includes('youtube') ||
      document.referrer.includes('linkedin'))
  ) {
    cstmCntntStorage()
  } else if (!document.referrer && !utmSource) {
    cstmCntntStorage()
  }
  sessionStorage.setItem('cstmCntnt', searchParams.get('utm_content'))
  return searchParams.get('utm_content')
}

const language = (lang) =>{
  if(lang === 'en'){
    return 'English'
  }
  return 'Arabic'
}

const isAndroidCheck = () =>{
  if(isAndroid){
    return 'android'
  }
  return 'Web'
}

const platform = () =>{
  if(isIOS){
    return 'iOS'
  }
  return isAndroidCheck()
}

const deleteCard = (data) =>{
  if(platform() === 'Web'){
    data && delete data['AB_listingCard']
  }
}

export const pushDataLayerEventHandler = (window, lang, eventName, pageType, data = {}) => {
  window.dataLayer = window.dataLayer || []
  const login_status = (window.localStorage.getItem('authToken') || eventName?.user_id) ? true : false
  const previous_screen = decodeURI(getPreviousPath())
  let userData = {}
  if(login_status){
    userData = {
      user_id : window?.localStorage.getItem('userId') || eventName?.user_id,
      Ownership: window.localStorage.getItem('userRole'),
      // Ownership : 'role' in localStorage ? JSON.parse(localStorage.getItem('role')) : '',
    }
  }

  if (eventName === 'SRP_Loaded'){
    deleteCard(data)
  }

  const details = {
    language:language(lang),
    page_type: pageType,
    login_status,
    previous_screen,
    ...data,
    ...userData,
    platform:platform(),
    user_mobile: window?.localStorage.getItem('encryptedPhoneNumber') || '',
    marketing_channel_grouping: sessionStorage.getItem('marketingChnnlGrp')? sessionStorage.getItem('marketingChnnlGrp'): marketingChannelGrouping() ,
    custom_source:sessionStorage.getItem('cstmSrc')? sessionStorage.getItem('cstmSrc'):getCustomSource(),
    custom_medium:sessionStorage.getItem('cstmMed')? sessionStorage.getItem('cstmMed'):customMedium(),
    custom_campaign:sessionStorage.getItem('cstmCmpgn')? sessionStorage.getItem('cstmCmpgn'):getCustomCampaign(),
    custom_content: sessionStorage.getItem('cstmCntnt')? sessionStorage.getItem('cstmCntnt'):getCustomContent(),
  }
  window.dataLayer.push({
    event: eventName,
    details,
  })
  if (process.env.NEXT_PUBLIC_IS_PRODUCTION == 'true' || process.env.NEXT_PUBLIC_IS_PREPROD == 'true')
    mixpanel.track(eventName, details)
}

export const downloadFile = async (url, fileName) => {
  const response = await fetch(url)
  const data = await response.blob()
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(data)
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
}

export const removeDuplicatesFromArrayOfObjects = (array = [], key = 'id') =>
  Array.from(new Set(array.map((a) => a[key]))).map((id) => array.find((a) => a[key] === id))

export const findDuplicatesArray = (array) => {
  const count = {}
  const result = []
  array.forEach((item) => {
    if (count[item]) {
      count[item] += 1
      return
    }
    count[item] = 1
  })
  for (const prop in count) {
    if (count[prop] >= 2 && prop != '') {
      result.push(prop)
    }
  }
  return result
}
export const handleBackGroundScroll = (type) => {
  if (type == 'remove') {
    document.body.style.overflow = 'hidden'
    document.body.style.width = '100%'
  } else {
    document.body.style.overflow = 'auto'
    document.body.style.position = null
    document.body.style.width = null
    document.body.style.scrollPo = null
  }
}

export const debounceFunction = (func, delay) => {
  let timer
  return function () {
    const self = this
    const args = arguments
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(self, args)
    }, delay)
    return timer
  }
}

export const getDateMonthConverted = (t, date, addComma) => {
  const currDate = new Date(date)
  const formattedDate = currDate.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  })
  const month = currDate.toLocaleString('en-GB', { month: 'short' })
  const monthsUpper = `months.${month.toUpperCase()}`
  return formattedDate.replace(month, `${t(monthsUpper)}${addComma ? t('Details.COMMA') : ''}`)
}
export const getDateConverted = (t, date) => {
  const month = dayjs(date).format('MMM');
  return date.replace(month, t(`months.${month.toUpperCase()}`))
}

export const getDateConvertedWithTranslationWithTime = (t, dateToBeConverted, isTimeRequire = true) => {
  //D - MMMM - YYYY | h:mm a
  if (isTimeRequire) {
    return `${getDateConverted(t, dayjs(dateToBeConverted).format('DD MMM YYYY, '))}  ${dayjs(
      dateToBeConverted,
    ).format('hh:mm')} 
    `
  }
  return `${getDateConverted(t, dayjs(dateToBeConverted).format('DD MMM YYYY'))}`
}

const countryCheck = (countryId) =>{
  if(countryId == 1){
    return 'domestic'
  }
  return 'international'
}

const regionCheck = (propertyDetails) =>{
  if(propertyDetails?.propertyRegionId != 57){
    return 'domestic'
  }
  return 'international'
}

const managedPropertyCheck = (propertyDetails) =>{
  if(propertyDetails?.managedById === 54){
    return 'wasalt'
  }
  return 'self'
}

const keyIdCheck = (propertyDetails, key) =>{
  if(propertyDetails?.[key]?.managedById === 54){
    return 'wasalt'
  }
  return 'self'
}

const manageIdCheck = (propertyDetails, key) =>{
  if(propertyDetails?.[key]?.managedById){
    return keyIdCheck(propertyDetails, key)
  }
  return ''
}

const isFeatured = (propertyDetails) => 
  propertyDetails?.packageData?.subsPkg === 'featured-listing';


const isPremiumCheck = (propertyDetails) =>
  propertyDetails?.packageData?.subsPkg === 'premium-listing' ||
  propertyDetails?.packageData?.subsPkg === 'premium-project';

const getPropertyType = (key, propertyDetails) =>{
  if(key == 'propertyInfo'){
    return (propertyDetails?.[key]?.propertySubType || propertyDetails?.propertyInfo?.propertySubType)
  }
  return 'Compound'
}

const getPropertyCategory = (countryId, propertyDetails) =>{
  if(countryId){
    return countryCheck(countryId)
  }
  return regionCheck(propertyDetails)
}

const getManagedBy = (propertyDetails, key) =>{
  if(propertyDetails?.managedById){
    return managedPropertyCheck(propertyDetails)
  }
  return manageIdCheck(propertyDetails, key)
}

const getService = (propertyDetails, key) =>(
   propertyDetails?.[key]?.propertyFor || propertyDetails?.propertyInfo?.propertyFor
)

const getTitle = (propertyDetails, key) =>(
   propertyDetails?.[key]?.title || propertyDetails?.propertyInfo?.title
)

const getAddress = (propertyDetails, key) =>(
  propertyDetails?.[key]?.address || propertyDetails?.propertyInfo?.title
)

const getCity = (propertyDetails, key) =>(
  propertyDetails?.[key]?.city || propertyDetails?.propertyInfo?.city || ''
)

const getCountry = (propertyDetails, key) =>(
  propertyDetails?.[key]?.country || propertyDetails?.propertyInfo?.country || 'Saudi Arabia'
)

const getCityId = (propertyDetails, key) =>(
  propertyDetails?.[key]?.cityId || propertyDetails?.propertyInfo?.cityId || ''
)

const getConversionUnit = (propertyDetails, key) =>(
  propertyDetails?.[key]?.conversionUnit || propertyDetails?.propertyInfo?.conversionUnit || ''
)

const getCurrencyType = (propertyDetails, key) =>(
  propertyDetails?.[key]?.currencyType || propertyDetails?.propertyInfo?.currencyType || ''
)

export const formatPropertyDatalayerData = (countryId, propertyDetails = {}, key = 'propertyInfo', page = 'nn') => {
  const attrDataLayerValues = {}
  propertyDetails?.attributes?.map((item) => {
    attrDataLayerValues[attributeDataKeys[item.key]] = item.value
  })

  let data = {
    img_URL: `${getAssetsBaseURL()}/properties/${propertyDetails?.id}/images/${encodeURI(
      propertyDetails?.propertyFiles?.images[0],
    )}/width=360,quality=60,format=auto`,
    card_position: propertyDetails?.card_position,
    srp_no: propertyDetails?.srp_no,
    property_id: propertyDetails?.id,
    verification_status: propertyDetails?.isVerified,
    is_verified: propertyDetails?.isVerified,
    is_featured: isFeatured(propertyDetails),
    is_premium: isPremiumCheck(propertyDetails),
    service: getService(propertyDetails, key) ,
    title: getTitle(propertyDetails, key),
    address: getAddress(propertyDetails, key),
    city: getCity(propertyDetails, key),
    country: getCountry(propertyDetails, key),
    property_type: getPropertyType(key, propertyDetails),
    city_id: getCityId(propertyDetails, key),
    conversion_unit: getConversionUnit(propertyDetails, key),
    currency_type: getCurrencyType(propertyDetails, key),
    property_category: getPropertyCategory(countryId, propertyDetails),
    managed_by: getManagedBy(propertyDetails, key),
    ...attrDataLayerValues,
  }

  // PDP page redirected from parameter
  if (page === 'PDP') {
    data = {
      ...data,
      redirected_from: getRedirectedFromData(propertyDetails?.id),
    }
  }

  if (key == 'propertyInfo') {
    data = {
      ...data,
      zone: propertyDetails?.[key]?.territory || propertyDetails?.propertyInfo?.territory || '',
      conversion_price: propertyDetails?.[key]?.conversionPrice || propertyDetails?.propertyInfo?.conversionPrice || '',
      furnishing_type: propertyDetails?.[key]?.furnishingType || propertyDetails?.propertyInfo?.furnishingType || '',
      possession_type: propertyDetails?.[key]?.possessionType || propertyDetails?.propertyInfo?.possessionType || '',
      sale_price: propertyDetails?.[key]?.salePrice || propertyDetails?.propertyInfo?.salePrice || '',
      rent: propertyDetails?.[key]?.expectedRent || propertyDetails?.propertyInfo?.expectedRent || '',
      rent_type: propertyDetails?.[key]?.expectedRentType || propertyDetails?.propertyInfo?.expectedRentType || '',
    }
  } else {
    data = {
      ...data,
      zone: propertyDetails?.[key]?.district || propertyDetails?.propertyInfo?.district || '',
      salePriceMax: propertyDetails?.[key]?.salePriceMax || propertyDetails?.propertyInfo?.salePriceMax,
      salePriceMin: propertyDetails?.[key]?.salePriceMin || propertyDetails?.propertyInfo?.salePriceMin,
      completionDate: propertyDetails?.[key]?.completionDate || propertyDetails?.propertyInfo?.completionDate,
    }
  }
  return data
}

const districtIdCheck = (propertyDetails) =>{
  if(typeof propertyDetails?.districtId === 'string'){
    return propertyDetails?.districtId
  }
  return propertyDetails?.districtId?.join()
}

const isVerifiedCheck = (propertyDetails) =>(
   propertyDetails?.isVerified || false
)

const getBedroom = (propertyDetails) =>(
  propertyDetails?.bedroomsCount || ''
)

const getBathroom = (propertyDetails) =>(
   propertyDetails?.bathroomsCount || ''
)

const getFurnishment = (propertyDetails) =>(
  propertyDetails?.furnishingTypeId || ''
)

const premiumListingCnt = (propertyDetails) =>(
  propertyDetails?.data?.premium_listing_cnt || 0
)

const featuredListingCnt = (propertyDetails) =>(
  propertyDetails?.data?.featured_listing_cnt || 0
)

const filterApplied = (propertyDetails) =>(
   propertyDetails?.data?.filter_applied || ''
)

const premiumCountry = (propertyDetails, key) =>(
  propertyDetails?.[key]?.country || 'Saudi Arabia'
)

export const formatPropertyDatalayerDataPremium = (countryId, propertyDetails = {}, key = 'propertyInfo', isProject = false) => {
  const attrDataLayerValues = {}
  propertyDetails?.attributes?.map((item) => {
    attrDataLayerValues[attributeDataKeys[item.key]] = item.value
  })
  return {
    srp_no: propertyDetails?.srp_no,
    is_verified: isVerifiedCheck(propertyDetails),
    is_featured: isFeatured(propertyDetails),
    is_premium: isPremiumCheck(propertyDetails),
    ...(!isProject && {service: propertyDetails?.propertyFor || propertyDetails?.projectInfo?.propertyFor || propertyDetails?.propertyInfo?.propertyFor}),
    city: propertyDetails?.[key]?.city || propertyDetails?.propertyInfo?.city || propertyDetails?.projectInfo?.city || '',
    country: premiumCountry(propertyDetails, key),
    property_type: propertyDetails?.propertyTypeData ?? '',
    city_id: propertyDetails?.[key]?.cityId || propertyDetails?.propertyInfo?.cityId || propertyDetails?.projectInfo?.cityId || '',
    district_id: propertyDetails?.districtId?.length ? districtIdCheck(propertyDetails) : '',
    district: propertyDetails?.allSelectedDistrict?.map((district) => district?.split(',')[0]).join(',') || '',
    bedroom: getBedroom(propertyDetails),
    bathroom: getBathroom(propertyDetails),
    budget: propertyDetails?.min_price ? `${propertyDetails?.min_price || ''}  , ${propertyDetails?.max_price || ''}` : '',
    furnishment: getFurnishment(propertyDetails),
    premium_listing_cnt: premiumListingCnt(propertyDetails),
    featured_listing_cnt: featuredListingCnt(propertyDetails),
    filter_applied: filterApplied(propertyDetails),
    zone_id: propertyDetails?.allSelectedDistrict?.map((district) => district?.regionId).join(',') || '',
    property_size: `${propertyDetails?.min_size || ''}  , ${propertyDetails?.max_size || ''}`,
    property_category: getPropertyCategory(countryId, propertyDetails),
  }
}
export const objectRemoveEmptyData = (data) => {
  Object.keys(data).forEach((key) => {
    if (
      data[key] === null ||
      data[key] === '' ||
      (Array.isArray(data[key]) && !data[key].length) ||
      data[key] === undefined
    ) {
      delete data[key]
    }
  })
  return data
}

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1)

export const getYoutubeId = (link) => {
  const youtubeRegex = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/
  return link.match(youtubeRegex)
}

export const getPreviousPath = () => {
  const getPrevPath = localStorage.getItem('prev_path')
  const prev = JSON.parse(getPrevPath)
  if (!prev) return '/'
  return prev.length <= 1 || null ? '/' : prev[prev?.length - 2]?.split('/').pop() || ''
}

export const myPropertyEventHandler = (eventName, lang, data = {}) => {
  const role = JSON.parse(localStorage.getItem('role'))
  const prev_path = getPreviousPath()
  const current = window.location.pathname.split('/')
  const page_type = current[current.length - 1]
  const browserType = isAndroid ? 'android' : 'Web'
  pushDataLayerEventHandler(window, lang, eventName, page_type, {
    Navigation: 'Banner',
    platform: isIOS ? 'iOS' : browserType,
    Ownership: role,
    previous_screen: decodeURI(prev_path),
    ...data,
  })

  //trigger redirection only event = 'MyProfile_SavedItems_Clicked' after data for the event has been added to the GA
  if (eventName === 'MyProfile_SavedItems_Clicked') {
    const url = `${process.env.NEXT_PUBLIC_AUCTIONBASE_URL}${lang == 'en' ? '/en' : ''}/user/saved?type=${
      process.env.NEXT_PUBLIC_DISABLE_AUCTION_FLOW == 'false' ? 'auctions' : 'properties'
    }`
    window.location.href = url
  }
}

export const updateQueryStringParameter = (uri, key, value) => {
  const re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i')
  const separator = uri.indexOf('?') !== -1 ? '&' : '?'
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + '=' + value + '$2')
  }
  return uri + separator + key + '=' + value
}

export const removeParam = (key, sourceURL) => {
  let rtn = sourceURL.split('?')[0]
  let param
  let paramsArr = []
  const queryString = sourceURL.indexOf('?') !== -1 ? sourceURL.split('?')[1] : ''
  if (queryString !== '') {
    paramsArr = queryString.split('&')
    for (let i = paramsArr.length - 1; i >= 0; i -= 1) {
      param = paramsArr[i].split('=')[0]
      if (param === key) {
        paramsArr.splice(i, 1)
      }
    }
    if (paramsArr.length) rtn = rtn + '?' + paramsArr.join('&')
  }
  return rtn
}

export const nFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ]
  const rx = /\.0+$|(\.\d*[1-9])0+$/
  const item = lookup
    .slice()
    .reverse()
    .find(function (itemFind) {
      return num >= itemFind.value
    })
  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0'
}

export const isMobileSSR = (context) =>
  Boolean(
    context?.req?.headers['user-agent']?.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i),
  )

export const isMobileIOS = () => {
  if (typeof window !== 'object') return false
  return Boolean(navigator.userAgent.match(/iPhone|iPad/i))
}

export function hasNumber(myString) {
  return /\d/.test(myString)
}

export function check_create_params(appendParam, obsoleteParam = []) {
  let url = window.location.search
  if (obsoleteParam?.length) {
    const params = getUrlParamsObject(window.location.search)
    obsoleteParam.forEach((element) => element in params && delete params[element])
    let tempURL = ''
    for (const ele in params) {
      if (tempURL.length) {
        tempURL = tempURL.concat('&', `${ele}=${params[ele]}`)
      } else {
        tempURL = tempURL.concat('?', `${ele}=${params[ele]}`)
      }
    }
    url = tempURL
  }

  if (appendParam && !window.location.search.split('?')[1]?.includes(appendParam)) {
    const urlCheck = `&${url.split('?')[1]}`
    return `?${appendParam}${url.length ? urlCheck : ''}`
  }

  return url
}

export const getPath = (params) => {
  const { pageType, pFor, propIdentityTypeId, query, udpCompoundId } = params
  if (pageType === 'CDP') {
    return `project/${pFor?.city}`
  }
  if (pageType === 'UDP') {
    return `project/${pFor?.city}/${query?.projectId || udpCompoundId}`
  }
  if (propIdentityTypeId == 95 || pageType === 'PDP') {
    return `property/${pFor?.propertyFor}`
  }
}

const getPageNumber = (router) =>{
  if(router?.query?.page){
     return `?page=${Number(router?.query?.page)}`
  }
  return ''
}

const seoUrl = (lang, metaData, pageNum) =>{
  if (lang === 'ar') {
    return `/en/${metaData.alternateSlug}${pageNum}`
  } else if (lang === 'en') {
    return `/${metaData.alternateSlug}${pageNum}`
  }
  return `/${metaData.alternateSlug}${pageNum}`
}

const changeSeoUrl = (metaData, router, lang) =>{
  if (metaData.alternateSlug) {
    const pageNum = getPageNumber(router)
    return seoUrl(lang, metaData, pageNum)
  }
}

const changeParamsUrl = (lang, router) =>{
  if (lang === 'ar') {
    return `/en${router.asPath}`
  } else if (lang === 'en') {
    return `${router.asPath}`
  }
}

const changeDetailPageUrl = (lang, slug, propertyDetails, isFirstLoadDone) =>{
  if (lang === 'ar') {
    return `/en/${slug}/${redirectUrl(propertyDetails)}${firstLoadDoneCheck(isFirstLoadDone)}`
  } else if (lang === 'en') {
    return `/${slug}/${redirectUrl(propertyDetails)}${firstLoadDoneCheck(isFirstLoadDone)}`
  }
}

const redirectUrl = (propertyDetails) =>{
  if(propertyDetails.propIdentityTypeId == 96){
    return propertyDetails?.propertyInfo?.slug
  }
  return propertyDetails?.propertyInfo?.alternateSlug
}

const firstLoadDoneCheck = (isFirstLoadDone) =>{
  if(isFirstLoadDone){
    return window.location.search
  }
  return ''
}

export const refPath = (params) => {
  const { page, pageType, propertyDetails, metaData, query, udpCompoundId, isFirstLoadDone, lang, router } = params
  if (page == 'propertyDetail' && propertyDetails?.propertyInfo) {
    const slug = getPath({
      pageType,
      propIdentityTypeId: propertyDetails?.propIdentityTypeId,
      pFor: propertyDetails?.propertyInfo,
      query,
      udpCompoundId,
    })
    return changeDetailPageUrl(lang, slug, propertyDetails, isFirstLoadDone)
  } 
  else if (metaData) {
    return changeSeoUrl(metaData, router, lang)
  } 
  else if(router){
    return changeParamsUrl(lang, router)
  }
  return router.asPath
}

export const getAssetsBaseURL = () => {
  if (process.env.NEXT_PUBLIC_IS_PRODUCTION == 'true' && process.env.NEXT_PUBLIC_CDN_UNAVAILABLE == 'false') return process.env.NEXT_PUBLIC_CDN_BASE_FILE_URL
  return process.env.NEXT_PUBLIC_BASE_FILE_URL
}

export const isUserLoggedIn = () => {
  if (typeof window !== "undefined") {
    return (window?.localStorage.getItem('authToken') || window?.localStorage.getItem('userId')) ? true : false
  }
}

export const getRemoteConfigInfo = (data = {}) => {
  let remoteConfigData =
    Object.keys(data).length > 0
      ? data?.map((item) => ({
            [item?.entity_name]: item?.entity_value,
          }))
      : []
  remoteConfigData = remoteConfigData.length > 0 ? remoteConfigData.reduce((acc, cur) => ({ ...acc, ...cur }), {}) : {}
  return remoteConfigData || {}
}
export const businessAppHandler = () => window.location.replace(process.env.NEXT_PUBLIC_WASALT_BUSSINESS)

export const formatNumber = (number) =>
  `(${number.substring(0, 4)}) ${number.substring(4, 6)} ${number.substring(6, 9)} ${number.substring(9, 13)}`

export const getHighlightedText = (text, highlight, customClass) => {
  highlight = highlight.trim()
  const highlighted = text?.replace(
    new RegExp(highlight, 'gi'),
    (highlights) => `<strong style="font-weight:600">${highlights}</strong>`,
  )
  return <span className={customClass} dangerouslySetInnerHTML={{ __html: highlighted }} />
}

export const createBreadcrumbLinksData = (dataArray, locale, t) => (
   dataArray.map(({ url, title }, index) => {
    if (url == null) return { name: title }
    const langCheck = locale === 'en' ? '/en' : '/';
    return {
      path: index === 0 ? `${langCheck}` : url,
      name: index === 0 ? t('common.HOME') : title,
    }
  })
)

export const getScrollDepth = (scrollValue) => {
  if (scrollValue >= 25 && scrollValue <= 50) {
    return '25-50%'
  }
  if (scrollValue >= 51 && scrollValue <= 75) {
    return '51-75%'
  }
  if (scrollValue >= 76 && scrollValue <= 100) {
    return '76-100%'
  }
  return '0-25%'
}
export const strToTripleDot = (value, lang = 'en') => {
  let strVal = value
  if(typeof value === 'string') strVal = (lang === 'ar') ? value?.split('،') : value?.split(',')
  const valueType = typeof value === 'string' ? value : value.join();

  return strVal?.length > 3 ? `${strVal?.slice(0, 3).join()}...` : valueType;
}
export const strToTripleDotHome = (value, lang = 'en') => {
  const arComma = value?.replaceAll(',', '،')
  let strVal = value
  if(typeof value === 'string') strVal = (lang === 'ar') ? arComma?.split('،') : value?.split(',')
  const typeValue = typeof value === 'string' ? value : value?.join()?.replaceAll(' ', '');

  return strVal?.length > 2 ? `${strVal?.slice(0, 2)?.join()?.replaceAll(' ', '')}.` : typeValue;
}
export const detectBrowser = () => {
  const ua = navigator.userAgent
  if (ua?.indexOf('Firefox') > -1) {
    return {
      name: 'Firefox',
    }
  } else if (ua?.indexOf('Chrome') > -1) {
    return {
      name: 'Chrome',
    }
  } else if (ua?.indexOf('Safari') > -1) {
    return {
      name: 'Safari',
    }
  } else if (ua?.indexOf('Edge') > -1) {
    return {
      name: 'Edge',
    }
  } else if (ua?.indexOf('Trident') > -1) {
    return {
      name: 'IE',
    }
  }
  return {
    name: 'Unknown',
  }
}

export const detecPlatform = () => {
  const ua = navigator.userAgent
  if (ua?.indexOf('Win') > -1) {
    return 'Windows'
  } else if (ua?.indexOf('Mac') > -1) {
    return 'Mac'
  } else if (ua?.indexOf('Linux') > -1) {
    return 'Linux'
  }
  return 'Unknown'
}

// customised toast notification
export const showCustomToast = (message, lang, toastid, type = 'bid-success') => {
  toast(message, {
    ...toastProperties,
    toastId: toastid,
    theme: 'colored',
    type,
    rtl: lang == 'ar' ? true : false,
    position: lang == 'ar' ? 'top-left' : 'top-right',
    autoClose: 5000,
    closeButton: false,
  })
}
export const propertyMain =(check)=>{
  switch(check){
    case 95: return "property"
    case 96: return "project"
    case 97: return "masterplan"
    default: return null
  }
}

export const unitReservationStatus = {
  AVAILABLE: 505,
  RESERVED: 516,
  SOLD_RENT: 512,
  PRE_RESERVE: 517,
  MY_BOOKING_RESERVED: 15,
  MY_BOOKING_REJECTED: 8,
  MY_BOOKING_SOLD: 10,
}

export const getClassNameByUnitTag = (tags, styles, item) => {
  if (tags === 'available') {
    return styles.available
  }
  if (tags === 'rented') {
    return styles.rented
  }
  if (tags === 'sold') {
    return styles.sold
  }
  if (tags === 'reserved') {
    if (unitReservationStatus.PRE_RESERVE !== item?.propertyInfo?.statusTypeId) {
      return checkForMyUnit(item?.reservation?.userId) ? styles.reservedMine : styles.reserved
    }
    return styles.reserved
  }
  return styles.sale
}

export const checkForMyUnit = (userId) => {
  if (userId == null) {
    return false
  }
  if (typeof window !== 'undefined') {
    if (userId === JSON.parse(window?.localStorage.getItem('userId'))) {
      return true
    }
    {
      return false
    }
  }
}
export const formatTime = (time) => (time < 10 ? `0${time}` : time)

export const displayTime = (remainingTimeVal) => {
  const displayMinutes = Math.floor((remainingTimeVal % 3600) / 60)
  const displaySeconds = remainingTimeVal % 60
  return `${formatTime(displayMinutes)}:${formatTime(displaySeconds)}`
}
export const getTimeDifference = (startTime, endTime) => {
  const start = new Date(startTime)
  const end = new Date(endTime)
  return Math.floor((end - start) / 1000)
}

export const getLabelValue = (data) => {
  switch (data?.projectInfo?.labels[1]?.name) {
    case 'For Rent':
      return 'Rented'
    case 'For Sale':
      return 'Available'
    case 'Reserved':
      return 'Reserved'
    default:
      return null
  }
}
export const userDetailsSet = (data) => {
  const itemsToSet = [
    { key: 'authToken', value: data?.token || '' },
    { key: 'user_Id', value: data?.id || '' },
    { key: 'role', value: JSON.stringify(data?.role) || '' },
    { key: 'firstName', value: data?.fullName || '' },
    { key: 'firstNameTest', value: data?.fullName || '' },
    { key: 'phoneNumber', value: data?.phoneNumber || '' },
    { key: 'countryCode', value: data?.phoneNumberCountryCode || '' },
  ]
  itemsToSet.forEach((item) => {
    window.localStorage.setItem(item.key, item.value)
  })
}

export const generateUniqueId = () => {
  const array = new Uint8Array(10);
  window.crypto.getRandomValues(array);
  const randomPart = Array.from(array, (byte) => byte.toString(36)).join('');
  const timestampPart = Date.now().toString(36);
  return `${randomPart}-${timestampPart}`;
};

export const mixpanelInit = () => {
  if (process.env.NEXT_PUBLIC_IS_PRODUCTION == 'true' || process.env.NEXT_PUBLIC_IS_PREPROD == 'true')
    mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_KEY, { track_pageview: false })
}

export const mixpanelSetIndentity = () => {
  const userId = isUserLoggedIn() && localStorage.getItem('user_Id')
  const userProfile = isUserLoggedIn() && localStorage.getItem('role')
  const User = userId + JSON.parse(userProfile)
  
  if (userId && (process.env.NEXT_PUBLIC_IS_PRODUCTION == 'true' || process.env.NEXT_PUBLIC_IS_PREPROD == 'true')) {
    mixpanel.identify(userId)
    mixpanel.people.set({$name: (User)})
  }
}

export const mixpanelReset = () => {
  if (process.env.NEXT_PUBLIC_IS_PRODUCTION == 'true' || process.env.NEXT_PUBLIC_IS_PREPROD == 'true') mixpanel.reset()
}

export const mixpanelTrackPageview = (details) => {
  const url = window?.location?.href
  const searchParams = new URLSearchParams(new URL(url).search);
  const dataDetails = {...details,
    marketing_channel_grouping: sessionStorage.getItem('marketingChnnlGrp')? sessionStorage.getItem('marketingChnnlGrp'): marketingChannelGrouping(),
    custom_source: sessionStorage.getItem('cstmSrc')? sessionStorage.getItem('cstmSrc'):getCustomSource(),
    custom_medium: sessionStorage.getItem('cstmMed')? sessionStorage.getItem('cstmMed'):customMedium() || searchParams.get('utm_medium'),
    custom_campaign: sessionStorage.getItem('cstmCmpgn')? sessionStorage.getItem('cstmCmpgn'):getCustomCampaign() ,
    custom_content: sessionStorage.getItem('cstmCntnt')? sessionStorage.getItem('cstmCntnt'):getCustomContent(),
  }
  if (process.env.NEXT_PUBLIC_IS_PRODUCTION == 'true' || process.env.NEXT_PUBLIC_IS_PREPROD == 'true')
    mixpanel.track('Page View', dataDetails)
}

export const checkRegaImageNotFound = (data = {}, isDetailPage = false) => (!isDetailPage
    ? data?.isRegaProp &&
      (!data?.propertyFiles?.images ||
        data?.propertyFiles?.images?.length === 0)
    : data?.isRegaProp &&
      (!data?.images?.data || data?.images?.data?.length === 0));

// return the selected value from the list
export const numberFormatter = (t, lang, item) => {
  if (item === undefined || item === null) return

  return t(`shareRequirement.${item?.type}`)
  .replace('max', priceConverter(item?.max, lang))
  .replace('min', priceConverter(item?.min, lang))
}

export const propertyTypeFromSlug = (t, slug) => {
  const slugToProp = {
    'apartment': t('Filter.APARTMENT'),
    'duplex': t('Filter.DUPLEX'),
    'villa-townhouse': t('Filter.VILLA'),
    'residential-land': t('Filter.LAND'),
    'building': t('Filter.BUILDING'),
    'palace': t('Filter.PALACE'),
    'rest-house': t('Filter.RENT_HOUSE'),
    'chalet': t('Filter.CHALET'),
    'farm-house': t('Filter.FARM'),
    'floor': t('Filter.FLOOR'),
    'land': t('Filter.LAND'),
    'showroom-1': t('Filter.SHOWROOM'),
    'building-1': t('Filter.BUILDING'),
    'office-space': t('Filter.OFFICE_SPACE')
  }

  return slugToProp[slug]?.toLowerCase()
}

export const getDeviceType = () => {
  if (typeof window !== 'undefined' && window.innerWidth <= 991) {
    return 'msite';
  } 
    return 'web';
  
}

export const openWhatsappInterestedIn = (lang, refId,url) => {
  let message = ''
  const number = "+9668001233227";
  if(lang === 'en') {
    message = `Hello, I'd like to inquire about your property on Wasalt.
${url}
#RefId:${refId}/1`
  } else {
    message = `السلام عليكم،
أرغب بالاستفسار عن عقارك المعروض على وصلت.
${url}
#RefId:${refId}/1`
  }
  window.open('https://api.whatsapp.com/send?phone=' + number + '&text=' + encodeURIComponent(message));
}

export const getAbCookie = (isEnabled, uCookies) => {
  if(isEnabled) {
    return uCookies?.cookies?.AB_cookie === 'A'
  }
  return true
}

export const getListCardABCookie =(isEnabled,uCookies)=>{
  if (isEnabled) {
    const cookiesCheck = uCookies?.cookies?.AB_listingCard === 'B' ? false : null;

    return uCookies?.cookies?.AB_listingCard === 'A' ? true : cookiesCheck;
  }
  return true
}
export const propertyTypesorting =(type,attributes,cardCategory)=>{
  let priorityOrder = [];
  const propertyTypes = ["Villa", "Apartment", "Duplex", "Palace", "Rest house", "Chalet", "Floor"];
  if (propertyTypes.includes(type)){
    priorityOrder = ["noOfBedrooms", "noOfBathrooms", "builtUpArea"]
    }
  else if (type === "Building" || type === "Farm"){
    priorityOrder = ["builtUpArea","completionYear"]
    }
  else if (type === "Land"){
    priorityOrder = ["carpetArea"]
    }
  else {
    priorityOrder = ["noOfBedrooms"]
  }
  const sortedData = attributes.sort((a, b) => {
    const aPriority = priorityOrder.indexOf(a.key);
    const bPriority = priorityOrder.indexOf(b.key);
  if (aPriority === -1 && bPriority === -1) {
      return 0; 
    } else if (aPriority === -1) {
      return 1; 
    } else if (bPriority === -1) {
      return -1; 
    }
    return aPriority - bPriority;
  });

  return  cardCategory === 'project' ? sortedData.slice(0, 1) : sortedData.slice(0, 3);
}

// below code will extract Video ID from almost any YouTube Link 
export const youtubeIdExtract = (url) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/; 
  const match = url?.match(regExp);
  return (match && match[7].length === 11) ? match[7] : false;
}

export const callButtonIdClick = () => {
  const callButtonId = (typeof window !== 'undefined' && localStorage.getItem('callButtonId')) || ''
  const button = document.getElementById(callButtonId)
  if (button) {
    setTimeout(() => {
      button.click()
      document.body.style.overflow = 'auto'
      typeof window !== 'undefined' && localStorage.removeItem('callButtonId')
    }, 600);
  }
}
export const isBetweenTime = (start, end) => {  
  dayjs.extend(isSameOrBefore)
  dayjs.extend(isSameOrAfter)
  return dayjs().isSameOrAfter(start, 'date') && dayjs().isSameOrBefore(end, 'date')
};

export const convertDaysToMonthsAndYears = (t, days) => {
  if (!days || days <= 0) return '';

  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);
  const remainingMonths = months % 12;

  if (years > 0) {
    if (remainingMonths > 0) {
      return `${years} ${years > 1 ? t('paymentRequest.years') : t('paymentRequest.year')} ${remainingMonths} ${t('paymentRequest.months')}`;
    }
    return `${years} ${years > 1 ? t('paymentRequest.years') : t('paymentRequest.year')}`;
  }

  return `${months} ${t('paymentRequest.months')}`;
}


export const calculateTomorrowDate = () => {
  const currentDate = new Date();

  const tomorrowDate = new Date(currentDate);
  tomorrowDate.setDate(currentDate.getDate() + 1);
  
  return tomorrowDate.toISOString().split('T')[0]
}
export const getIdByName = (cities,name) => {
  for (let i = 0; i < cities?.allCities?.length; i++) {
    if (cities?.allCities?.[i]?.name === name) {
      return cities?.allCities[i].id;
    }
  }
  return null
}

export const shiftToBeginningById = ( arr, id, key ) => {
  const targetData = arr?.filter((item) => item[key] == id) || []
  const otherData = arr?.filter((item) => item[key] != id) || []
  return [...targetData, ...otherData]
}

export const getcityNameByID =(cityId,cities)=>{
  const selectedCity = cities?.data?.allCities?.find((obj) => obj?.id == cityId)
  return selectedCity?.name;
}
export const openWhatsappLogIn = (lang, refId,url = window.location.href) => {
  let message = ''
  const number = "+9668001233227";
  if(lang === 'en') {
    message = `Sign me into Wasalt.
#${url}
#RefId:${refId}/2` 
  } else {
    message = `السلام عليكم،
أرغب بالاستفسار عن عقارك المعروض على وصلت.
#${url}
#RefId:${refId}/2`
  }
  window.open('https://api.whatsapp.com/send?phone=' + number + '&text=' + encodeURIComponent(message));
}

export const handleLogInViaWhatsapp = async (locale) => {
  const setCookieOnLogin = (token, userID) => {
    const cookie = new UCookies()
    const domain = location.host
    const cookieOptions = {
      path: '/',
      expires: new Date(new Date().setMonth(new Date().getMonth() + 1)), // 1 moth expiry added to cookie
    }
    if (domain.split('.').length > 1) {
      const domainParts = domain.split('.')
      domainParts.shift()
      cookieOptions.domain = '.' + domainParts.join('.')
    }
    cookie.set('authToken', token, cookieOptions)
    cookie.set('userId', userID, cookieOptions)
    if (domain.match(/[a-z]/i) == null) {
      delete cookieOptions.domain
      cookie.set('authToken', token, cookieOptions)
      cookie.set('userId', userID, cookieOptions)
    } else if (domain.split('.').length > 1 && (cookie.get('authToken') == null || cookie.get('authToken') != token)) {
      cookieOptions.domain = '.' + location.host
      cookie.set('authToken', token, cookieOptions)
      cookie.set('userId', userID, cookieOptions)
    }
  }
  try {
    const auth_token_data = JSON.parse(localStorage.getItem('whatsapp-auth-token'))
    const res = await fetch(`${process.env.NEXT_PUBLIC_GATEWAY_URL}autologin-verification`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        locale,
      },
      body: JSON.stringify({ 
        auth_token: auth_token_data?.auth_token 
      }),
    })
    const response = await res.json()
    if(response.status===true) {
      const responseData = await response?.data
  
      setCookieOnLogin(responseData?.token, response?.id)
      localStorage.setItem('authToken', responseData?.token)
      localStorage.setItem('userRole', responseData?.role)
      localStorage.setItem('countryCode', responseData?.phoneNumberCountryCode)
      localStorage.setItem('phoneNumber', responseData?.phoneNumber)
      localStorage.setItem('userId',responseData?.id);
      if (responseData.fullName) {
        localStorage.setItem('firstName',responseData?.fullName)
      }
    }
    else{
      localStorage.removeItem('whatsapp-auth-token')
    }
  } catch(error) {
    // eslint-disable-next-line no-console
    localStorage.removeItem('whatsapp-auth-token')
    console.error(error)
  }  
}

export const getLoginViaWhatsappData = async (lang) => {
  const auth_token_data = JSON.parse(localStorage.getItem('whatsapp-auth-token'))
  let successData ={}
  const reqHeaders = {
  'Content-Type': 'application/json',
  Cookie: 'i18next=undefined',
  locale: lang
  }
  const entType = auth_token_data?.entType
  const entId = auth_token_data?.entId
  const srcPage =auth_token_data?.srcPage

  if (entType == 95){
    const propertyDetail = await getPropertyDetailsClint(reqHeaders, entId)
    const galleryDetails = await getGalleryDetailsClint(reqHeaders,entId,false)
    successData = {...{data:propertyDetail,galleryData:galleryDetails?.images?.data.map((img) =>getGalleryKeyDataPDP(img, propertyDetail?.id),)}}
  }
  else if (entType == 96 && srcPage != 923){
    const compoundDetails = await getCompoundDetailsClint(reqHeaders, entId)
    successData={...{data:compoundDetails,galleryData:null}}
  }
  
  localStorage.removeItem('whatsapp-auth-token')
  return successData;
}


export const propertyCallClickedAPI = (data, propertyType) => {
    const entityIds = [
      {
        id: data?.id,
        propIdentityType: data?.propIdentityTypeId,
      },
    ]
    saveUnsaveAPI('saveAuction', entityIds, lang, `${propertyType}_property`)
}

export const clearError = (fieldName, errors) => {
  delete errors[fieldName]
}
//wrapper function to generate new requestId on CTA click and call the callback function
export const requestIdWrapper = (callback) => (...param) => {
  uniqueIdGenerator.generateId()
  if (callback) {
    callback(...param);  // Call the callback function with the provided parameters
  }
};

export const deleteFirstObject = (obj, deleteAfter = 20) => {
  for (const key in obj) {
      if (Array.isArray(obj[key]) && obj[key].length > deleteAfter) {
          obj[key].shift(); // Delete the first object
      }
  }
  return obj;
};

export const propertySeenAPICall = (data, propertyType, lang) => {
  if (window.localStorage.getItem('userId')) {
    const entityIds = [
      {
        id: data.id,
        propIdentityType: data.propIdentityTypeId,
      },
    ]
    saveUnsaveAPI('saveAuction', entityIds, lang, `${propertyType}_property`)
  }
}

export const youtubeUrlRegex = ()=> new RegExp(
  `^((?:https?:)?//)?` +            // Optional protocol (http or https)
  `((?:www|m)\\.)?` +               // Optional subdomain (www or m)
  `((?:youtube(?:-nocookie)?\\.com|youtu\\.be))` + // Main domain (youtube.com, youtube-nocookie.com, or youtu.be)
  `(/` +                            // Start of path
    `(?:[\\w-]+\\?v=|embed/|v/)?` +  // Optional path after domain (v=, embed/, or v/)
  `)` +
  `([\\w-]+)` +                     // Video ID (alphanumeric and hyphens)
  `(\\S+)?$`                        // Optional query parameters or fragments
)

export const convertSecondsToMinutesAndSeconds = (time) => {
  if (typeof time === 'number' && !isNaN(time)) {
    const minutes = Math.floor(time / 60)
    const seconds = time % 60
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
  }
  return '00:00'
}

export const utmTrackingParams = () => {
  return {
    "marketingChannelGrouping": sessionStorage.getItem('marketingChnnlGrp') ? sessionStorage.getItem('marketingChnnlGrp') : marketingChannelGrouping(),
    "source": sessionStorage.getItem('cstmSrc') ? sessionStorage.getItem('cstmSrc') : getCustomSource(),
    "medium": sessionStorage.getItem('cstmMed') ? sessionStorage.getItem('cstmMed') : customMedium(),
    "campaign": sessionStorage.getItem('cstmCmpgn') ? sessionStorage.getItem('cstmCmpgn') : getCustomCampaign(),
    "content": sessionStorage.getItem('cstmCntnt') ? sessionStorage.getItem('cstmCntnt') : getCustomContent(),
  }
}
export const directionCheck = (lang) => {
 let direction='rtl'
 if (lang === 'en') direction = 'ltr'
 return direction
}

export const headerTitle = (docType, t) => {
  return docType ? t('postproperty.delete_pdf') : t('postproperty.delete_image');
}

export const paragraphTitle = (docType, t) => {
  return docType
    ? `${t('postproperty.AreYouSurePropertyAuthorization')} ${docType}${t('postproperty.question_mark')}`
    : `${t('postproperty.AreYouSurePropertyAuthorization')} ${t('postproperty.image')}${t(
        'postproperty.question_mark',
      )}`
}

export const detailsPayload = (temp, routerParams, propertyTypeData, bedroomsCount, localPriceRange) => {
  return {
    propertyFor: temp,
    includeProperties: true,
    page: 0,
    pageSize: listingsPerPageSize || 32,
    cityId: routerParams?.cityId || null,
    districtId: typeof routerParams?.districtId === "string" ? routerParams?.districtId?.split(',')?.map((m) => parseInt(m)) : routerParams?.districtId || [],
    countryId: routerParams?.countryId || null,
    zoneId: routerParams?.zoneId || null,
    type: routerParams?.type || 'residential',
    sort: routerParams?.sort,
    propertyTypeData: propertyTypeData || null,
    bedroomsCount: bedroomsCount || null,
    priceRange: localPriceRange || null,
    // geo_bounding_box: params?.geo_bounding_box || null
  }
}

const environmentCheck = (url) => {
  // Regex to match the env before the "wasalt" domain
  const match = url?.match(/^(?:([^\.]+)\.)?wasalt\./);
  if (match) {
    const envPrefix = match[1];
    return envPrefix ? envPrefix : "PROD";
  }
  return url;
}

export const commonPropertiesWB = (window, screenName = 'Wasalt business') => {
  return {
    url :window?.location?.href,
    environment : environmentCheck(window?.location?.hostname),
    User_ID :window?.localStorage.getItem('userId'),
    User_type :window?.localStorage.getItem('userType'),
    Ownership :window?.localStorage.getItem('userRole'),
    Screen_Name:screenName,
    ...window?.localStorage?.getItem('Group_Type') ? { Group_Type: window?.localStorage?.getItem('Group_Type') } : {},
    ...window?.sessionStorage?.getItem("Membership") ? { Membership: window?.sessionStorage?.getItem("Membership") } : {}
  }
}

export const matchmakerUtmTrackingparams = () =>{
  return {
    "utmMarketingChannel": sessionStorage.getItem('marketingChnnlGrp') ? sessionStorage.getItem('marketingChnnlGrp') : marketingChannelGrouping(),
    "utmSource": sessionStorage.getItem('cstmSrc') ? sessionStorage.getItem('cstmSrc') : getCustomSource(),
    "utmMedium": sessionStorage.getItem('cstmMed') ? sessionStorage.getItem('cstmMed') : customMedium(),
    "utmCampaign": sessionStorage.getItem('cstmCmpgn') ? sessionStorage.getItem('cstmCmpgn') : getCustomCampaign(),
    "utmContent": sessionStorage.getItem('cstmCntnt') ? sessionStorage.getItem('cstmCntnt') : getCustomContent(),
  }
}

export const isDifferenceOneHourOrLess = (givenTime) => {
  if(!givenTime) return;
  const now = new Date();
  const givenDate = new Date(givenTime);
  const differenceMs = Math.abs(now - givenDate);
  const differenceHours = differenceMs / (1000 * 60 * 60);
  return differenceHours <= 1;
}

export const updateAuthKeys = (cookies, window) => {
  if (cookies.get('authToken') && cookies.get('userId') && cookies.get('refreshToken') && cookies.get('authStoredDate')) {
    window.localStorage.setItem('authToken', cookies.get('authToken'))
    window.localStorage.setItem('userId', cookies.get('userId'))
    window.localStorage.setItem('user_Id', cookies.get('userId'))
    window.localStorage.setItem('refreshToken', cookies.get('refreshToken'))
    window.localStorage.setItem('authStoredDate', cookies.get('authStoredDate'))
    window.localStorage.setItem('encryptedPhoneNumber', cookies.get('encryptedPhoneNumber'))
  } else {
    window.localStorage.removeItem('authToken')
    window.localStorage.removeItem('refreshToken')
    window.localStorage.removeItem('userId')
    window.localStorage.removeItem('user_Id')
    window.localStorage.removeItem('firstName')
    window.localStorage.removeItem('phoneNumber')
    window.localStorage.removeItem('countryCode')
    window.localStorage.removeItem('isExpiredPropertyAlertClosed')
    window.localStorage.removeItem('paymentStatusRequest')
    window.localStorage.removeItem('authStoredDate')
    window.localStorage.removeItem('encryptedPhoneNumber')
    window.localStorage.removeItem('userType')
    window.localStorage.removeItem('userRole')
    window.localStorage.removeItem("userRoleId")
    window.localStorage.removeItem("Group_Type")
  }
}

  
