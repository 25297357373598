export const analytics = (data) => {
  const GA_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID;
  if (!GA_ID) return; // Check for GA ID
  
  (function initializeGTM(w, d, s, i) {
    w.dataLayer = w.dataLayer || [];
    w.dataLayer.push({ ...data });
    w.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

    function loadGTM() {
      const script = d.createElement(s);
      script.async = true;
      script.defer = true;
      script.src = `https://www.googletagmanager.com/gtm.js?id=${i}`;

      d.head.appendChild(script);
    }

    if ('requestIdleCallback' in w) {
      requestIdleCallback(loadGTM);
    } else {
      setTimeout(loadGTM, 1); // Fallback for browsers without requestIdleCallback
    }
  })(window, document, 'script', GA_ID);
};
