import dynamic from 'next/dynamic'
import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'
import React, {useState, useEffect} from 'react'
import styles from './Footer.module.scss'
import { isDesktop } from 'react-device-detect'
import { pushDataLayerEventHandler } from '@/utils/utils'
const SubFooter = dynamic(()=>import("./SubFooter/SubFooter")) 
const SocialLinks = dynamic(()=>import('./SocialLinks')) 
const Category = dynamic(()=>import('./Category/Category')) 

const Footer = (props) => {
  const { footerLinks, locale, metaData, propertyDetails, page, wrapperClass, pageType ,udpCompoundId } = props
  const [onLoadItems, setOnLoadItems] = useState({
    utm_source:'Desktop',
    playstore_url:'',
    app_store_url:''
  })
  const [isDeviceDetect, setDeviceDetect] = useState(true)
  const { t, lang } = useTranslation('translations')

  useEffect(() => {
    const utm_source = isDesktop ? 'Desktop' : 'm-site';
    setOnLoadItems({
      ...onLoadItems,
      utm_source,
      playstore_url: isDesktop
        ? `https://play.google.com/store/apps/details?id=com.wasalt.app&utm_source=${utm_source}&utm_medium=${pageType}&utm_campaign=web_smart_banner_installs_android`
        : `https://webtoappadhfjk.onelink.me/K99z/pc02j39o`,
      app_store_url: isDesktop
          ? `https://apps.apple.com/in/app/wasalt/id1588885673?utm_source=${utm_source}&utm_medium=${pageType}&utm_campaign=web_bottom_install_iOS`
          : `https://webtoappadhfjk.onelink.me/K99z/pc02j39o`,
    })
  }, [isDesktop])

  const categoriesView = footerLinks &&
    Object.keys(footerLinks).length > 0 && [
      <Category
        id = "type"
        key={1}
        title={locale == 'ar' ? 'النوع' : 'Type'}
        category={footerLinks[locale == 'ar' ? 'نوع' : 'Type']}
        pageType={pageType}
      />,
      <Category
        id = "region"
        key={2}
        title={locale == 'ar' ? 'المناطق' : 'Regions'}
        category={footerLinks[locale == 'ar' ? 'المناطق' : 'Regions']}
        pageType={pageType}
      />,
    ]

  useEffect(() => {
    const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent
    if (/android/i.test(userAgent)) {
      setDeviceDetect('android')
    } else if (/iPad|iPhone|iPod/i.test(userAgent)) {
      setDeviceDetect('ios')
    } else {
      setDeviceDetect('default')
    }
  }, [])

  const openPDFInNewTab = () => {
    // Change the PDF URL to your actual PDF file URL
    const pdfUrl = `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/license/license-wasalt.pdf`;
    window.open(pdfUrl, '_blank');
  };
  
  const handleEvent=()=>{
    const data = {
      REGA_logo_type: 'Wasalt'
    }
    pushDataLayerEventHandler(window, lang, 'REGA_logo_clicked', pageType, data)
  }

  return (
    <footer className={`${styles.footer} ${styles[wrapperClass]}`}>
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.logo}>
            <div className={styles.logo_div}>
              {
                locale === 'ar' ? (
                  <Image loading='lazy' src={`${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/wasalt-logo-ar.svg`} alt='شعار وصلت' width={160} height={56} />
                ) : (
                  <Image loading='lazy' src={`${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/wasalt-logo.svg`} alt='Wasalt Logo' width={160} height={56} />
                )
              }
            </div>
            <div 
            className={styles.regaBlock} 
            onClick={()=>{
              openPDFInNewTab()
              handleEvent()}}>
              <Image id = "regaDoc" loading='lazy' src={`${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/Wasalt.png`} alt={t('common.REGA')} width={194} height={85} />
            </div>
          </div>
          {categoriesView}
          <div className={styles.staticContent}></div>
          <div className={styles.links}>
            <div 
            className={styles.storeBtns} onClick={() => pushDataLayerEventHandler(window, lang, 'app_install_link_clicked', pageType, {
                      utm_source: onLoadItems.utm_source,
                      platform: isDeviceDetect,
                      placement:"footer"
                    })}>
              <a href={onLoadItems.app_store_url}>
                <Image id = "Appstore" loading='lazy' src='/images/AppStore-gray.svg' width={162} height={48} alt='Appstore' />
              </a>
              <a href={onLoadItems.playstore_url}>
                <Image id = "Playstore" loading='lazy' src='/images/GooglePlay-gray.svg' width={162} height={48} alt='Google Playstore' />
              </a>
            </div>
            <div className={styles.socialIcons}>
              <SocialLinks lang={lang} pageType={pageType}/>
            </div>
          </div>
        </div>
      </div>  
      <SubFooter 
      metaData={metaData} 
      propertyDetails={propertyDetails} 
      page={page} 
      pageType={pageType} 
      udpCompoundId={udpCompoundId}/>
    </footer>
  )
}
export default Footer