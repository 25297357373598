import { useAppContext } from 'context/AppContext'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import IconoGraphy from '../../IconoGraphy'
import styles from './TopLocations.module.scss'

export default function TopLocations({
  title,
  top_locations,
  selectedCity,
  onSearch = null,
  isHomeSearchMobile = false,
  toggleCityBackClick,
  isFromHomeDesk = false,
  isFromSRPDesk = false,
  setShouldShowExceedLimitMsg = null,
  handleShowAll = null,
  shouldShowAllButtonVisible = false
}) {
  const router = useRouter()
  const { state, dispatch } = useAppContext()
  const {
    searchReducer: { recentSearchValue, selectedItem, showDistrictLoader },
    commonReducer: {
      slugQueries,
      removedPillLocationTypeIsCountry,
      removedPillLocationTypeIsCity,
      removedPillLocationTypeIsDistrict,
    },
  } = state
  const { t } = useTranslation('translations')
  const [onLoadItems, setOnLoadItems] = useState({
    isRecentSearch: false,
  })

  const [clickedItem, setClickedItem] = useState(null)
  useEffect(() => {
    if (localStorage.getItem('recentSearch')) {
      setOnLoadItems({
        isRecentSearch: localStorage.getItem('recentSearch'),
      })
    }
    if (
      (router?.query?.countryId && !router?.query?.cityId && !router?.query?.districtId && !router?.query?.zoneId) ||
      (slugQueries?.countryId && !slugQueries?.cityId && !slugQueries?.districtId && !slugQueries?.zoneId)
    ) {
      setClickedItem({ locationType: 'country' })
    }
  }, [])

  const handleHomePageCitySelection = () => {
    dispatch({ type: 'REMOVE_ALL_SELECTED_DISTRICT', payload: [] })
    dispatch({ type: 'SET_ALL_DISTRICTS', payload: [] })
    toggleCityBackClick && toggleCityBackClick()
  }
  const displayAllLocations = () => {
    if (isHomeSearchMobile || isFromHomeDesk || isFromSRPDesk) return true
    return (
      (recentSearchValue?.locationType != 'country' &&
        ((!slugQueries && !router?.query?.countryId) ||
          (router?.query?.countryId &&
            (clickedItem?.locationType != 'country' ||
              removedPillLocationTypeIsCountry ||
              removedPillLocationTypeIsCity ||
              removedPillLocationTypeIsDistrict)))) ||
      (slugQueries &&
        (clickedItem?.locationType != 'country' ||
          removedPillLocationTypeIsCountry ||
          removedPillLocationTypeIsCity ||
          removedPillLocationTypeIsDistrict))
    )
  }

  const displayAllLocationsButCountry = () => {
    if (isHomeSearchMobile || isFromHomeDesk || isFromSRPDesk) return false
    return (
      (!slugQueries &&
        router?.query?.countryId &&
        clickedItem?.locationType == 'country' &&
        !removedPillLocationTypeIsCountry &&
        !removedPillLocationTypeIsCity &&
        !removedPillLocationTypeIsDistrict) ||
      (slugQueries &&
        clickedItem?.locationType == 'country' &&
        !removedPillLocationTypeIsCountry &&
        !removedPillLocationTypeIsCity &&
        !removedPillLocationTypeIsDistrict) ||
      recentSearchValue?.locationType == 'country'
    )
  }
  const handleTitle = (val) => (
    <div className={styles.propertyTypeWrap}>
      <span className={`${styles.cityName} ${isFromSRPDesk && showDistrictLoader ? styles.visibilityHidden : ''}`}>{isFromSRPDesk ? val?.srpTitle || val?.title : val?.title}</span>
      {val?.distanceString && !isFromSRPDesk && (
        <p className={styles.propertyType}>{val?.distanceString}</p>
      )}
    </div>
  )
  return (
    <div
      className={`${styles.suggestion_wrap} ${isHomeSearchMobile ? styles.mobileTopLocation : ''} ${
        isFromSRPDesk ? styles.srpDesktopWrap : ''
      }`}
    >
      <div className={styles.scrollber}>
        {title && <p className={`${styles.suggestion} ${!onLoadItems.isRecentSearch && styles.mTop} `}>{title}</p>}
        <div
          id='cityList'
          className={`${isHomeSearchMobile ? styles.topCityButtonWrapper : ''} ${isFromSRPDesk ? styles.filters : ''}`}
        >
          {displayAllLocations() &&
            top_locations?.map((val, i) => (
              <button
                className={`${styles.suggestion_list} ${isFromSRPDesk && showDistrictLoader ? styles.shimmer : ''} ${isFromHomeDesk ? styles?.suggestionListings : ''} ${
                  isFromHomeDesk &&
                  (val?.title === selectedItem?.countryName[0] || val?.title === selectedItem?.cityName[0])
                    ? styles.selectedCityName
                    : ''
                }`}
                onClick={() => {
                  dispatch({ type: 'REMOVED_PILL_COUNTRY', payload: false })
                  dispatch({ type: 'REMOVED_PILL_CITY', payload: false })
                  dispatch({ type: 'REMOVED_PILL_DISTRICT', payload: false })
                  dispatch({ type: 'RECENT_SEARCH_VALUE', payload: null })
                  selectedCity(val)
                  setClickedItem(val)
                  setShouldShowExceedLimitMsg && setShouldShowExceedLimitMsg(true)
                  !isHomeSearchMobile && !isFromHomeDesk ? onSearch && onSearch(val) : handleHomePageCitySelection()
                }}
                disabled={isFromSRPDesk && showDistrictLoader}
                key={i}
              >
                <div>
                  {handleTitle(val)}
                </div>
              </button>
            ))}
           {isFromSRPDesk && shouldShowAllButtonVisible ? <button className={styles.showAll} onClick={(e)=>{handleShowAll && handleShowAll(e)}}>{t('common.SHOW_ALL')}</button> : ''}
        </div>
        {displayAllLocationsButCountry() &&
          top_locations
            ?.filter((item) => item.locationType != 'country')
            ?.map((val, i) => (
              <div
                className={`${styles.suggestion_list}`}
                onClick={() => {
                  dispatch({ type: 'REMOVED_PILL_COUNTRY', payload: false })
                  dispatch({ type: 'REMOVED_PILL_CITY', payload: false })
                  dispatch({ type: 'REMOVED_PILL_DISTRICT', payload: false })
                  dispatch({ type: 'RECENT_SEARCH_VALUE', payload: null })
                  selectedCity(val)
                  setClickedItem(val)
                }}
                key={i}
              >
                <div>
                  <span>
                    <IconoGraphy iconClass='icon-map-marker' iconColor='color_gallery_popup_close' fontSize='f_20' />
                  </span>
                  <div className={styles.propertyTypeWrap}>
                    <span className={styles.cityName}>{val.title}</span>
                    {val?.distanceString && <p className={styles.propertyType}>{val?.distanceString}</p>}
                  </div>
                </div>

                <div className={`${styles.type} ${styles.typeMobile}`}>
                  {val.locationType === 'city' && t('Search.CITY')}
                  {val.locationType === 'country' && ''}
                  {val.locationType === 'district' && t('Search.DISTRICT')}
                </div>
              </div>
            ))}
      </div>
    </div>
  )
}
