import React, { useEffect, useState }  from 'react'
import IconoGraphy from '../../IconoGraphy'
import { searchAnalyticEvent } from 'services/searchService'
import { useAppContext } from 'context/AppContext'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { getParamsFromQuery } from 'services/queryString'
import { pushDataLayerEventHandler } from '@/utils/utils'
import Loader from '../../Loader'
import css from './mobileSearchPopup.module.scss'
import SearchPropertyTypeV2 from '../SearchPropertyTypeV2'
import { commercialAndResidentialPropertyTypeOptions } from '@/utils/constant'
import CustomToggle from '../../CustomToggle'
export default function MobileSearchV2({
  setInputValue,
  isMobileOnly,
  toggleSearchableModal,
  pageName,
  selectedCity,
  inputValue,
  tabs,
  handlePropertyIdChange,
  changePropertyType,
  onSearch,
  handleCityClick,
  handleDistrictClick,
  oldValArr,
  setSearchSRP
}) {
  const router = useRouter()
  const { state, dispatch } = useAppContext()
  const { searchReducer , homeSearchReducer, commonReducer} = state
  const selectedDistricts = homeSearchReducer?.selectedDistrictsMsite
  const { t, lang } = useTranslation('translations')
  const { query } = router
  const routerParams = getParamsFromQuery({ ...query })
  const [ isCommercial,setIsCommercial] = useState(searchReducer?.propertyType==='commercial' || (pageName==="SRPpage" && routerParams?.type==='commercial') || false)
  const [isSearching,setIsSearching] = useState(false);
  const [prevData,setPrevData] = useState({})
  const [prevProjectData, setPrevProjectData] = useState({})
  const {isProject} = commonReducer
  const [projectPropertyType, setProjectPropertyType] = useState(router?.query?.project ? router.query.propertyFor:'sale')

  const page = () => {
    if (pageName === 'SRPpage') {
      return 'SRP-List'
    }
    return 'Home Page'
  }

  const placement = () => {
    if (pageName === 'SRPpage') {
      return 'SRP-List'
    }
    return 'Search_screen'
  }

  const nearbyDistrictFunc = () =>
    selectedDistricts?.some((district) => district?.regionId && district?.locationType && district?.distance)

  const popularCityFunc = () =>
    selectedDistricts?.some((district) => !district?.distance) && selectedDistricts[0]?.locationType !== 'city'

  const checkDataProperty = (data, key) => (data?.[key]?.length ? String(data[key]) : '')
  const districtCheck = (districtData) => checkDataProperty(districtData, 'district')
  const districtIdCheck = (districtData) => checkDataProperty(districtData, 'districtId')
  
useEffect(()=>{
      dispatch({type:'BACK_CLICK', payload:false}) 
      dispatch({type:'VIEW_CLICK', payload:false})
      if(pageName=='SRPpage'){
        if(!router?.query?.project){
          dispatch({type:'IS_PROJECT', payload:false})
        }  
      }
  if(homeSearchReducer?.allPropertyTypes && homeSearchReducer?.allPropertyTypes?.length!==0){
    if(homeSearchReducer?.allPropertyTypes[0].whichType!==searchReducer?.propertyType && !isProject){
      dispatch({type:"ALL_PROPERTY_TYPES", payload:commercialAndResidentialPropertyTypeOptions(t)?.[searchReducer?.propertyType || 'residential']})
    }
  }
    else if(!isProject){
      dispatch({type:"ALL_PROPERTY_TYPES", payload:commercialAndResidentialPropertyTypeOptions(t)?.[searchReducer?.propertyType || 'residential']})
    }
    if(pageName==='SRPpage'){
      actionFunction(routerParams?.propertyFor || 'sale',true)
    }
  return () =>handleDefaultReset() //Reset on search or back click
},[])

  useEffect(()=>{
    if(router.query?.type !== 'commercial' || (isProject && router.query?.type === 'commercial' && !router.query?.project ) || (router.query?.project && !isProject) ){
      setIsCommercial(false)
    }
    if(router.query?.type === 'commercial' && ((router.query?.project && isProject) || (!router.query?.project && !isProject))){
      setIsCommercial(true)
    }
  },[isProject])

useEffect(()=>{
if(pageName==='SRPpage' && routerParams?.districtId && oldValArr && oldValArr?.length!==0 && oldValArr[0].districtId){
  dispatch({type:"REMOVE_ALL_SELECTED_DISTRICT",payload: []});
  for(let i = 0; i<oldValArr?.length;i++){
    dispatch({ type: "SELECTED_DISTRICTS", payload: oldValArr[i] })
  }
}
},[])
useEffect(()=>{
  if(pageName==='SRPpage'){
  const prevCity = {
    "propertyFor": searchReducer?.propertyFor,
    "title": searchReducer?.selectedItem?.cityName[0],
    "countryId": 1,
    "cityId": searchReducer?.selectedItem?.cityId[0],
    "districtId": null,
    "city": searchReducer?.selectedItem?.cityName[0],
    "srpTitle": searchReducer?.selectedItem?.cityName[0],
    "category": searchReducer?.propertyType,
    "locationType": searchReducer?.selectedItem?.locationType, 
    "whichSelected":searchReducer?.selectedItem?.locationType
}

  if(!router?.query?.project){
    dispatch({type: 'SET_SEARCH_PROJECT_TYPES', payload:commercialAndResidentialPropertyTypeOptions(t)?.[searchReducer?.propertyType === 'commercial'?'commercial':'residentialProject']})
  }
  setPrevProjectData({ prevCity:prevCity, prevPropertyTypes: JSON.parse(JSON.stringify(commonReducer?.allSearchProjectTypes))}) //Creating a shallow copy
  setPrevData({ prevCity:prevCity, prevPropertyTypes: JSON.parse(JSON.stringify(searchReducer?.allPropertyTypes.filter((val)=>val.whichType==isCommercial?'commercial':'residential')))}) //Creating a shallow copy
  dispatch({type:'UPDATE_PROPERTYTYPE',payload:routerParams?.type || 'residential'})
}
},[])
  const actionFunction = (tabname,defaultReset=false)=>{
    if(tabname!=='project'){
      handlePropertyIdChange(null,tabname)
      changePropertyType(tabname === 'sale' ? 'sale' : 'rent')
      dispatch({type:'UPDATE_PROPERTYFOR_MSITE',payload:tabname})
      dispatch({type:'UPDATE_PROPERTYFOR',payload:tabname})  
    }
    else{
      handlePropertyIdChange(null,'sale')
      changePropertyType('sale')
      dispatch({type:'UPDATE_PROPERTYFOR_MSITE',payload:'sale'})
      dispatch({type:'UPDATE_PROPERTYFOR',payload:'sale'})  
    }
    const data = {
      service: tabname,
      placement: placement(),
      listing_type: tabname === 'project' ? 'Projects' : 'Both'
    }
   !defaultReset && pushDataLayerEventHandler(window, lang, 'Change Service', page(), data)
  }

  const handlePropertyTypeSelection = (tabname,defaultReset=false) =>{
    setIsCommercial(tabname==='commercial')
    dispatch({type:'UPDATE_PROPERTYTYPE_MSITE',payload:tabname})
    dispatch({type:'UPDATE_PROPERTYTYPE',payload:tabname})
    const data = {
      status: tabname === 'commercial',
      placement: placement(),
      change_to: tabname,
    }
    const selectedType = searchReducer?.propertyType == 'residential' ? 'commercial' : 'residential'
    dispatch({type: pageName === 'SRPpage' ? "SET_ALL_PROPERTY_TYPES" : "ALL_PROPERTY_TYPES" , payload:commercialAndResidentialPropertyTypeOptions(t)?.[defaultReset ? 'residential' : selectedType]})
    dispatch({type:pageName === 'SRPpage' ? "SET_SEARCH_PROJECT_TYPES" : "SET_HOME_PROJECT_TYPES" , payload:commercialAndResidentialPropertyTypeOptions(t)?.[tabname === 'residential' ? 'residentialProject' : 'commercial']})
    !defaultReset && pushDataLayerEventHandler(window, lang, 'view_commercial_prop', page(), data)
  }

  const onChangeToggle =()=>{
    handlePropertyTypeSelection(!isCommercial ? 'commercial':'residential')
  }
    const handlePropertyTypeSelect = (e, index) =>{
      e.stopPropagation()
      e.preventDefault();

      if(!isProject){
        let tempPropType = pageName=='SRPpage'? [...searchReducer?.allPropertyTypes] : [...homeSearchReducer?.allPropertyTypes];
        tempPropType[index].isSelected = !tempPropType[index].isSelected;
        pageName=='SRPpage'? dispatch({type:"SET_ALL_PROPERTY_TYPES", payload:tempPropType}) : dispatch({type:"ALL_PROPERTY_TYPES", payload:tempPropType})
      }
      else{
        let tempProjectType = pageName=='SRPpage'?  [...commonReducer?.allSearchProjectTypes] :[...commonReducer.allHomeProjectTypes];
        tempProjectType[index].isSelected = !tempProjectType[index].isSelected;
        pageName=='SRPpage'? dispatch({type:"SET_SEARCH_PROJECT_TYPES", payload:tempProjectType}) : dispatch({type:"SET_HOME_PROJECT_TYPES", payload:tempProjectType})
      }
    }

  const handleDefaultReset = () => {
    if(pageName!=='SRPpage'){
      setInputValue('')
      selectedCity({});
      dispatch({type:"REMOVE_ALL_SELECTED_DISTRICT",payload: []});
      dispatch({ type: "SET_ALL_DISTRICTS", payload: [] })
      actionFunction('sale',true);
      handlePropertyTypeSelection('residential',true)
    }
  }

  const searchPropertyType = () =>{
    if(isProject){
      return commonReducer.allSearchProjectTypes 
    }
    return searchReducer?.allPropertyTypes 
  }

  const homePropertyTypes = () =>{
    if(isProject){
      return commonReducer.allHomeProjectTypes
    }
    return homeSearchReducer?.allPropertyTypes
  }

  const checkFunc = () => {
    if(!searchReducer?.selectedItem){
      isMobileOnly && toggleSearchableModal()
      return;
    }
  }

  const selectedReducer = () => {
    if (pageName == 'SRPpage') {
      return searchReducer
    }
    return homeSearchReducer
  }

  const onSearchFunc = (cityValues) => {
    if (!onSearch) return
    const propertyTypes = pageName === 'SRPpage' ? searchPropertyType() : homePropertyTypes()
    onSearch(cityValues, {
      districtValue: selectedDistricts,
      propertyType: propertyTypes,
    })
  } 
  
  const getCityData = (searchCityName, cityData) => {
    if (searchCityName && searchCityName?.length !== 0 && searchCityName[0]) {
      cityData = {
        city_id: searchReducer?.selectedItem?.cityId[0] || 273,
        city: searchCityName[0] || t('Home.RIYADH'),
        popular_city: searchReducer?.selectedItem?.popularCity ?? true,
      }
    } else {
      cityData = {
        city_id: 273,
        city: t('Home.RIYADH'),
        popular_city: true,
      }
    }
    return cityData
  }

  const getDistrictData = (districtData) => {
    if(selectedDistricts && selectedDistricts?.length!=0){
      selectedDistricts?.map((val)=>{
        districtData={
          district:[...districtData?.district,val?.title],
          districtId:[...districtData?.districtId,val?.districtId]
        }
      })
    }
    return districtData
  }

  const handleSearch = () =>{
    dispatch({type:'VIEW_CLICK', payload:true})
    let cityValues
    if(pageName==='SRPpage') {
      checkFunc()
      cityValues = searchReducer?.selectedItem && searchReducer?.selectedItem?.cityName?.length!==0 && searchReducer?.selectedItem?.cityName[0] ? searchReducer?.selectedItem : {cityId:''}
    } else {
      cityValues = searchReducer?.selectedItem?.countryName && searchReducer?.selectedItem?.countryName?.length!==0 && searchReducer?.selectedItem?.countryName[0]? searchReducer?.selectedItem : {cityId:273}
    }  
  onSearchFunc(cityValues)
  if(pageName==='SRPpage'){
    isMobileOnly && toggleSearchableModal()
    dispatch({type:"REMOVE_ALL_SELECTED_DISTRICT",payload: []})
    dispatch({ type: "SET_ALL_DISTRICTS", payload: [] })
  }
  setIsSearching(true);
  let cityData;
  const searchCityName = pageName!=='SRPpage' ? searchReducer?.selectedItem?.countryName : searchReducer?.selectedItem?.cityName
  cityData = getCityData(searchCityName, cityData)
    let districtData={district:[],districtId:[]}
    districtData = getDistrictData(districtData)
    const allSelectedPropertyTypeName = selectedReducer()?.allPropertyTypes ? String(selectedReducer()?.allPropertyTypes?.filter((value)=>value.isSelected)?.map((obj)=>obj?.name)) : '' //Selected properties name
    const data ={
      ...cityData,
      district: districtCheck(districtData),
      district_id: districtIdCheck(districtData),     
      service: searchReducer?.propertyFor || 'sale',
      property_type: allSelectedPropertyTypeName,
      view_commercial_prop:isCommercial,
      nearby_district : nearbyDistrictFunc() , // distance present in nearby districts
      popular_district : popularCityFunc(), // distance not presnt in popular
      listing_type: isProject ? 'Projects': 'Both',
    }
    if(allSelectedPropertyTypeName && allSelectedPropertyTypeName!==''){
      const propertyData={
        property_type: allSelectedPropertyTypeName, 
        placement: placement()
      }
      pushDataLayerEventHandler(window, lang, 'property_type_selected',page(), propertyData)
    }
    pushDataLayerEventHandler(window, lang, 'search', page(), data)
  }

  const handleClearClick = () =>{
    dispatch({type:"REMOVE_ALL_SELECTED_DISTRICT",payload: []});
    dispatch({ type: "SET_ALL_DISTRICTS", payload: []});
  }

  const handleBackClick = () =>{
    if(pageName==='SRPpage'){ 
      dispatch({type:'BACK_CLICK', payload:true})     
      dispatch({type:"REMOVE_ALL_SELECTED_DISTRICT",payload: []});
      dispatch({ type: "SET_ALL_DISTRICTS", payload: []});
      handlePropertyTypeSelection(routerParams?.type, true);
      if(router?.query?.project|| isProject){
        selectedCity(prevProjectData?.prevCity || {}, true)
      }
      else{
        selectedCity(prevData?.prevCity || {}, true)
      }
      dispatch({type:"SET_SEARCH_PROJECT_TYPES", payload:prevProjectData?.prevPropertyTypes || []})
      dispatch({type:"SET_ALL_PROPERTY_TYPES", payload:prevData?.prevPropertyTypes || []})
    }
  }

  const displayCityValue = () =>{
    if(pageName!=='SRPpage'){
      return searchReducer?.selectedItem?.countryName[0] || searchReducer?.selectedItem?.cityName[0]|| t('Home.RIYADH')
    }
    else if(pageName==='SRPpage' && searchReducer?.selectedItem?.cityId && searchReducer?.selectedItem?.cityId[0]){
      return searchReducer?.selectedItem?.cityName[0];
    }
    else{
      return ''
    }
  } 

  const projectButton = (tabname) =>{
    if(!isProject && searchReducer?.propertyFor === tabname.key){
      return `${css.tabButton} ${css.active}`
    }
    return `${css.tabButton}`
  }

  const viewButton = () =>{
    if(isProject){
      return t('Search.VIEW_PROJECTS')
    }
    return t("Home.VIEW_PROPERTIES")
  }

  const propertyTypes = () =>{
    if(pageName==='SRPpage'){
      if(isProject){
        return commonReducer.allSearchProjectTypes
      }
        return searchReducer?.allPropertyTypes.length>0 ? searchReducer?.allPropertyTypes : commercialAndResidentialPropertyTypeOptions(t)?.[searchReducer?.propertyType || 'residential'] 
    }
      if(isProject){
        return commonReducer.allHomeProjectTypes
      }
        return homeSearchReducer?.allPropertyTypes
  }

  return (
    <div className={css.popupWrapper}>
      <div className={css.modalTop}>
        <button 
          id = 'closeSearchButton'
          className={css.close}
          onClick={() => {
            dispatch({type:'IS_PROJECT', payload:false})
            pageName==='SRPpage' && setSearchSRP && setSearchSRP(false)
            pageName==='SRPpage' && handleBackClick();
            (isMobileOnly && toggleSearchableModal())
            searchAnalyticEvent(searchReducer?.selectedItem, '', inputValue, window, lang, 'search_back')
          }}
        >
          <IconoGraphy iconClass='icon-close'  iconColor='color_gallery_popup_close'  fontSize='f_8'/>
        </button>
        <h2>{t('Search.HOME_SEARCH')}</h2>
      </div>
      <div className={css.modalBody}>
        <div className={css.modalCard}>
          <div className={css.TabPen}>
          {tabs.map((tabname, index) => (
              <div
                id = {tabname.key}
                key={index}
                className={isProject && tabname.key==='project' ?`${css.tabButton} ${css.active}`: projectButton(tabname)}         
                  onClick={() => {
                  if(tabname.key=='project'){
                    dispatch({type:'IS_PROJECT', payload:true})
                    actionFunction('project');
                    setProjectPropertyType(router?.query?.project?router.query.propertyFor:'sale')
                  }
                  else{
                    dispatch({type:'IS_PROJECT', payload:false})
                    actionFunction(tabname.key);
                    setProjectPropertyType('sale')
                  }
                }}
              >
                {tabname?.translation}
              </div>
            ))}

          </div>
          <CustomToggle
                customClass={[
                  'commercialTab',
                  'switch',
                  'slider'
                ]}
                text={isProject ? t('NewPropertySearch.VIEW_COMMERCIAL_PROJ_ONLY'):t('NewPropertySearch.VIEW_COMMERCIAL_PROP_ONLY')}
                onChangeFunction={()=>onChangeToggle()}
                check={isCommercial}
              />
        </div>

        <div className={css.modalCard}>
          <label className={css.label}>{t("Search.CITY")}</label>
          <div className={css.citySearchWrap}>
            <input id = 'citySearch' placeholder={t('NewPropertySearch.SELECT')} value={displayCityValue()} onFocus={handleCityClick} className={css.citySearch}/>
            <IconoGraphy iconClass='icon-arrow-down' fontSize='f_12' iconColor='color_lighBlack'/>
          </div>
        </div>

      {!isProject && (pageName!=='SRPpage' || pageName==='SRPpage' && searchReducer?.selectedItem?.cityId && searchReducer?.selectedItem?.cityId[0]) && <div className={css.modalCard}>
          <label className={css.label}>{t("NewPropertySearch.DISTRICT")}</label>
          <div className={css.distSearchWrap} onClick={handleDistrictClick}>
            <IconoGraphy iconClass='icon-search-new' fontSize='f_16'/>
          <input id = 'districtSearch' placeholder={`${t("NewPropertySearch.SEARCH_DISTRICTS_IN")} ${searchReducer?.selectedItem?.countryName[0] || searchReducer?.selectedItem?.cityName[0] || t('Home.RIYADH')}`} className={css.distSearch}/>
          </div>
         {selectedDistricts?.length!==0 && 
         <div className={css.selectedDist}>
            {selectedDistricts?.map((dist,index)=>
            <button id = {`district-${index}`} key = {index} className={css.btn}> 
              {dist?.srpTitle}
              <span id='removeSelection'>
              <IconoGraphy
                iconClass='icon-close'
                iconColor='color_purple'
                fontSize='f_7'
                onClickHandler={()=>dispatch({type:"REMOVED_DISTRICT",payload: dist})}
              />
              </span>
            </button>
            )}
          {selectedDistricts?.length > 1 && <button id ='clearAll' className={css.clearAll} onClick={()=>handleClearClick()}>{t("Search.CLEARALL")}</button> }
          </div> }
        </div> }

        {isProject && <div className={css.modalCard}>
          <label className={css.label}>{t('Filter.PURPOSE')}</label>
          <ul className={css.residentialType}>
          <li><button type='button' className={`${css.saleType} ${projectPropertyType === 'sale' && css.selected}`} onClick={()=>{
            dispatch({type:'UPDATE_PROPERTYFOR',payload:'sale'})
            dispatch({type:'UPDATE_PROPERTYFOR_MSITE',payload:'sale'})
            setProjectPropertyType('sale')
            actionFunction('sale');
            }}>{t('Home.BUY')}</button></li>
          <li><button type='button' className={`${css.saleType} ${projectPropertyType === 'rent' && css.selected}`} onClick={()=>{
            dispatch({type:'UPDATE_PROPERTYFOR',payload:'rent'})
            dispatch({type:'UPDATE_PROPERTYFOR_MSITE',payload:'rent'})
            setProjectPropertyType('rent')
            actionFunction('rent');
            }}>{t('Home.RENT')}</button></li>
          </ul>
        </div>}
        <div className={css.modalCard}>
          <SearchPropertyTypeV2 css={css} label={t("NewPropertySearch.PROPERTY_TYPE_HEADING")} 
          propertyTypes= {propertyTypes()} 
          handlePropertyTypeSelect = {handlePropertyTypeSelect}/>
        </div>
      </div>

      <div className={css.buttons}>
        <button id = 'viewPropertiesButton' type='button' className={css.viewProperties} onClick={()=>handleSearch()}>{!isSearching ? viewButton() : <Loader/>}</button>
      </div>
    </div>
  )
}