import React from 'react'
import { useRouter } from 'next/router'
import { SchemaMarkup } from '@/utils/schemaMarkup'
import { getAssetsBaseURL } from '@/utils/utils'

function SchemaMarkupComponent(props) {
  const router = useRouter()
  const {
    t,
    lang,
    metaData,
    params,
    compoundDetails,
    breadcrumbsLinks,
    compoundBreadcrumbDetails,
    galleryDetails,
    propertyDetailsV3,
  } = props

  const queryString = () =>{
    if(router?.query?.page){
      return `?page=${router?.query?.page}`
    }
    return ''
  }

  const ArabicLangCheck = () =>{
    if(lang == 'ar'){
      return metaData?.slug
    }
    return metaData?.alternateSlug
  }

  const EnglishLangCheck = () =>{
    if(lang == 'en'){
      return metaData?.slug
    }
    return metaData?.alternateSlug
  }

  const getEnglishSlugProperty = () =>{
    if(lang == 'en'){
      return propertyDetailsV3?.propertyInfo?.slug
    }
    return propertyDetailsV3?.propertyInfo?.alternateSlug
  }

  const getArabicSlugProperty = () =>{
    if(lang == 'ar'){
      return propertyDetailsV3?.propertyInfo?.slug
    }
    return propertyDetailsV3?.propertyInfo?.alternateSlug
  }
  const englishSlugProperty = getEnglishSlugProperty() || ''
  const arabicSlugProperty = getArabicSlugProperty() || ''

  const pageUrlCompound = `/project/${params?.slug}/${compoundDetails?.data?.projectInfo?.slug}`
  const getBreadcrumbForSchema = () => {
    const breadCrumbArray = Array.isArray(propertyDetailsV3?.propertyInfo?.breadCrumbs)
      ? propertyDetailsV3.propertyInfo.breadCrumbs.map((breadcrumb) => ({
          name: breadcrumb.title,
          item: `${lang === 'en' ? '/en' : ''}/${breadcrumb.url}`,
        }))
      : []
    
    return [
      { name: t('schema.Homepage'), item: lang == 'en' ? '/en' : '' },
      ...breadCrumbArray
    ]
  }

  const langCheck = () =>{
    if(lang == 'en'){
      return '/en'
    }
    return ''
  }

  const getBreadCrumbDetailsSlug = () =>{
    if(lang == 'en'){
      return `/en/${compoundBreadcrumbDetails?.slug}`
    }
    return `/${compoundBreadcrumbDetails?.slug}`
  }

  const slugPropertyLang = () =>{
    if(lang === 'en'){
      return englishSlugProperty
    }
    return arabicSlugProperty
  }

  return (
    <>
      {router.pathname == '/' && SchemaMarkup('', '', '', '', t, lang, { schemaType: 'Home' })}
      {router.pathname == '/about' &&
        SchemaMarkup('/about', '/about', '/about', '/about', t, lang, { schemaType: 'Home' })}
      {router.pathname == '/[slug]' &&
        SchemaMarkup(
          `/${metaData?.canonical_tag || metaData?.slug}${queryString()}`,
          `/${metaData?.alternate_ar || ArabicLangCheck()}${queryString()}`,
          `/${metaData?.alternate_en || EnglishLangCheck()}${queryString()}`,
          `/${metaData?.x_default || ArabicLangCheck()}${queryString()}`,
          t,
          lang,
          {
            breadcrumbsList: ( (Array.isArray(breadcrumbsLinks) && breadcrumbsLinks.length > 1)
              ? breadcrumbsLinks.map((breadcrumb, index) => {
                if (index == 0) return { name: t('schema.name'), item: langCheck() }
                return {
                  name: breadcrumb.title,
                  item: `${
                    index + 1 == breadcrumbsLinks.length
                      ? `${langCheck()}/${params.slug}`
                      : breadcrumb.url
                  }`,
                }
                }).slice(0, -1) 
              : [{ name: t('schema.Homepage'), item: langCheck() },{ name: params.slug, item: `${langCheck()}/${params.slug}` }] ),
            schemaType: 'BreadcrumbList',
          },
        )}
      {router.pathname == '/project/[slug]/[projectId]' &&
        SchemaMarkup(
          pageUrlCompound,
          pageUrlCompound,
          pageUrlCompound,
          pageUrlCompound,
          t,
          lang,
          {
            breadcrumbsList: [
              { name: t('schema.name'), item: langCheck() },
              {
                name: compoundBreadcrumbDetails?.title,
                item: getBreadCrumbDetailsSlug(),
              },
              // {name: compoundDetails?.data?.projectInfo?.title, item: lang == 'en' ? `/en${pageUrl}` : pageUrl },
            ],
            propertyDetails: {
              ...compoundDetails.data,
              propertyInfo: compoundDetails?.data?.projectInfo,
              propertyOwner: { phone: compoundDetails?.data?.contactDetails?.phone },
            },
            propertyImage: `${getAssetsBaseURL()}/compound/${compoundDetails?.data?.id}/images/${encodeURI(
              galleryDetails?.images?.data[0]?.content,
            )}/quality=60`,
            schemaType: 'DetailPage',
          }
        )}
      {router.pathname == '/property/[slug]/[slug-id]' &&
        propertyDetailsV3 &&
        Object.keys(propertyDetailsV3).length > 0 &&
        ![301, 404].includes(propertyDetailsV3?.statusCode) &&
        SchemaMarkup(
          `/property/${params.slug}/${slugPropertyLang()}`,
          `/property/${params.slug}/${arabicSlugProperty}`,
          `/property/${params.slug}/${englishSlugProperty}`,
          `/property/${params.slug}/${englishSlugProperty}`,
          t,
          lang,
          {
            breadcrumbsList: getBreadcrumbForSchema(),
            propertyDetails: propertyDetailsV3,
            propertyImage:
              getAssetsBaseURL() +
              '/properties/' +
              propertyDetailsV3.id +
              '/images/' +
              galleryDetails?.images?.data[0]?.content +
              '/quality=50,w=480',
            schemaType: 'DetailPage',
          }
        )}
    </>
  )
}

export default SchemaMarkupComponent