import React, { useEffect, useState } from 'react'
import IconoGraphy from '../../IconoGraphy'
import { searchAnalyticEvent } from 'services/searchService'
import { useAppContext } from 'context/AppContext'
import useTranslation from 'next-translate/useTranslation'
import Loader from '../../Loader'
import { getAutoSuggestData } from 'services/cityOptionsService'
import cssMob from './mobileDistrictSearch.module.scss'
import cssDesk from './desktopHomeDistrictSearch.module.scss'
import { useMediaQuery } from 'react-responsive'
import RecentSearchNew from '../RecentSearchNew'
import { pushDataLayerEventHandler } from '@/utils/utils'

export default function DistrictSearch({
  isMobileOnly,
  setSearchLoader,
  toggleDistrictBackClick,
  isFromDesktop=false,
  isMobileSSR=false,
  districtSearchDesktop='',
  setInputValueDesktop=false,
  propertyType,
  pageName='',
  showAllDistrictsDataDesktop,
  noPopularDistrict = false,
}) {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const { state, dispatch } = useAppContext()
  const { searchReducer, homeSearchReducer } = state
  const { t, lang } = useTranslation('translations')
  const allDistricts = homeSearchReducer?.allDistricts
  const selectedDistricts = homeSearchReducer?.selectedDistrictsMsite
  const [isLoader, setIsLoader] = useState(false)

  const getInitialValue = () =>{
    if(!isMobileSSR){
      return districtSearchDesktop || ''
    }
    return ''
  }
  const [inputValue, setInputValue] = useState(getInitialValue())
  const [selectionLimitMsgShow,setSelectionLimitMsgShow] = useState(true)
  const [noDataMsg,setNoDataMsg] = useState(false)
  const [showAllDistrictsData,setShowAllDistrictsData] = useState([])
  const [shouldInitialLoaded, setShouldInitialLoaded] = useState(false)
  const { showAllClicked } = homeSearchReducer

  const displayNoDataMsg = () =>{
    if(noDataMsg){
      setNoDataMsg(false)
    }
  }

  const setDistricts = (data) =>{
    if(data && (Array.isArray(data) && data?.length!==0 || !Array.isArray(data) && Object.keys(data).length !== 0) && data?.allCities?.length!==0){
      dispatch({ type: "SET_ALL_DISTRICTS", payload: data })
      setShowAllDistrictsData(data);
    }
    else if(data && Array.isArray(data) && data?.length===0){ 
      dispatch({ type: "SET_ALL_DISTRICTS", payload: [] })
      setNoDataMsg(true)
      setShowAllDistrictsData([])
    }
    if(data?.allCities && data?.allCities?.length==0){
      dispatch({ type: "SET_ALL_DISTRICTS", payload: [] })
      setShowAllDistrictsData([])
    }
  }

  const fetchAllDistricts = async (searchedDistrict = null) => {
    displayNoDataMsg()
    let payload = {
      "newAutoSuggest": true,
      "propertyFor": searchReducer?.propertyForMsite
    }
    if (selectedDistricts?.length == 0 || searchedDistrict) {
      payload = {
        ...payload,
        "cityId": !searchReducer?.selectedItem?.cityId || searchReducer?.selectedItem?.cityId?.length == 0 ? 273 : searchReducer?.selectedItem?.cityId[0] || 273,
      }
      if (searchedDistrict) {
        payload = {
          ...payload,
          "districtId": selectedDistricts?.length !== 0 ? selectedDistricts?.map((district) => district?.districtId) : [],
          "searchText": searchedDistrict ?? '',
          "districtOnly":true
        }
      }
    }
    else {
      payload = {
        ...payload,
        districtId: selectedDistricts?.map((district) => district?.districtId)
      }
    }
    const reqHeaders = {
      'Content-Type': 'application/json;charset=UTF-8',
      locale: lang,
    }
    const data = await getAutoSuggestData(reqHeaders, payload)
    setDistricts(data)
    setIsLoader(false)
  }
  useEffect(() => {
    if((shouldInitialLoaded && !isMobile) || isMobile){
      if(noPopularDistrict && isFromDesktop && selectedDistricts?.length==0){
        setIsLoader(false)
        dispatch({ type: "SET_ALL_DISTRICTS", payload: [] })
      }
      else{
        setIsLoader(true)
      }
      fetchAllDistricts()
    }
    !selectionLimitMsgShow && setSelectionLimitMsgShow(true) 
    dispatch({ type: "SET_SHOW_ALL_CLICKED", payload: false })
  }, [selectedDistricts])
  useEffect(() => {
    const timeout = setTimeout(() => {
      if((shouldInitialLoaded && !isMobile) || isMobile) {
        setIsLoader(true)
        fetchAllDistricts(inputValue)
      }
    }, 600);
   !shouldInitialLoaded && setShouldInitialLoaded(true)
    return () => clearTimeout(timeout);
  }, [inputValue])
  useEffect(()=>{
    if(!isMobileSSR){
      setInputValue(districtSearchDesktop)
    }
    if(showAllDistrictsDataDesktop){
      setShowAllDistrictsData(showAllDistrictsDataDesktop)
    }
  },[districtSearchDesktop, showAllDistrictsDataDesktop])
  const changeFn = (e) => {
    setInputValue(e.target.value.trimStart())
  }

  const getDistrictTitleWithBoldSearchText = (districts) => {
    const boldText = inputValue.trim()
    const highlightedText = districts?.replace(
      new RegExp(boldText, 'gi'),
      (match) => `<strong>${match}</strong>`
    );
    return <span dangerouslySetInnerHTML={{ __html: highlightedText }} /> // To show the bold and normal text together
  }  
  const checkSelectedDistrictExists = (district_Id) => selectedDistricts?.length !== 0 && selectedDistricts?.some(district => district.districtId === district_Id);

  const handleDistrictSelect = (district) =>{
    const isAlreadySelected = checkSelectedDistrictExists(district?.districtId) // To check the duplicate district selection
    if( !isAlreadySelected ){
      dispatch({ type: "SELECTED_DISTRICTS", payload: district })
      setInputValue('')
      if(!isMobileSSR && setInputValueDesktop){
        setInputValueDesktop('')
      }
      if(inputValue !==''){
        dispatch({ type: "SET_ALL_DISTRICTS", payload: [] })
      }
    }
  }

  const handleShowAll = () => {
    dispatch({ type: "SET_SHOW_ALL_CLICKED", payload: true })
    pushDataLayerEventHandler(window, lang, 'district_show_all', 'Home Page');
  }
  const handleClearAll = () =>{
    dispatch({type:"REMOVE_ALL_SELECTED_DISTRICT",payload: []})
  }
  
  const css = () =>{
    if(isMobileSSR || isMobile){
      return cssMob
    }
    return cssDesk
  }

  const displayDistrictPillsName = (district) =><span className={isLoader && !inputValue ? css().visibilityHidden : ''}> {district?.srpTitle} </span>
  const allDistrictsMapHtml = (district,index) => (
    <>
      {shouldListShow && <span>
        <IconoGraphy iconClass='icon-map-marker' iconColor='color_gallery_popup_close' fontSize='f_20' />
      </span>}
      <button className={`${css().filterBtn} ${isLoader && !inputValue ? css().shimmer : ''}`} key={index} onClick={(e) => {e.stopPropagation(); handleDistrictSelect(district)}} disabled={isLoader && !inputValue}>
        {shouldListShow ? getDistrictTitleWithBoldSearchText(district?.title) : displayDistrictPillsName(district)}
      </button>
    </>
  )
  const allFetchedDistricts = () =>{
    if(showAllClicked){
      return showAllDistrictsData?.allDistricts
    }
    return allDistricts?.allCities ?? allDistricts
  }

  const cityName = () =>{
    if(pageName==='SRPpage'){
      return searchReducer?.selectedItem?.cityName[0]
    }
    return searchReducer?.selectedItem?.countryName[0] || t('Home.RIYADH')
  }

  const shouldListShow = !allDistricts?.allCities || (showAllClicked && selectedDistricts?.length===0)

  const selectedClass = () =>{
    if (shouldListShow) {
      return css().FilterSearch
    } 
    return css().FilterWrapper
  }

  const districtText = () =>{
    if (selectedDistricts?.length === 0) {
      return `${t("NewPropertySearch.POPULAR_DISTRICTS_IN")} ${cityName()}`;
    } 
    return t("NewPropertySearch.NEARBY_DISTRICT");
  }

  const showAllButton = () =>{
    if(selectedDistricts?.length===0 && allDistricts?.allCities && !showAllClicked && showAllDistrictsData && (!isMobileSSR && !isMobile) ){
      return(
        <button className={css().showAll} onClick={handleShowAll}>{t('common.SHOW_ALL')}</button>
      )
    }
    return ''
  }

  const showDataStyle = () =>{
    if(isFromDesktop && !noDataMsg && allDistricts && allDistricts?.length == 0 && selectedDistricts?.length==0 && !isLoader ){
      return css().noDisplay
    }
    return ''
  }

  const displayIconClose = () => {
    if (isLoader && inputValue) {
      return (
        <div className={css().loaderWrap}>
          <Loader color={true} />
        </div>
      )
    } else if (inputValue) {
      return (
        <div className={css().loaderWrap}>
          <IconoGraphy
            iconClass='icon-close'
            iconColor='color_gallery_popup_close'
            fontSize='f_8'
            onClickHandler={() => {
              setInputValue('')
              setSearchLoader && setSearchLoader(false)
            }}
          />
        </div>
      )
    }
  }

  const displayLimitMsgStyle = () => {
    if (!selectionLimitMsgShow || (!inputValue && allDistricts?.length === 0)) {
      return css().noBottomBorder
    }
    return ''
  }

  const showClearAll = () =>{
    if(selectedDistricts?.length > 1 && isFromDesktop){
      return(
        <button id = 'clearAll' onClick={()=>handleClearAll() } className={css().clearAll}>{t("Search.CLEARALL")}</button>
      )
    }
  }

  const showRecentSearch = () =>{
    if(isMobileOnly && allDistricts?.allCities){
      return(
        <RecentSearchNew
          propertyType = {propertyType}
          searchType = {'district'}
          selectedCityId={!searchReducer?.selectedItem?.cityId || searchReducer?.selectedItem?.cityId?.length == 0 ? 273 : searchReducer?.selectedItem?.cityId[0] || 273}
          setInputValue = {handleDistrictSelect}
        />
      )
    }
  }

  const showNoDataMsg = () =>{
    if(noDataMsg){
      return(
        <div className={css().noMatch}><div className={css().moMatchText}>{`${t("NewPropertySearch.NO_MATCHING_DISTRICT_IN")} ${searchReducer?.selectedItem?.countryName[0] || t('Home.RIYADH')}`}</div></div>
      )
    }
  }

  const displayContinue = () =>{
    if(!isFromDesktop){
      return(
        <div className={css().buttons}>
          <button id = 'continueButton' onClick={() => isMobileOnly && toggleDistrictBackClick()}>{t('NewPropertySearch.CONTINUE')}</button>
        </div>
      )
    }
  }
  return (
    <div className={`${css().DistSearchWrap} ${showDataStyle()}`}>
      <div className={css().scrollber}>
      <div className={css().searcharea}>
       {!isFromDesktop && <div className={css().searchBox}>
          <span id = 'goBack' className={css().searchIconPlaceholder} 
          onClick={() => {
            (isMobileOnly && toggleDistrictBackClick())
            searchAnalyticEvent(searchReducer?.selectedItem, '', inputValue, window, lang, 'search_back')
          }}
          >
            <IconoGraphy
              iconClass='icon-chevron-left'
              iconColor='color_gray_darker'
              fontSize='f_10'
            />
          </span>
          <input
            type='text'
            name='searchBox'
            id = 'districtSerachInput'
            placeholder={`${t("NewPropertySearch.SEARCH_DISTRICTS_IN")} ${cityName()}`}
            value={inputValue}
            className={css().searchDist}
            onChange={(e) => {
              changeFn(e)
            }}
            autoComplete='off'
            autoFocus={true}
          />
            {displayIconClose()}
        </div> }
        {selectedDistricts?.length !== 0 && <div id = 'selectedDistrict' className={`${css().selectedDist} ${displayLimitMsgStyle()}`}>
          {selectedDistricts?.map((dist,index) =>
            <button id = {`district-${index}`} key = {index} className={css().btn}>
              {dist?.srpTitle}
              <span id='removeSelection'>
              <IconoGraphy
                iconClass='icon-close'
                iconColor='color_purple'
                fontSize='f_7'
                onClickHandler={()=>dispatch({ type: "REMOVED_DISTRICT", payload: dist })}
              />
              </span>
            </button>
          )}
          {showClearAll()}
        </div>}
      </div>
      <div className={css().scrollingDiv}>
      {showRecentSearch()}
       {!noDataMsg && <>
        {selectedDistricts?.length < 5 ? <div className={selectedClass()}>
          {allDistricts?.allCities && !showAllClicked && <h4>{districtText()}</h4>}
          <div id = 'districtList' className={css().filters}>
            {allFetchedDistricts() && allFetchedDistricts()?.map((district, index) =>
              <>
              {shouldListShow ? 
              <div className={css().allDistView} onClick={() => handleDistrictSelect(district)}>
              {allDistrictsMapHtml(district,index)}
              </div> :
              allDistrictsMapHtml(district,index)}
              </>
            )}
            {showAllButton()}
          </div>
        </div> :
        selectionLimitMsgShow && <div className={css().SelectionLimit}>
            <div className={css().alert}>
              <IconoGraphy iconClass='icon-error' fontSize='f_16' iconColor='color_white'/>
              <span>{t("NewPropertySearch.DISTRICTS_SELECT_LIMIT")}</span>
              <button onClick={()=>setSelectionLimitMsgShow(false)}>
                <IconoGraphy iconClass='icon-close' fontSize='f_7' iconColor='color_white'/>
              </button>
            </div>
          </div> 
        }
        </> }
        {showNoDataMsg()}
      </div>
      {displayContinue() }
        </div>
    </div>
  )
}