export const commonReducer = (state, action) => {
  switch (action.type) {
    case 'SET_IS_MAPBOX_LOADED':
      return {
        ...state,
        isMapBoxLoaded: action.payload
      }
    case 'SET_SLUG_QUERIES':
      return {
        ...state,
        slugQueries: action.payload
      }
    case 'REMOVED_PILL_DISTRICT':
      return {
        ...state,
        removedPillLocationTypeIsDistrict: action.payload
      }
    case 'REMOVED_PILL_CITY':
      return {
        ...state,
        removedPillLocationTypeIsCity: action.payload
      }
    case 'REMOVED_PILL_COUNTRY':
      return {
        ...state,
        removedPillLocationTypeIsCountry: action.payload
      }
    case 'SET_IS_DROP_DOWN_DATA_EMPTY':
      return {
        ...state,
        isDropDownDataEmpty: action.payload
      }
    case 'SET_IS_OUTSIDE_CLICK':
      return {
        ...state,
        isOutsideClick: action.payload
      }
    case 'SET_OPEN_APP_HEADER': 
      return {
        ...state,
        isOpenAppHeaderEnabled: action.payload
      }
    case 'SET_PTYPE':
      return {
        ...state,
        pType: action.payload,
      }
    case 'SET_TYPE':
      return {
        ...state,
        type: action.payload,
      }
    case 'SET_REMOTE_CONFIG_PROPERTIES':
      return {
        ...state,
        remoteConfigProperties: action.payload,
      }
    case 'SET_REMOTE_CONFIG_COMPOUND':
      return {
        ...state,
        remoteConfigCompound: action.payload,
      }
    case 'SET_REMOTE_CONFIG_INFO_COMPOUND':
      return {
        ...state,
        remoteConfigInfoCompound: action.payload,
      }
    case 'SET_REMOTE_CONFIG_INFO_PROPERTY':
      return {
        ...state,
        remoteConfigInfoProperty: action.payload,
      }
    case 'SET_REMOTE_CONFIG_INFO_PROPERTY_NEW':
      return {
        ...state,
        remoteConfigInfoPropertyNew: action.payload,
      }
    case 'SET_PROPERTY_ID':
      return {
        ...state,
        propertyId: action.payload,
      }
    case 'FEATURE_AGENT_DETAILS':
      return {
        ...state,
        featureAgentDetails: action.payload,
      }
    case 'IS_PROJECT':
      return{
        ...state,
        isProject: action.payload
      }
    case 'SET_HOME_PROJECT_TYPES':
      return{
        ...state,
        allHomeProjectTypes: action.payload
      }
    case 'SET_SEARCH_PROJECT_TYPES':
      return{
        ...state,
        allSearchProjectTypes: action.payload
      }
    case 'BACK_CLICK':
      return{
        ...state,
        backClick:action.payload
      }
    case 'VIEW_CLICK':
      return{
        ...state,
        viewClick: action.payload
      }
    default:
      return state
  }
}
