import dynamic from 'next/dynamic'
import { observerService } from '@/utils/observer'
import { handleBackGroundScroll, priceConverter, pushDataLayerEventHandler } from '@/utils/utils'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { isDesktop, isMobileOnly } from 'react-device-detect'
import { getParamsFromQuery } from 'services/queryString'
import css from './Header.module.scss'
import UCookies from 'universal-cookie'
import { useAppContext } from 'context/AppContext'
import mobileNavbarCss from './PrimaryNav.module.scss'
import { isAuctionLiveCount } from 'services/auctionService'

const IconoGraphy = dynamic(()=>import('../IconoGraphy')) 
const PrimaryNavbarComponent = dynamic(()=>import('./PrimaryNav')) 
const SecondaryNav = dynamic(()=>import('./SecondaryNav')) 
const CustomPropertyDetail = dynamic(()=>import('./CustomPropertyDetail')) 
const Brand = dynamic(()=>import('./Brand')) 
const ReservationFailedStickyHeader = dynamic(()=>import('./ReservationFailedStickyHeader')) 


let importMethods = null
let ClientMethod = null

const Header = ({
  locale,
  headerCities,
  page,
  stickyHeader,
  metaData,
  propertyDetails,
  isSuggestedUnit = false,
  isOpenAppHeader,
  customClass,
  slugQueries = {},
  pageType,
  udpCompoundId = null,
  showTopClass,
}) => {
  const [ProfileSideMenu, setProfileSideMenu] = useState(null);
  const [LoginPopup, setLoginPopup] = useState(null);
  const [navbarOpen, setNavbarOpen] = useState(false)
  const [revampNavOpen, setRevampNavOpen] = useState(false)
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [isDesk, setIsDesk] = useState(false)
  const [isFirstLoadDone, setIsFirstLoadDone] = useState(false)
  const [statusTag, setStatusTag] = useState(null);
  const { t, lang } = useTranslation('translations')
  const { state, dispatch } = useAppContext()
  const {
    searchReducer,
    commonReducer: { isMapBoxLoaded },
  } = state
  const router = useRouter()
  const cookies = new UCookies()
  const { query } = router
  const routerParams = getParamsFromQuery({ ...query, ...slugQueries })
  const attributeDataKeys = {
    noOfBedrooms: 'bedrooms',
    noOfBathrooms: 'bathrooms',
    builtUpArea: 'builtUpArea',
    carpetArea: 'carpetArea',
    noOfLivingrooms: 'livingrooms',
    noOfGuestrooms: 'guestrooms',
    furnishingType: 'furnishType',
    completionYear: 'completionYear',
  }

  const [isSignInClick, setIsSignInClick] = useState(false)
  const onScreenClose = () => setIsSignInClick (false);

  const onSuccessLogin = (isNewUser = false) =>{
    isNewUser ?  router.push('/user/register') : router.push('/user/e-profile');
  }

  let salesPriceMinFormat,
    salesPriceMaxFormat,
    priceCondition,
    salesPrice = null

  let priceDisplay;

  if (propertyDetails && Object.keys(propertyDetails).includes('projectInfo')) {
    salesPriceMinFormat = priceConverter(propertyDetails?.propertyInfo?.conversionPriceMin)
    salesPriceMaxFormat = priceConverter(propertyDetails?.propertyInfo?.conversionPriceMax)
    priceCondition = salesPriceMinFormat !== salesPriceMaxFormat ? true : false
  } else {
    salesPrice =
      pageType !== 'CDP'
        ? priceConverter(propertyDetails?.propertyInfo?.conversionPrice)
        : `${priceConverter(propertyDetails?.propertyInfo?.conversionPriceMin)} - ${priceConverter(
            propertyDetails?.propertyInfo?.conversionPriceMax,
          )}`
  }

  const dynamicImportMethods = async () => {
    if(!importMethods) {
      importMethods = await import(/*webpackChunkName: "ImportMethods" */ '../../../components/PropertyDetails/methods/importMethods')
    }
  }

  const loadDynamicContent = async () => {
    await dynamicImportMethods()
    if(revampNavOpen && !ProfileSideMenu) {importMethods.importProfileSideMenu({ setProfileSideMenu })}
    if(isSignInClick && !LoginPopup) {importMethods.importLoginPopup({ setLoginPopup })}
  }

  useEffect(() => {
    loadDynamicContent();
  },[revampNavOpen, isSignInClick])

  const handleNavbar = (currentState) => {
    setNavbarOpen(!currentState)
  }

  // Added to handle filter drawer open in mobile view
  useEffect(() => {
    setIsMobile(isMobileOnly)
  }, [isMobileOnly])
  useEffect(() => {
    setIsDesk(isDesktop)
  }, [isDesktop])
  
  useEffect(() => {
    const fetchData = async () => {
      const liveAuction = await isAuctionLiveCount()
      const upcomingCase = liveAuction[1] > 0 ? "upcoming" : "closed"
      setStatusTag(liveAuction[0] > 0 ? "live"  : upcomingCase )
    }
    fetchData()
    setIsFirstLoadDone(true)
    if (localStorage.getItem('m_listViewBtnClicked') == 'false') {
      dispatch({ type: 'SET_SHOW_APP_HEADER', payload: false })
    }
    // window.addEventListener('beforeunload', handleBeforeUnload)
    const observer = observerService.observable().subscribe((val) => {
      if (val?.data?.type === 'filterDrawerOpen' && val?.data?.data && isMobile) {
        setFilterDrawerOpen(val?.data?.data?.isOpen)
        window.scrollTo(0, 0)
      }
    })

    return () => {
      observer.unsubscribe()
    }
  }, [])

  const importClientSideMethods = async () => {
    if(!ClientMethod) {
      ClientMethod = await import(/*webpackChunkName : "ClientSideMethod"*/'./Methods/HeaderClientMethod')
    }
  }


  const handleDataLayer = async (arg) => {
    await importClientSideMethods()
    ClientMethod.handleDataLayerMethodForHeader(arg, propertyDetails, routerParams, attributeDataKeys, pageType, lang, pushDataLayerEventHandler)
  }

  const handleDataLayerforNav = async (arg, dataDetails = {}) => {
    let data=null;
    let page_type = pageType;
    if(arg==='banner_clicked'){
      data={
        banner_type :'app_download'
      }
      page_type='profile'
    }
    await importClientSideMethods()
    ClientMethod.handleDataLayerMethodForSecondaryNav(arg, router, lang, pushDataLayerEventHandler,page_type, {...data, ...dataDetails}, routerParams)
  }

  const handleBackClick = async () => {
    localStorage.setItem('isBackToSRPMap', true)
    await importClientSideMethods()
    ClientMethod.handleBackClickMethod(propertyDetails, handleDataLayer, pageType, router, query, isDesk)
  }

  const onCardClick = async () => {
    setRevampNavOpen(true)
    pushDataLayerEventHandler(window, lang, 'hamburger_clicked', pageType)
  }

  const handleDataLayerCommon = (arg, data = {}) => {
    pushDataLayerEventHandler(window, lang, 'hamburger_clicked', pageType)
    const newData = {...data }
    pushDataLayerEventHandler(window, lang, arg, pageType, newData)
    //Google Tag Manager -- Data Layer end
  }
  useEffect(()=>{
    const isSignClicked = isSignInClick ? 'remove' : ''
    handleBackGroundScroll(revampNavOpen ? 'remove' : isSignClicked );
    if (revampNavOpen) sessionStorage.setItem('isTopMenuBtnClicked', true)
    if (!revampNavOpen) {
      sessionStorage.removeItem("isTopMenuBtnClicked")
    }
  },[revampNavOpen])

  useEffect(()=>{
    const isRerampOpened = revampNavOpen ? 'remove' : ''
    handleBackGroundScroll(isSignInClick ? 'remove' : isRerampOpened );
    dispatch({ type: 'SET_CURRENT_SCREEN', payload: 'login' })
    dispatch({ type: 'SET_VISIBLE', payload: true })
  },[isSignInClick])

  const ifSRPMapViewOpen = () =>
    isDesk ||
    (isMobile &&
      ((!isMapBoxLoaded && pageType != 'SRP Map') ||
        (isMapBoxLoaded &&
          pageType != 'SRP Map' &&
          (pageType != 'PDP' || pageType != 'CDP' || pageType != 'UDP' || pageType != 'SRP'))))
  
  const mapVeiwCheck = ifSRPMapViewOpen() ? css.dBlock : css.dNone
  const pageCheck = page && page === 'home' && searchReducer.isHomeMsiteOpen ? css.dNone : mapVeiwCheck


  if (salesPrice === null && salesPriceMaxFormat) {
    let formattedPrice = ''

    if (lang === 'ar') {
      formattedPrice = `${
        priceCondition ? salesPriceMaxFormat + ' - ' : ''
      }${salesPriceMinFormat} `
    } else if (lang === 'en') {
      formattedPrice = `${salesPriceMinFormat}${
        priceCondition ? ' - ' + salesPriceMaxFormat : ''
      } `
    }

    priceDisplay = (
      <span>
        {formattedPrice}
        {propertyDetails?.propertyInfo?.currencyType}
      </span>
    )
  } else if (salesPrice) {
    priceDisplay = (
      <span>
        {salesPrice} {propertyDetails?.propertyInfo?.currencyType}
      </span>
    )
  }

  let titleDisplay = (
    <>
      <h4>{propertyDetails?.propertyInfo?.title}</h4>
      {priceDisplay}
    </>
  );

  if (isSuggestedUnit?.isShow > 0) {
    titleDisplay = (
      <h4 className={`${css.suggestedUnitTitle} ${css.wUnset}`}>
        {t('unitReservation.Suggested_Units')}
        {isSuggestedUnit?.count > 0 ? `(${isSuggestedUnit?.count})` : ''}{' '}
      </h4>
    );
  }
  
  return (
    <>
      <header
        className={`${css.header} ${stickyHeader ? css.sticky : ''} ${!isMobile ? css.stickyDesktop : ''} ${navbarOpen ? css.navbaropen : ''} ${
          css.open_app_header
        } ${isOpenAppHeader ? css.top_0 : ''} ${customClass ? css[customClass] : ''} ${
          filterDrawerOpen ? css.headerRemove : ''
        } ${pageType == 'SRP Map' || pageType == 'SRP List' ? css.zIndexSRPDesktop : ''} ${
          showTopClass ? css.topClass : ''
        } ${searchReducer.enableLocation ? css.reducezindex : ''} ${!isFirstLoadDone ? css.dBlock : pageCheck} ${stickyHeader && pageType==='SRP List' ? css.srpStickyTop : ''}`}
      >
        {!isMobile && pageType === 'UDP' && (
          <ReservationFailedStickyHeader isMobileOnly={isMobile} id={propertyDetails?.id} />
        )}
        <div
          className={`
      ${css.container} 
      ${page === 'propertyDetail' ? css.mobCustomPdp : ''}
      `}
        >
          {/* BACK ARROW */}
          <div
            className={`${css.backPage} ${page !== 'propertyDetail' && css.d_none} ${css.isNotDesktop}`}
            onClick={handleBackClick}
          >
            <IconoGraphy iconClass={`icon-arrow-back-new`} fontSize={'f_18'} customClass={'sideMargin'}></IconoGraphy>
          </div>

          {/* MOBILE TOGGLE ICON */}
          <div className={`${css.toggle} ${page === 'propertyDetail' ? css.isNotMobile : css.navWrapperDesktop}`}>
            <nav className={mobileNavbarCss.navbar}>
                <div
                  className={`${navbarOpen ? mobileNavbarCss.crossicon : ''} ${mobileNavbarCss.isNotDesktop}`}
                  id='crossIcon'
                >
                  <button
                    id = "menu"
                    aria-label='menu'
                    type='button'
                    className={mobileNavbarCss.navbar__control}
                    onClick={onCardClick}
                  >
                    {(() => {
                      if (
                        (stickyHeader && page == 'home' && !navbarOpen) ||
                        (!navbarOpen && page != 'home') ||
                        navbarOpen
                      ) {
                        return (
                          <span
                            className={`${mobileNavbarCss.hamburger} ${navbarOpen ? mobileNavbarCss.open : ''}`}
                          ></span>
                        )
                      }
                      return (
                        <>
                          <span
                            className={`${mobileNavbarCss.hamburger} ${navbarOpen ? mobileNavbarCss.open : ''}`}
                          ></span>
                        </>
                      )
                    })()}
                  </button>
                </div>
            </nav>
          </div>
          {/* LOGO */}
          {page === 'propertyDetail' && stickyHeader && !isDesk ? (
            <>
              <div className={`${css.pdpfixedtext}`}>
                {titleDisplay}
              </div>
            </>
          ) : (
            <Brand locale={locale} />
          )}

          {/* NAVBER */}
          <div className={page === 'propertyDetail' ? css.isNotMobile : css.navWrapperDesktop}>
              <PrimaryNavbarComponent
                locale={locale}
                headerCities={headerCities}
                handleNavbar={handleNavbar}
                navbarOpen={navbarOpen}
                metaData={metaData || null}
                isOpenAppHeader={isOpenAppHeader}
                slugQueries={slugQueries}
                pageType={pageType}
                statusTag={statusTag}
                stickyHeader={stickyHeader}
              />
          </div>

          

          {page === 'propertyDetail' && (
            <CustomPropertyDetail
              isSuggestedUnit={isSuggestedUnit}
              page={page}
              property={propertyDetails}
              dataLayer={handleDataLayer}
              isCompound={pageType === 'CDP'}
              isUnit={pageType === 'UDP'}
            />
          )}
          <div className={`${page === 'propertyDetail' ? css.isNotMobile : css.headerCTA}`}>
            <SecondaryNav
              stickyHeader={stickyHeader}
              propertyDetails={propertyDetails}
              page={page}
              metaData={metaData || null}
              pageType={pageType}
              udpCompoundId={udpCompoundId}
              handleDataLayerForNav={handleDataLayerforNav}
              isFirstLoadDone={isFirstLoadDone}
              setRevampNavOpen={setRevampNavOpen}
              authToken={typeof window === 'object' ? cookies.get('authToken') : null}
                userId={typeof window === 'object' ? cookies.get('userId') : null}
            />
          </div>
        </div>
        {isMobile && pageType === 'UDP' && (
          <ReservationFailedStickyHeader isMobileOnly={isMobile} id={propertyDetails?.id} />
        )}
      </header>
      {(revampNavOpen && ProfileSideMenu ) &&
      <ProfileSideMenu.default
        page={page}
        pageType={pageType}
        metaData={metaData}
        propertyDetails={propertyDetails}
        query={query}
        isMobile={isMobile}
        isSignInClick={isSignInClick}
        udpCompoundId={udpCompoundId}
        isFirstLoadDone={isFirstLoadDone}
        handleDataLayerForNav={handleDataLayerforNav} 
        setRevampNavOpen={setRevampNavOpen}
        setIsSignInClick={setIsSignInClick}
        statusTag={statusTag}
        /> }

{isSignInClick && LoginPopup &&
            <LoginPopup.default
                login_type={'profile'}  
                pageType={pageType}
                onClose={onScreenClose}
                popuptype={'login'}
                onSuccessLogin={onSuccessLogin}
                setCurrentReservationFlowScreen={()=>{/* No Code here */}}
                data={null}
                handleDataLayerCommon={handleDataLayerCommon}
                processType={false}
                signUpPopup={false}
                secondPopUp={true}
            />
        }
     
    </>
  )
}
export default Header
