import { useAppContext } from 'context/AppContext'
import useTranslation from 'next-translate/useTranslation'
import { useEffect, useRef, useState } from 'react'
import css from './HomeSearchBox.module.scss'
import { useMediaQuery } from 'react-responsive'
import Loader from '../../Loader'
import TabButtonHomePage from '../TabButtonHomePage/TabButtonHomePage'
import HomeSearchCity from '../HomeSearchCity'
import HomeSearchDistrict from '../HomeSearchDistrict'
import HomeSearchPropertyTypeDropDownBox from '../HomeSearchPropertyTypeDropDownBox'
import { useRouter } from 'next/router'
import { commercialAndResidentialPropertyTypeOptions } from '@/utils/constant'
import { pushDataLayerEventHandler } from '@/utils/utils'

export default function HomeSearchBox(props) {
  const {
    handlePropertyIdChange,
    changePropertyType,
    setInputValue,
    setInputError,
    setShowSuggestion,
    inputError,
    selectedCity,
    onSearch} = props;
  const { t, lang } = useTranslation('translations')
  const { state, dispatch } = useAppContext()
  const { searchReducer, homeSearchReducer, commonReducer} = state
  const router = useRouter()
  const buttonRef = useRef()
  const isDesktop = useMediaQuery({ minWidth: 767 })
  const selectedDistricts = homeSearchReducer?.selectedDistrictsMsite
  const [isCommercial,setIsCommercial] = useState(searchReducer?.propertyType==='commercial' || false)
  const [isSearching,setIsSearching] = useState(false)
  const [isSetToSale,setIsSetToSale] = useState(false)
  const isProject = commonReducer.isProject
  
  useEffect(() => {
    dispatch({ type: 'UPDATE_PROPERTYFOR', payload: 'sale' })
    dispatch({ type: 'UPDATE_PROPERTYTYPE', payload: 'residential' })
    return () =>resetAllOnSearch()
  }, [])

  useEffect(() => {
    if (isDesktop) {
      const checkIfClickedOutside = (e) => {
        if (inputError && buttonRef.current && !buttonRef.current.contains(e.target)) {
          setInputError(false)
          dispatch({ type: 'SET_IS_OUTSIDE_CLICK', payload: false })
        }
      }
      document.addEventListener('click', checkIfClickedOutside)
    }
  }, [inputError])

  useEffect(() => {
    setIsCommercial(false)
  }, [isProject])

  const resetAllOnSearch = () =>{
    setInputValue('')
    selectedCity({});
    dispatch({type:"REMOVE_ALL_SELECTED_DISTRICT",payload: []});
    dispatch({ type: "SET_ALL_DISTRICTS", payload: [] })
    setIsSetToSale(true);
    handlePropertyTypeSelection('residential',true)
  }

  const handlePropertyTypeSelection = (tabname,isClearAll=false) =>{
    setIsCommercial(tabname==='commercial')
    dispatch({type:'UPDATE_PROPERTYTYPE_MSITE',payload:tabname})
    dispatch({type:'UPDATE_PROPERTYTYPE',payload:tabname})
    const mapView = JSON.parse(localStorage.getItem('zonefilterClick'))
    const isHomePage = router.pathname === '/'
    const isMapView = mapView?.isMapView || false
    const isMapOrList = isMapView ? 'srp_map' : 'srp_list'
    const page = isHomePage ? 'Home Page' : isMapOrList
    const data = {
      status: tabname === 'commercial',
      placement: "search_screen",
      change_to: tabname,
    }
    const selectedType = searchReducer?.propertyType == 'residential' ? 'commercial' : 'residential'
    dispatch({type:"ALL_PROPERTY_TYPES", payload:commercialAndResidentialPropertyTypeOptions(t)?.[isClearAll ? 'residential' : selectedType]})
   !isClearAll && pushDataLayerEventHandler(window, lang, 'view_commercial_prop', page, data)
  }
  
  const getFirstPara = (countryNameLengthCheck) => {
    return countryNameLengthCheck ? searchReducer?.selectedItem : { cityId: 273 }
  }

  const getSecondPara = () => {
    return {
      districtValue: selectedDistricts,
      propertyType: isProject ? commonReducer.allHomeProjectTypes : homeSearchReducer?.allPropertyTypes,
    }
  }

  const getCityData = (countryNameLengthCheck) => {
    if (countryNameLengthCheck) {
      return {
        city_id: searchReducer?.selectedItem?.cityId[0] || 273,
        city: searchReducer?.selectedItem?.countryName[0] || t('Home.RIYADH'),
        popular_city: searchReducer?.selectedItem?.popularCity ?? true,
      }
    }
      return {
        city_id: 273,
        city: t('Home.RIYADH'),
        popular_city: true,
    }
  }

  const getDistrictData = () => {
    let districtData = { district: [], districtId: [] }

    if (selectedDistricts && selectedDistricts.length !== 0) {
      selectedDistricts.forEach((val) => {
        districtData = {
          district: [...districtData.district, val.title],
          districtId: [...districtData.districtId, val.districtId],
        }
      })
    }

    return districtData
  }

  const getAllSelectedPropertyTypeName = () => {
    if(isProject){
      if(commonReducer?.allHomeProjectTypes){
        return String(commonReducer?.allHomeProjectTypes?.filter((value) => value.isSelected)?.map((obj) => obj?.name))
      }
      return ''
    }
      if(homeSearchReducer?.allPropertyTypes){
        return String(homeSearchReducer?.allPropertyTypes?.filter((value) => value.isSelected)?.map((obj) => obj?.name))
      }
      return ''
  }

  const formatDistrictData = (districtData) => {
    return  districtData && districtData?.district && districtData?.district?.length !== 0 ? String(districtData?.district) : ''
  }

  const isNearbyDistrict = () => {
    return (
      selectedDistricts?.some((district) => district.regionId && district.locationType && district.distance) || false
    )
  }

  const isPopularDistrict = () => {
    return (
      (selectedDistricts?.some((district) => !district.distance) && selectedDistricts[0]?.locationType !== 'city') ||
      false
    )
  }

  const pushPropertyDataToDataLayer = (allSelectedPropertyTypeName) => {
    const propertyData = {
      property_type: allSelectedPropertyTypeName,
      placement: 'Home Page',
    }
    pushDataLayerEventHandler(window, lang, 'property_type_selected', 'Home Page', propertyData)
  }

  const pushDataToDataLayer = (data) => {
    pushDataLayerEventHandler(window, lang, 'search', 'Home Page', data)
  }

  const handleSearch = () => {
    const countryName = searchReducer?.selectedItem?.countryName
    const countryNameLengthCheck = countryName && countryName.length !== 0 && countryName[0]
    const firstPara = getFirstPara(countryNameLengthCheck)
    const secondPara = getSecondPara()
    if (onSearch) {
      onSearch(firstPara, secondPara)
    }
    setIsSearching(true)
    const cityData = getCityData(countryNameLengthCheck)
    const districtData = getDistrictData()
    const allSelectedPropertyTypeName = getAllSelectedPropertyTypeName()
    const data = {
      ...cityData,
      district: formatDistrictData(districtData),
      district_id: formatDistrictData(districtData),
      service: searchReducer?.propertyFor || 'sale',
      property_type: allSelectedPropertyTypeName,
      view_commercial_prop: isCommercial,
      nearby_district: isNearbyDistrict(),
      popular_district: isPopularDistrict(),
      listing_type: isProject? 'Projects' : 'Both',
    }
    if (allSelectedPropertyTypeName) {
      pushPropertyDataToDataLayer(allSelectedPropertyTypeName)
    }
    pushDataToDataLayer(data)
  }
  return (
    <>
     <TabButtonHomePage 
          setShowSuggestion={setShowSuggestion}
          defaultTab={'sale'}
          handlePropertyIdChange={handlePropertyIdChange}
          changePropertyType={changePropertyType}
          isSetToSale={isSetToSale}
        />
        <div className={css.SearchWrapper}>
          <HomeSearchCity {...props}/>
          {!isProject && <HomeSearchDistrict {...props}/>}
          <HomeSearchPropertyTypeDropDownBox/>
          <div className={css.buttonWrap}>
            <button id = 'searchButton' ref={buttonRef} className={css.searchButtonText} onClick={()=> handleSearch()}>{isSearching ? <Loader/> : t('Home.SEARCH')}</button>
          </div>
        </div>
       
        <div className={css.commercialTab}>
            <input id = 'commercialCheckbox' className={css.checkbox} type='checkbox' checked={isCommercial} onChange={()=>handlePropertyTypeSelection(!isCommercial ? 'commercial':'residential')}/>
            <span className={css.checkmark} onClick={()=>handlePropertyTypeSelection(!isCommercial ? 'commercial':'residential')}></span>
            <label>{isProject? t("NewPropertySearch.VIEW_COMMERCIAL_PROJ_ONLY") : t("NewPropertySearch.VIEW_COMMERCIAL_PROP_ONLY")}</label>
        </div>
       
    </>
  )
}
