import { observerService } from './observer'
import UCookies from 'universal-cookie'
import { isUserLoggedIn, deleteFirstObject } from './utils'

var defaultStore = {
  seen: [],
  contacted: [],
  favourites: [],
  priceValuationFeedback: [],
}
export const projectStoreKey = 'propertyStore'

export const initializeStore = () => {
  const cookies = new UCookies()
  let localStorageData =
    (typeof window !== 'undefined' && window.localStorage.getItem('userId')) || cookies.get('userId')
      ? getDataFromLocalStorage()
      : cookies.get('propertyStore')
  if (!localStorageData || !localStorageData.seen || !localStorageData.contacted || !localStorageData.favourites) {
    setDataToLocalStorage(defaultStore)
  } else {
    // Limit 'seen' to 5 items
    if (localStorageData.seen && localStorageData.seen.length > 5) {
      localStorageData.seen = localStorageData.seen.slice(-5); // Keep the last 5 items
    }
    // Limit 'favourites' to 5 items
    if (localStorageData.favourites && localStorageData.favourites.length > 5) {
      localStorageData.favourites = localStorageData.favourites.slice(-5); // Keep the last 5 items
    }
    const domain = typeof location != 'undefined' && location.host
    const cookieOptions = {
      path: '/',
      secure: true,
    }
    if (domain && !domain.includes('localhost')) {
      if (domain && domain?.split('.').length > 2) {
        const domainParts = domain?.split('.')
        domainParts.shift()
        cookieOptions.domain = '.' + domainParts.join('.')
      } else {
        cookieOptions.domain = '.' + domain
      }
    }
    cookies.set('propertyStore', JSON.stringify(localStorageData), cookieOptions)
  }
}

export const initializeProjectStore = () => {
  let localStorageData = getDataFromLocalStorage(projectStoreKey)
  if (!localStorageData || !localStorageData.seen || !localStorageData.contacted || !localStorageData.favourites) {
    setDataToLocalStorage(defaultStore, projectStoreKey)
  }
}

export const getDataFromLocalStorage = (key = 'propertyStore') => {
  if (typeof window !== 'undefined') {
    return JSON.parse(window.localStorage.getItem(key) || `{}`)
  }
}

const setDataToLocalStorage = (data, key = 'propertyStore') => {
  const cookies = new UCookies()
  const domain = typeof location != 'undefined' && location.host

  const cookieOptions = {
    path: '/',
    secure: true,
  }
  if (domain && !domain.includes('localhost')) {
    if (domain && domain?.split('.').length > 2) {
      const domainParts = domain?.split('.')
      domainParts.shift()
      cookieOptions.domain = '.' + domainParts.join('.')
    } else {
      cookieOptions.domain = '.' + domain
    }
  }

  cookies.set(key, JSON.stringify(data), cookieOptions)
  if (typeof window !== 'undefined') return window.localStorage.setItem(key, JSON.stringify(deleteFirstObject(data)))
}

export const setPropertySeen = (data, key = 'propertyStore') => {
  const store = getDataFromLocalStorage(key);
  const seenItem = { id: data?.id, propIdentityTypeId: data?.propIdentityTypeId };

  // Check if the item is already in the seen list
  const index = store?.seen?.findIndex((item) => 
    item.id === seenItem.id && item.propIdentityTypeId === seenItem.propIdentityTypeId
  );

  if (index === -1) {
    // If the item is not in the list, add it
    store.seen.push(seenItem);
  }
  setDataToLocalStorage(store, key);
  // Passing event to observer
  observerService.handleEvent({
    type: 'seen',
    cardType: key == 'propertyStore' ? 'property' : 'project',
    data: store,
  });
}


export const setPropertyContacted = (data, key = 'propertyStore') => {
  const store = getDataFromLocalStorage(key);
  const contactedItem = { id: data?.id, propIdentityTypeId: data?.propIdentityTypeId };
  // Check if the item is already in the contacted list
  const index = store?.contacted?.findIndex((item) => 
    item.id === contactedItem.id && item.propIdentityTypeId === contactedItem.propIdentityTypeId
  );
  if (index === -1) {
    // If the item is not in the list, add it
    store.contacted.push(contactedItem);
  }
  setDataToLocalStorage(store, key)
  // Passing event to observer
  observerService.handleEvent({
    type: 'contacted',
    cardType: key == 'propertyStore' ? 'property' : 'project',
    data: store,
  })
}

export const setPropertyFavourite = (data, key = 'propertyStore') => {
  const store = getDataFromLocalStorage(key)
  const favouriteItem = { id: data?.id, propIdentityTypeId: data?.propIdentityTypeId };
  
  // Find the index of the item in favourites
  const index = store?.favourites?.findIndex((item) => 
    item?.id === favouriteItem?.id && item?.propIdentityTypeId === favouriteItem?.propIdentityTypeId
  );

  const favourites = store?.favourites;
  if (isUserLoggedIn()) {
    if (index === -1) {
      favourites.push(favouriteItem);
    } else {
      favourites.splice(index, 1);
    }
  } else {
    if (index === -1 && favourites.length < 10) {
      favourites.push(favouriteItem);
    } else if (index !== -1) {
      favourites.splice(index, 1);
    } else {
      favourites.push(favouriteItem);
      favourites.splice(0, 1);
    }
  }
  setDataToLocalStorage(store, key)
  // passing event to observer
  observerService.handleEvent({
    type: 'favourite',
    cardType: key == 'propertyStore' ? 'property' : 'project',
    data: store,
  })
}

export const setPropertyFeedback = (feedbackItemData, key = 'propertyStore') => {
  const store = getDataFromLocalStorage(key)
  const index = store?.priceValuationFeedback?.findIndex((item) => 
  item?.propId === feedbackItemData?.propId && item?.propIdentityTypeId === feedbackItemData?.propIdentityTypeId && item?.feedbackState == feedbackItemData?.feedbackState
  )

  const priceValuationFeedback = store?.priceValuationFeedback
  if (index === -1) {
    const indexCheck = store?.priceValuationFeedback?.findIndex((item) => 
    item?.propId === feedbackItemData?.propId && item?.propIdentityTypeId === feedbackItemData?.propIdentityTypeId
    )
    if(indexCheck !== -1){
      priceValuationFeedback[indexCheck] = feedbackItemData
    }else priceValuationFeedback.push(feedbackItemData)
  } else {
    priceValuationFeedback.splice(index, 1)
  }
  setDataToLocalStorage(store, key)
}

export const clearPropertyFavourite = (key = 'propertyStore') => {
  let store = getDataFromLocalStorage(key)
  store.favourites = []
  setDataToLocalStorage(store, key)
}
