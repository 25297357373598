import { v4 as uuidv4 } from "uuid";
class UniqueIdGenerator {
    constructor() {
        this.id = uuidv4();
    }
  
    static getInstance() {
      if (!UniqueIdGenerator.instance) {
        UniqueIdGenerator.instance = new UniqueIdGenerator();
      }
      return UniqueIdGenerator.instance;
    }
  
    generateId() {
        this.id = uuidv4();
        return this.id
    }
  
    setId(newId) {
      this.id = newId;
    }
  
    getId() {
      return this.id;
    }
  }
  
  const generateUniqueId = UniqueIdGenerator.getInstance();
  export default generateUniqueId;