import dynamic from 'next/dynamic'
import { observerService } from '@/utils/observer'
import { pushDataLayerEventHandler, refPath } from '@/utils/utils'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import css from './SecondaryNav.module.scss'
import UCookies from 'universal-cookie'
import Link from 'next/link'
const IconoGraphy = dynamic(()=>import('../IconoGraphy')) 

let clearLocalStorageFunction = null

let importMethods = null

const SecondaryNav = ({
  metaData,
  propertyDetails,
  stickyHeader,
  page,
  pageType,
  udpCompoundId,
  setRevampNavOpen,
  handleDataLayerForNav,
  isFirstLoadDone,
}) => {
  // Dropdown states
  const [showDropdown, setShowDropdown] = useState(false)
  const [DownloadDropdown, setDownloadDropdown] = useState(null)
  const [isClient, setIsClient] = useState(false)
  const [isNavigating, setIsNavigating] = useState(false)
  
  const { t, lang } = useTranslation('translations')
  const url = t('postproperty.addproperty_url')
  const router = useRouter()
  const { query } = router
  const fillIconColor = 'color_grey_dim'
  const stickyDownloadClass = css.downloadAppBtn
  const stickyNavbarClass = css.navbar__link
  let authToken, authUserId
  const cookies = new UCookies()

  const dynamicImportMethods = async () => {
    if (!importMethods) {
      importMethods = await import(/*webpackChunkName:"ImportMethods"*/'./Methods/importMethods')
    }
  }

  const loadDynamicContent = async () => {
    await dynamicImportMethods()
    if (showDropdown) { importMethods.importDownloadDropdown({ setDownloadDropdown }) }
  }

  useEffect(() => {
    loadDynamicContent()
  }, [showDropdown])

  const handleDownloadClose = () => {
    setShowDropdown(false)
  }


  // /**
  //  * added to handle event of favourite, seen, contacted property
  //  */
  useEffect(() => {
    const observer = observerService.observable().subscribe((val) => {
      if (val?.data?.type === 'headerToTop' && val?.data?.data) {
        if (val?.data?.data?.top) {
          handleDownloadClose()
        }
      }
    })
    setIsClient(true)
    return () => observer.unsubscribe() // Ensure proper unsubscription
  }, [])

  const clearLocalStorage = async () => {
    if (clearLocalStorageFunction === null) {
      const { clearLocalStorageMethod } = await import(
        /* webpackChunkName: "clearLocalStorage" */ './Methods/onClickMethods'
      )
      clearLocalStorageFunction = clearLocalStorageMethod
    }
    clearLocalStorageFunction()
  }
  const profileclick = (e) => {
    e.stopPropagation()
    setRevampNavOpen(true)
  }
    
  const handleDropDown = (e) => {
    e.stopPropagation()
    setShowDropdown(!showDropdown)
    handleDataLayerForNav('Download_app_clicked')
  }
  if (typeof window === 'object') {
    authToken = cookies.get('authToken') || localStorage.getItem('authToken')
    authUserId = cookies.get('userId') || localStorage.getItem('userId')

  }

  let iconClass;
  let font_Size;
  let logInfo;


  if (isClient && authToken && authUserId) {
    iconClass = 'icon-loggedin-profile';
    font_Size = 'f_20';
    logInfo = 'YES';
  } else {
    iconClass = 'icon-profile-circle';
    font_Size = 'f_24';
    logInfo = 'NO';
  }

  const handleClick = async (e) => {
    e.preventDefault()
    if (!isNavigating) {
      setIsNavigating(true)
      pushDataLayerEventHandler(window, lang, 'header_post_property_clicked', pageType, {
        source: 'Header_cta',
      })
      try {
        await router.push('/post-property');
      } catch (err) {
        console.error('Navigation error:', err);
      } finally {
        setIsNavigating(false)
      }
    }
  }

  const restrictedPaths = [
    '/select-type',
    '/add-property',
    '/add-project',
    '/add-masterplan',
    '/add-auction',
    'user/postpropertyUserFlow',
    '/post-property'
  ]

  const shouldRenderButton = (
    pageType === 'Login' ||
    (restrictedPaths.includes(router.pathname) || router.pathname.includes('post-property-success'))
  )

  return (
        <div className={css.navbar}>
          <div className={css.navbar__item}>
            <div className={css.actionBtns}>
              {page == 'home' && (
                <button className={stickyDownloadClass} id = "downloadApp" onClick={handleDropDown}>
                  <IconoGraphy iconClass={'icon-app-download'} iconColor={fillIconColor}/>
                  {t('common.DOWNLOAD_APP')}
                </button>
              )}

            {page != 'home' && (
              <button
                className={css.downloadAppBtn}
                onClick={handleDropDown}
              >
                <IconoGraphy iconClass={'icon-app-download'} iconColor={fillIconColor}/>
                {t('common.DOWNLOAD_APP')}
              </button>
            )}
            {showDropdown && DownloadDropdown && (
              <DownloadDropdown.default
                onClose={() => {
                  setShowDropdown(!showDropdown)
                }}
                pageType={pageType}
                handleDataLayerForNav={handleDataLayerForNav}
              />
            )}
          </div>

            <div className={css.desktopview}>
              <a
                id = "addProperty"
                className={css.addProperty}
                href={url}
                onClick={() => {
                  clearLocalStorage()
                  handleDataLayerForNav('Add_Property_Clicked')
                }}
              >
                {t('common.ADD_PROPERTY')}
                <IconoGraphy iconClass='icon-post-property' iconColor='color_white' fontSize='f_20'/>
                {/* <span className={css.propBtns}>
                  <span className={css.propsBtnsLabel}></span>
                  <IconoGraphy iconClass={'icon-post-property'} iconColor={'color_white'}></IconoGraphy>
                </span> */}
              </a>

              <div className={css.profileView}>
                <>
                  <div
                    onClick={(e) => {
                      profileclick(e)
                    }}
                  >
                    <div id = "profileMenu" checkLog = {logInfo} className={`${css.profile} ${!stickyHeader ? css.profileHoverFixonDarkBG : ''}`}>
                      <IconoGraphy iconClass={'icon-menu-more'} iconColor={fillIconColor} fontSize='f_24'/>
                      {/* <IconoGraphy iconClass={'icon-profile-circle'} iconColor={fillIconColor}></IconoGraphy> */}
                      <span>
                        <IconoGraphy
                          iconClass={iconClass}
                          iconColor={fillIconColor}
                          fontSize='f_24'
                        />
                      </span>
                    </div>
                  </div>
                </>
              </div>
            </div>
            {!shouldRenderButton && <div className={css.mobileview}>
                <Link
                  href='/post-property'
                  className={`${css.mmCTA} ${isNavigating ? css.disabled : ''}`}
                  onClick={handleClick}
                >
                  <IconoGraphy iconClass='icon-post-property' fontSize='f_16' iconColor='color_purple'></IconoGraphy>
                    {t('postproperty.heading')}
                </Link>
            </div>}
          </div>
        </div>
  )
}
export default SecondaryNav
